import {
  Stack,
  Box,
  Chip,
  FormHelperText,
  FormControl,
  FormLabel
} from "@mui/material";
import { mainColours } from "@qubit/autoparts";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { extractUserNameFromAuth0Id } from "~/lib/helpers";
import { selectAdminSummariesWorkstation } from "~/redux/selectors/workstationsSelectors";

const WorkstationDetailLayout = ({
  value,
  labelName,
  helperText
}: {
  value: string | React.ReactNode;
  labelName: string;
  helperText?: string;
}) => (
  <FormControl size="medium">
    <FormLabel>{labelName}</FormLabel>
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
    {value}
  </FormControl>
);

const WorkstationDetailsView = () => {
  const workstation = useAppSelector(selectAdminSummariesWorkstation);
  const { t } = useTranslation();

  if (!workstation) return <></>;

  return (
    <Box
      sx={{
        display: "grid",
        columnGap: 7,
        rowGap: 3,
        gridTemplateColumns: "repeat(2, minmax(100px, 1fr))",
        border: `1px solid ${mainColours.border.primary}`,
        borderRadius: "10px",
        p: 3,
        mt: 2,
        "@media (max-width: 1200px)": {
          gridTemplateColumns: "1fr",
          mt: 0
        }
      }}
      aria-label="workstation drill down details"
    >
      <WorkstationDetailLayout
        value={workstation.deviceId}
        labelName={t("device id")}
      />
      <WorkstationDetailLayout
        value={
          workstation.userId
            ? extractUserNameFromAuth0Id(workstation.userId)
            : "-"
        }
        labelName={t("user")}
      />
      <WorkstationDetailLayout
        value={workstation.mode}
        labelName={t("workstation mode")}
        helperText={t("workstation mode description")}
      />
      <WorkstationDetailLayout
        value={
          <Stack flexDirection="row" gap={1} flexWrap={"wrap"} mt={0.5}>
            {workstation.roles.map((role) => (
              <Chip
                label={role}
                size="medium"
                sx={{
                  minHeight: 40,
                  fontSize: "1rem"
                }}
                color="1"
                key={`ws-${workstation.id}-${role}`}
              />
            ))}
          </Stack>
        }
        labelName={t("roles")}
        helperText={t("workstation roles tooltip")}
      />
    </Box>
  );
};
export default WorkstationDetailsView;
