import {
  Container,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { formatUtcDate, useToast } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";

import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";

import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";

import { useGetBinsByFlaggedReasonQuery } from "~/redux/warehouse/bins.openApi";

interface EscalatedBins {
  viewTitle: ViewNameTranslation;
}

export const EscalatedBins: React.FC<EscalatedBins> = (props) => {
  const { viewTitle } = props;
  useNavbar({ viewTitle });
  const { t } = useTranslation();
  const { errorToast } = useToast();

  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);

  const {
    data: flaggedBins,
    error,
    isLoading
  } = useGetBinsByFlaggedReasonQuery(
    autostoreGridId
      ? {
          autostoreGridId,
          flaggedReason: "exterior maintenance"
        }
      : skipToken
  );

  if (error) {
    errorToast(getMessageFromRtkError(error));
  }

  return (
    <Container maxWidth="md" sx={{ p: 4 }}>
      <Typography variant="h6" sx={{ py: 1 }}>
        {t("bins marked for exterior maintenance")}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">{t("bin id")}</TableCell>
            <TableCell align="right">{t("date escalated")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={2}>
                <Skeleton role="progressbar" variant="text" />
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            flaggedBins?.map((binData) => (
              <TableRow key={binData.autostoreBinId}>
                <TableCell align="left">{binData.autostoreBinId}</TableCell>
                <TableCell align="right">
                  {formatUtcDate(binData.flaggedTime)}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Container>
  );
};
