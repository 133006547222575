import { Badge, Box, Stack, Tab, Tabs, useTheme } from "@mui/material";
import { mainColours } from "@qubit/autoparts";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import useFlag from "~/config/flags";
import Andon from "~/features/andon/Andon";
import { selectIsAndonBoardFullScreen } from "~/features/andon/andon.slice";
import { selectEnableDevInformation } from "~/features/settings/devSettings.slice";
import { Status } from "~/features/status/Status";
import { TaskAssessment } from "~/features/status/TaskAssessment";

import WorkstationTestView from "~/features/workstationTest/WorkstationTestView";
import { useClientConfig } from "~/hooks/useClientConfig";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import { useTabNavLocation } from "~/hooks/useTabNavLocation";

import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";

import Configurations from "./Configurations";
import ServiceSupport from "./ServiceSupport";
import { UnifyView } from "./UnifyView";

type ServiceSupportViewProps = {
  viewTitle?: ViewNameTranslation;
};

const ServiceSupportView = (props: ServiceSupportViewProps) => {
  const { viewTitle } = props;
  const { t } = useTranslation();
  const { palette } = useTheme();

  const usersFulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const shouldRenderTasksTab =
    usersFulfillmentCenter?.pickingConfigurations.includes("Autostore");
  const enableTestingToolsTab = useFlag().enableTestingTools;
  useNavbar({ viewTitle });

  const { an_andonFeaturesEnabled, an_displayEventFeatures } =
    useClientConfig();
  const isAndonBoardFullScreen = useAppSelector(selectIsAndonBoardFullScreen);

  const shouldDisplayAndonTab =
    an_andonFeaturesEnabled || an_displayEventFeatures;
  const shouldRenderConfigurationsTab = useAppSelector(
    selectEnableDevInformation
  );

  const validTabs = [
    "service-support",
    "unify",
    ...(shouldRenderConfigurationsTab ? ["configurations"] : []),
    "status",
    ...(shouldRenderTasksTab ? ["tasks"] : []),
    ...(shouldDisplayAndonTab ? ["andon"] : []),
    ...(enableTestingToolsTab ? ["testing-tools"] : [])
  ];

  const { tabIndex, handleTabIndexChange } = useTabNavLocation({
    defaultTab: "service-support",
    validTabs,
    key: "service-support-tab",
    subTabs: [
      {
        subTab: "configurations",
        subTabKey: "configurations-tab",
        subTabDefault: "client-configurations"
      },
      {
        subTab: "andon",
        subTabKey: "andon-tab",
        subTabDefault: "andon-board"
      },
      {
        subTab: "status",
        subTabKey: "status-tab",
        subTabDefault: "ports-status"
      },
      {
        subTab: "testing-tools",
        subTabKey: "testing-tools-tab",
        subTabDefault: "workstation-test-functionality"
      }
    ]
  });

  const isCurrentTabAndonAndIsFullscreenMode =
    tabIndex === "andon" && isAndonBoardFullScreen;
  return (
    <Stack
      margin={3}
      marginTop={0}
      height={isCurrentTabAndonAndIsFullscreenMode ? "100%" : "unset"}
      sx={{
        overflowY: isCurrentTabAndonAndIsFullscreenMode ? "hidden" : "unset"
      }}
    >
      <Box
        position="sticky"
        top="0px"
        bgcolor={palette.background.default}
        zIndex={1001}
      >
        {!isCurrentTabAndonAndIsFullscreenMode && (
          <Tabs
            value={tabIndex}
            onChange={(_event, newValue) =>
              handleTabIndexChange(newValue as string)
            }
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label={t("service and support")} value="service-support" />
            <Tab
              label={
                <Badge
                  badgeContent="beta"
                  sx={{ mr: 3 }}
                  slotProps={{
                    badge: {
                      style: {
                        marginRight: -20,
                        color: mainColours.text.secondary,
                        backgroundColor: mainColours.label[5]
                      }
                    }
                  }}
                >
                  {t("unify")}
                </Badge>
              }
              value="unify"
            />
            <Tab label={t("status")} value="status" />
            {shouldRenderTasksTab && <Tab label={t("Tasks")} value="tasks" />}
            {shouldDisplayAndonTab && <Tab label={t("andon")} value="andon" />}
            {shouldRenderConfigurationsTab && (
              <Tab label={t("configurations")} value="configurations" />
            )}
            {enableTestingToolsTab && (
              <Tab label={t("testing tools")} value="testing-tools" />
            )}
          </Tabs>
        )}
      </Box>
      <Box height="100%">
        {tabIndex === "service-support" && <ServiceSupport />}
        {tabIndex === "unify" && <UnifyView />}
        {tabIndex === "status" && <Status />}
        {tabIndex === "andon" && shouldDisplayAndonTab && <Andon />}
        {tabIndex === "tasks" && shouldRenderTasksTab && <TaskAssessment />}
        {tabIndex === "configurations" && shouldRenderConfigurationsTab && (
          <Configurations />
        )}
        {tabIndex === "testing-tools" && enableTestingToolsTab && (
          <WorkstationTestView />
        )}
      </Box>
    </Stack>
  );
};

export default ServiceSupportView;
