import { AppThunk } from "~/app/store";
import { ClientConfig, getClientConfig } from "~/config/clientConfig";
import { LanguageCode } from "~/localization_i18n";
import { UserMessage } from "~/redux/reducers/site";

// REFRESH CLIENT CONFIG

export interface SetClientConfigAction {
  type: "site/SET_CLIENT_CONFIG";
  payload: ClientConfig;
}

export const refreshClientConfig =
  (clientId: string): AppThunk =>
  (dispatch) => {
    const clientConfig = getClientConfig(clientId);

    dispatch<SetClientConfigAction>({
      type: "site/SET_CLIENT_CONFIG",
      payload: clientConfig
    });
  };

// SET LANGUAGE
export interface SetLanguageAction {
  type: "site/SET_LANGUAGE";
  payload: LanguageCode;
}

export const setLanguage =
  (languageCode: LanguageCode): AppThunk =>
  (dispatch) => {
    dispatch<SetLanguageAction>({
      type: "site/SET_LANGUAGE",
      payload: languageCode
    });
  };

// SET USER MESSAGE
export interface SetUserMessageAction {
  type: "site/SET_USER_MESSAGE";
  payload: UserMessage;
}

export const setUserMessage =
  (args: UserMessage): AppThunk =>
  (dispatch): void => {
    dispatch<SetUserMessageAction>({
      type: "site/SET_USER_MESSAGE",
      payload: { ...args }
    });
  };

// CLEAR USER MESSAGE
export interface ClearUserMessageAction {
  type: "site/CLEAR_USER_MESSAGE";
  payload?: string | undefined | null;
}

export const clearUserMessage =
  (messageId: string | undefined | null): AppThunk =>
  (dispatch): void => {
    dispatch<ClearUserMessageAction>({
      type: "site/CLEAR_USER_MESSAGE",
      payload: messageId
    });
  };

// CLEAR SINGLE USER MESSAGE
export interface ClearSingleUserMessageAction {
  type: "site/CLEAR_SINGLE_USER_MESSAGE";
}

export const clearSingleUserMessage =
  (): AppThunk =>
  (dispatch): void => {
    dispatch<ClearSingleUserMessageAction>({
      type: "site/CLEAR_SINGLE_USER_MESSAGE"
    });
  };
