import { Box, Container, Typography, Stack } from "@mui/material";
import { ProgressButton } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch } from "~/app/store";
import { AutostoreBin } from "~/features/autostoreBin";
import { InventoryProductCard } from "~/features/inventoryProductCard/InventoryProductCard";
import { useClientConfig } from "~/hooks/useClientConfig";
import { useDevCheats } from "~/hooks/useDevCheats";
import { useInactivityResetTimer } from "~/hooks/useInactivityResetTimer";
import { useNavbar } from "~/hooks/useNavbar";
import { mixpanelTrack } from "~/lib/mixpanel-tracking";
import usePromiseIntervalEffect from "~/lib/usePromiseIntervalEffect";
import { closeBin } from "~/redux/actions";
import {
  fetchBinLogPublisherState,
  fetchNextPickBin,
  fetchPortStatus,
  setLeftOrRightPortActive,
  setSelectedAutostoreBinId,
  startPickingOnWorkstation,
  suspendBatch
} from "~/redux/actions/autostore";
import { getBatch } from "~/redux/actions/batch";
import {
  clearSelectedVariant,
  getVariantByVariantId,
  clearSelectedInventoryId,
  setSelectedInventoryId,
  getInventoryReport
} from "~/redux/actions/inventory";
import { StoreState } from "~/redux/reducers";

import { useGetInventorySummariesQuery } from "~/redux/warehouse/inventory.hooks";

import { InventoryTable } from "./InventoryTable";

const mapStateToProps = (state: StoreState) => ({
  selectedAutostoreGridId: state.workstations.siteWorkstation?.autostoreGridId,
  sitePortId: state.workstations.sitePortId,
  selectedVariant: state.inventory.selectedVariant,
  selectedInventoryId: state.inventory.selectedInventoryId,
  portState: state.autostore.portState,
  previousAutostoreBin: state.autostore.previousBin,
  requestedAutostoreBin: state.autostore.requestedAutostoreBin || null,
  inventoryReports: state.inventory.inventoryReport,
  usersFulfillmentCenter: state.store.usersFulfillmentCenter,
  initialFrequency: state.cycleCounts.cycleCountFrequency?.frequency
});

const connector = connect(mapStateToProps, {
  closeBin,
  clearSelectedVariant,
  clearSelectedInventoryId,
  getVariantByVariantId,
  fetchPortStatus,
  startPickingOnWorkstation,
  suspendBatch,
  setSelectedInventoryId,
  getInventoryReport,
  fetchNextPickBin,
  setLeftOrRightPortActive,
  fetchBinLogPublisherState,
  setSelectedAutostoreBinId,
  getBatch
});

type LocationState = {
  state: {
    batchId: string;
    batchName: string;
  };
};
type PropsFromRedux = ConnectedProps<typeof connector>;
type AutostorePreviousBinProps = PropsFromRedux;

const AutostorePreviousBinComponent = function AutostorePreviousBin(
  props: AutostorePreviousBinProps
) {
  // Hooks should be defined at the top of the component
  const navigate = useNavigate();
  const { state } = useLocation() as LocationState;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [portPollingActive, setPortPollingActive] = useState<boolean>(true);
  const [compartment, setCompartment] = useState<number | null>(null);

  const {
    selectedVariant,
    selectedInventoryId,
    portState,
    selectedAutostoreGridId,
    sitePortId,
    previousAutostoreBin,
    requestedAutostoreBin,
    inventoryReports,
    usersFulfillmentCenter,
    initialFrequency,
    getBatch,
    suspendBatch
  } = props;

  const { ap_fusionPortScreenEnabled } = useClientConfig();

  const { data: inventorySummaries } = useGetInventorySummariesQuery(
    previousAutostoreBin?.autostoreBinGuid
      ? {
          binId: [previousAutostoreBin.autostoreBinGuid]
        }
      : skipToken,
    { refetchOnMountOrArgChange: true }
  );

  const selectedInventorySummary =
    inventorySummaries &&
    inventorySummaries.inventory.find(
      (invSum) => invSum.inventoryId === selectedInventoryId
    );

  const hideAddInventory = true;

  const closeBinAndContinuePicking = async () => {
    if (previousAutostoreBin && previousAutostoreBin.autostoreBinId) {
      const binId = previousAutostoreBin.autostoreBinId;
      await props.closeBin({ binId });
      await props.fetchNextPickBin({
        portId: sitePortId || undefined
      });
      // We need to check the port side only for clients that have fusion port
      if (ap_fusionPortScreenEnabled) {
        const portStatus = await props.fetchPortStatus({
          portId: sitePortId || undefined
        });
        if (portStatus && selectedAutostoreGridId) {
          const binStateResponse = await props.fetchBinLogPublisherState(
            selectedAutostoreGridId,
            portStatus.selectedBin
          );
          if (binStateResponse) {
            switch (binStateResponse.portSide) {
              case "left":
                props.setLeftOrRightPortActive("Left");
                break;
              case "right":
                props.setLeftOrRightPortActive("Right");
                break;
              case "unknown":
                props.setLeftOrRightPortActive("Unknown");
                break;
              default:
                break;
            }
          }
        }
      }
      navigate("/autostore-pick");
    }
  };

  const handleConfirmClick = async () => {
    props.clearSelectedVariant();

    await closeBinAndContinuePicking();
  };

  const onInactivityModalClose = useCallback(async () => {
    const autostorePickingBatchInfo = await getBatch(state.batchName);
    // If the inactivity timer modal has expired, we should suspend the active batch
    if (
      autostorePickingBatchInfo &&
      autostorePickingBatchInfo.status.toLowerCase() === "processing" &&
      state?.batchId &&
      sitePortId
    ) {
      try {
        await suspendBatch(state.batchId, sitePortId);
      } catch {
        // todo: use toast here instead of user message
      }
    }
  }, [getBatch, sitePortId, state, suspendBatch]);

  useInactivityResetTimer({
    onInactivityModalClose
  });

  const binQuantity = selectedInventorySummary?.count?.value;
  const searchVariantId: Guid | null =
    selectedInventorySummary?.variantId || null;

  // EFFECT 0
  usePromiseIntervalEffect(
    async () => {
      if (!selectedAutostoreGridId || !sitePortId) return;

      await props.fetchPortStatus();
    },
    500,
    portPollingActive
  );

  // EFFECT 1: stop polling when bin arrives
  useEffect(() => {
    if (
      portState &&
      portState.isReady &&
      portState.selectedBin === previousAutostoreBin?.autostoreBinId
    ) {
      setPortPollingActive(false);
    } else {
      setPortPollingActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portState]);

  // EFFECT 2: load variant info
  useEffect(() => {
    dispatch(clearSelectedVariant());
    if (
      previousAutostoreBin &&
      previousAutostoreBin.autostoreBinGuid &&
      previousAutostoreBin.autostoreBinId
    ) {
      setCompartment(
        previousAutostoreBin?.autostoreCompartmentNumber
          ? previousAutostoreBin.autostoreCompartmentNumber - 1
          : null
      );
    }

    if (searchVariantId) {
      void dispatch(getVariantByVariantId(searchVariantId));
    }
  }, [previousAutostoreBin, searchVariantId, dispatch]);

  // get inventory report for selected variant. Used for total qty/total committed
  useEffect(() => {
    if (selectedVariant) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getInventoryReport(selectedVariant.variantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariant]);

  // set compartment
  useEffect(() => {
    if (
      selectedInventorySummary &&
      selectedInventorySummary?.autostoreCompartmentNumber
    ) {
      setCompartment(selectedInventorySummary.autostoreCompartmentNumber - 1);
    } else setCompartment(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInventoryId]);

  useDevCheats({ showAutostoreStatus: true });
  useNavbar({ viewTitle: "nav.viewname.picking - previous bin" });

  return (
    <Container maxWidth="xl" sx={{ p: 2 }}>
      <Box
        gap={2}
        display="grid"
        gridTemplateRows="0.8fr 300px"
        gridTemplateColumns="0.4fr 1fr"
      >
        <InventoryProductCard
          sx={{ minWidth: "42rem" }}
          variantId={selectedVariant?.variantId}
          addClickCb={() => null}
          hideAddInventory={hideAddInventory}
          inventoryReports={inventoryReports}
          usersFulfillmentCenter={usersFulfillmentCenter}
          initialCycleCountFrequency={initialFrequency}
        />
        <Stack flexGrow={1} gap={2}>
          <InventoryTable />
        </Stack>
        <Stack gap={1} alignItems="center">
          <Typography variant="h5">
            {t("bin")} {portState ? portState.selectedBin : undefined}
          </Typography>
          <AutostoreBin
            state={portPollingActive ? "Waiting for Bin" : "Bin Opened"}
            numberOfColumns={
              requestedAutostoreBin?.verticalCompartmentCount || 1
            }
            numberOfRows={
              requestedAutostoreBin?.horizontalCompartmentCount || 1
            }
            pickCompartment={compartment}
            pickQuantity={binQuantity || ""}
            binId={previousAutostoreBin?.autostoreBinId}
            hideBinId
          />
        </Stack>
        <ProgressButton
          id="return-to-picking-button"
          style={{ justifySelf: "flex-end" }}
          buttonSize="footer"
          emphasis="high"
          variant="contained"
          color="primary"
          disabled={portPollingActive}
          onClick={async () => {
            mixpanelTrack({
              trackedPageName: "Picking Previous Bin",
              type: "Button Click",
              label: "Return to Picking"
            });
            await handleConfirmClick();
          }}
        >
          {t("return to picking")}
        </ProgressButton>
      </Box>
    </Container>
  );
};
export default connector(AutostorePreviousBinComponent);
