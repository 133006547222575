import { Button, Stack, useMediaQuery } from "@mui/material";
import { mobileWidth, useToast } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import { RefObject, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useReactToPrint } from "react-to-print";

import { PrinterConfiguration } from "~/api/warehouseTypes/fulfillmentCenter";
import { useAppDispatch, useAppSelector } from "~/app/store";
import { useCanUnassignCart } from "~/hooks/useCanUnassignCart";
import { useClientConfig } from "~/hooks/useClientConfig";
import { usePrinter } from "~/hooks/usePrinter";
import { downloadBlob } from "~/lib/shared";
import { prepareCart } from "~/redux/actions/batch";
import { getBatchLabels } from "~/redux/actions/labels";

import { selectToteLabels } from "~/redux/selectors/batchSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import {
  useGetBatchQuery,
  useLazyGetBatchesQuery
} from "~/redux/warehouse/batches.hooks";
import { useGetTotesQuery } from "~/redux/warehouse/totes.hooks";

import { openAssignCartModal, openUnassignCartModal } from "./batch.slice";
import { toteLabelZPL } from "./toteLabelZpl";

type Props = {
  printComponentRef: RefObject<HTMLDivElement>;
};

export function BatchCartPrepToolbar({ printComponentRef }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { infoToast, successToast } = useToast();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(mobileWidth);
  const { batchName = "" } = useParams<{ batchName: string }>();

  const { data: batchResponse } = useGetBatchQuery({ batchId: batchName });
  const [getBatches] = useLazyGetBatchesQuery();
  const { data: totes } = useGetTotesQuery(
    batchResponse?.batch?.batchId
      ? { batchId: batchResponse.batch.batchId }
      : skipToken
  );

  const batch = batchResponse?.batch;
  const batchType = batch?.batchType;
  const cartNumberAssigned = batchResponse?.cartNumber;

  const { cartAssignmentEnabled } = useClientConfig();
  const fetchBatchFilters = useAppSelector(
    (state) => state.batch.fetchBatchFilters
  );
  const printerConfiguration = useAppSelector(
    (state) =>
      (state.store.usersFulfillmentCenter
        ?.printerConfiguration as PrinterConfiguration) || null
  );
  const toteLabels = useAppSelector((state) =>
    selectToteLabels(state, batch, totes)
  );
  const usersFC = useAppSelector(selectUsersFulfillmentCenter);
  const canUnassignCart = useCanUnassignCart(batch, cartNumberAssigned);

  const print = usePrinter();

  const handlePagePrint = useReactToPrint({
    pageStyle: `
      @page {
        margin: 0
      }
    `,
    contentRef: printComponentRef
  });

  const isCartAssigned = !!totes && totes.every((tote) => !!tote.cartId);
  const canBatchBePicked =
    usersFC &&
    (usersFC.toteConfiguration === "NoTotes" ||
      (usersFC.toteConfiguration === "GeneratedTotes" && isCartAssigned));

  const handleDownloadZPL = useCallback(async () => {
    if (batch) {
      dispatch(prepareCart(batch.batchId));
      const labelData = await dispatch(getBatchLabels(batch.batchId));

      if (labelData) {
        downloadBlob(labelData, `${batch.batchName}.labels.zpl`);
        successToast(t("labels printed"), { duration: 500 });
      }
    }
  }, [batch, dispatch, successToast, t]);

  const handleNextCartClicked = async () => {
    const { data } = await getBatches({
      status: ["Scheduled"],
      batchType: fetchBatchFilters.selectedBatchTypes.filter(
        (type) => type !== "Bulk" // dont include bulk batch types since theres no prep for bulk batches
      ),
      limit: 1,
      ...(fetchBatchFilters.selectedTempZones.length && {
        temperatureZone: fetchBatchFilters.selectedTempZones[0]
      }),
      ...(fetchBatchFilters.selectedOrderType && {
        orderType: fetchBatchFilters.selectedOrderType
      })
    });

    const nextBatch = data?.batches;
    if (!nextBatch?.length) {
      infoToast("No Scheduled Batches Ready");
    } else {
      navigate(`/batches/${nextBatch[0].batchName}/cart-prep`);
    }
  };

  const handleBrowserPrint = useCallback(() => {
    const labelsZPL = toteLabels?.map((label) => {
      const {
        zone,
        position = "",
        toteId,
        firstName,
        lastName = "",
        externalOrderId,
        putwallPosition,
        orderType
      } = label;
      return toteLabelZPL({
        zone,
        cartPosition: `${position}`,
        toteId,
        putwallPosition,
        customerName: `${firstName} ${lastName}`,
        externalOrderId,
        orderType
      });
    });
    if (labelsZPL) {
      print(labelsZPL.join());
    }
  }, [print, toteLabels]);

  const prepareAndPrint = useCallback(() => {
    if (batch && batch.status === "Scheduled")
      dispatch(prepareCart(batch.batchId));
    if (handlePagePrint && printerConfiguration === "PagePrint") {
      handlePagePrint();
    } else if (printerConfiguration === "BrowserPrint") {
      handleBrowserPrint();
    }
  }, [
    batch,
    dispatch,
    handleBrowserPrint,
    handlePagePrint,
    printerConfiguration
  ]);

  return (
    <Stack direction="row" spacing={1}>
      {!!totes?.length &&
        (printerConfiguration === "PagePrint" ||
          printerConfiguration === "BrowserPrint") && (
          <Button color="primary" variant="contained" onClick={prepareAndPrint}>
            {t("print")}
          </Button>
        )}

      {/* only allow Assign Cart when batch is Scheduled and no cartNumber is assigned */}
      {cartAssignmentEnabled &&
        !cartNumberAssigned &&
        batch?.status === "Scheduled" && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => dispatch(openAssignCartModal())}
          >
            {t("assign")}
          </Button>
        )}

      {!isMobile && canUnassignCart && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => dispatch(openUnassignCartModal())}
        >
          {t("unassign")}
        </Button>
      )}
      {batch && printerConfiguration === "DownloadZPL" && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            void handleDownloadZPL();
          }}
        >
          Print ZPL
        </Button>
      )}

      {/* only show manual pick option for non-autostore batches that are ready to be picked */}
      {batchType !== "Autostore" && canBatchBePicked && (
        <Button
          color="primary"
          variant="contained"
          onClick={() =>
            batch ? navigate(`/batches/${batch.batchName}/pick`) : null
          }
        >
          Pick
        </Button>
      )}
      {(batch?.status !== "Scheduled" || cartNumberAssigned !== null) && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            void handleNextCartClicked();
          }}
        >
          {t("next")}
        </Button>
      )}
    </Stack>
  );
}
