import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckIcon from "@mui/icons-material/Check";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useToast } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConnectedProps, connect } from "react-redux";

import { useAppSelector } from "~/app/store";
import MultiPort from "~/features/autostoreBin/MultiPort";
import CompartmentDataCard from "~/features/autostorePutaway/CompartmentDataCard";

import { useClientConfig } from "~/hooks/useClientConfig";
import {
  getHoldTypeOptions,
  holdTypeCheck,
  isAutostoreView,
  HoldType,
  HoldTypeSource
} from "~/lib/helpers";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { GetPortResponse } from "~/redux/actions";
import { getVariantData } from "~/redux/actions/inventory";
import { InventoryHoldReasonCode } from "~/redux/public/inventory.openApi";
import { NextBinInventory } from "~/redux/reducers/autostore";

import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { useHoldBinMutation } from "~/redux/warehouse/bin.hooks";

import {
  useDeleteInventoryHoldMutation,
  useGetInventoryWithVariantInfoByAutostoreBinNumberQuery
} from "~/redux/warehouse/inventory.hooks";
import { NextBinResponse, NextEmptyBinResponse } from "~/types/api";

const connector = connect(undefined, {
  getVariantData
});

type Props = ConnectedProps<typeof connector> & {
  isOpen: boolean;
  onClose: () => void;
  onCallback?: () => void;
  currentSelectedBin: NextEmptyBinResponse | NextBinResponse | null;
  currentSelectedCompartment?: number | null;
  nextEmptyBinByPort: {
    [portId: number]: NextEmptyBinResponse | null;
  };
  nextBinInventoryByPort?: {
    [portId: number]: NextBinInventory;
  };
  portStateByPort: {
    [portId: number]: {
      getPortResponse: GetPortResponse;
      timestamp: Date;
    };
  };
};

function InventoryHoldModal(props: Props) {
  const {
    isOpen,
    onClose,
    onCallback,
    nextEmptyBinByPort,
    nextBinInventoryByPort,
    portStateByPort,
    currentSelectedBin = null,
    currentSelectedCompartment = null,
    getVariantData
  } = props;
  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();

  const { ap_excludeRecalledHold } = useClientConfig();
  const [holdBin, { isLoading: holdBinLoading }] = useHoldBinMutation();
  const [removeHold] = useDeleteInventoryHoldMutation();

  const workstation = useAppSelector(selectThisWorkstation);

  const possibleHolds = getHoldTypeOptions(ap_excludeRecalledHold);

  const [newSelectedBin, setNewSelectedBin] =
    useState<NextEmptyBinResponse | null>(null);
  const [newSelectedCompartment, setNewSelectedCompartment] = useState<
    number | null
  >(null);

  const newOrCurrentSelectedBin =
    newSelectedBin !== null ? newSelectedBin : currentSelectedBin;

  const newOrCurrentSelectedCompartment =
    newSelectedCompartment !== null
      ? newSelectedCompartment
      : currentSelectedCompartment;

  const { compartmentData } =
    useGetInventoryWithVariantInfoByAutostoreBinNumberQuery(
      workstation && newOrCurrentSelectedBin && isOpen
        ? {
            autostoreGridId: workstation.autostoreGridId,
            binNumber: newOrCurrentSelectedBin.openBinResponse.binId
          }
        : skipToken,
      {
        selectFromResult: ({ data, isLoading }) => ({
          isLoading,
          compartmentData:
            newOrCurrentSelectedCompartment !== null
              ? data?.find(
                  (inv) =>
                    inv.inventory.bin.autostoreCompartmentNumber ===
                    newOrCurrentSelectedCompartment + 1
                )
              : undefined
        })
      }
    );

  const [selectedHolds, setSelectedHolds] = useState<HoldType[]>([]);

  const availableHolds = possibleHolds.filter((h) =>
    selectedHolds.every((sh) => sh.reasonCode !== h.reasonCode)
  );

  useEffect(() => {
    setSelectedHolds(
      compartmentData?.inventory.holds.map((hold) => ({
        reasonCode: hold.reasonCode as InventoryHoldReasonCode,
        source: hold.source as HoldTypeSource
      })) ?? []
    );
  }, [compartmentData, setSelectedHolds, t]);

  const selectedCompartmentBinId =
    newOrCurrentSelectedCompartment !== null &&
    newOrCurrentSelectedBin?.autostoreBinCompartments.find(
      (compartment) =>
        compartment.autostoreCompartmentNumber ===
        newOrCurrentSelectedCompartment + 1
    )?.binId;

  const selectedCompartmentInventory =
    newOrCurrentSelectedCompartment !== null &&
    newOrCurrentSelectedBin?.inventory.find(
      (inv) =>
        inv.bin.autostoreCompartmentNumber ===
        newOrCurrentSelectedCompartment + 1
    );

  const canPlaceHold =
    !holdBinLoading &&
    selectedCompartmentInventory &&
    newOrCurrentSelectedCompartment !== null &&
    newOrCurrentSelectedBin?.autostoreBinCompartments.length;

  const handleSelectHold = (selectedHold: HoldType) => {
    setSelectedHolds([...selectedHolds, selectedHold]);
  };

  const handleUnselectHold = (unselectedHold: HoldType) => {
    if (
      holdTypeCheck(
        unselectedHold,
        errorToast,
        compartmentData?.inventory.expiration
          ? moment(compartmentData.inventory.expiration)
          : undefined
      )
    ) {
      return;
    }

    setSelectedHolds([
      ...selectedHolds.filter((h) => h.reasonCode !== unselectedHold.reasonCode)
    ]);
  };

  const handleConfirm = async () => {
    if (workstation && selectedCompartmentBinId && compartmentData) {
      const { portId } = newOrCurrentSelectedBin.openBinResponse;
      const { autostoreGridId, id: workstationId } = workstation;
      const addedHolds = selectedHolds.filter((selectedHold) =>
        compartmentData.inventory.holds.every(
          (h) => h.reasonCode !== selectedHold.reasonCode
        )
      );
      for (const addedHold of addedHolds) {
        try {
          await holdBin({
            warehouseBinId: selectedCompartmentBinId,
            reasonCode: addedHold.reasonCode?.toLocaleLowerCase(),
            gridId: autostoreGridId,
            portId,
            workstationId
          }).unwrap();
          successToast(t("placed hold", { code: t(addedHold.reasonCode) }));
        } catch (e) {
          errorToast(getMessageFromRtkError(e));
        }
      }
      // holds currently on inventory
      const currentHolds =
        compartmentData?.inventory.holds.map((hold) => hold.reasonCode) ?? [];
      // holds that are on the inventory but are no longer selected in the UI
      const removedHolds = currentHolds.filter((currentHold) => {
        return !selectedHolds.find((h) => h.reasonCode === currentHold);
      });

      for (const removedHold of removedHolds) {
        try {
          await removeHold({
            inventoryId: compartmentData.inventory.inventoryId,
            workstationId: workstation.id,
            reasonCode: removedHold,
            autostoreGridId: workstation.autostoreGridId,
            autostorePortId: newOrCurrentSelectedBin?.openBinResponse.portId
          }).unwrap();
          successToast(
            t("removed hold", {
              code: t(removedHold as InventoryHoldReasonCode)
            })
          );
        } catch (e) {
          errorToast(getMessageFromRtkError(e));
        }
      }

      if (onCallback) onCallback();
      onClose();
    }
  };

  const ConfirmButton = (
    <Button
      id="confirm-button"
      size="large"
      sx={{ width: "100%", fontWeight: "normal" }}
      startIcon={<CheckIcon />}
      onClick={() => handleConfirm()}
      disabled={!canPlaceHold}
    >
      {t("confirm")}
    </Button>
  );

  // inventory hold placement fields
  const currentHoldTextField = (
    <List sx={{ flexGrow: 1 }}>
      {selectedHolds.map((h) => (
        <ListItem
          data-reasoncode={h.reasonCode}
          key={h.reasonCode}
          onClick={() => {
            handleUnselectHold(h);
          }}
          secondaryAction={
            <IconButton>
              <HighlightOffIcon />
            </IconButton>
          }
          sx={{
            border: "1px solid",
            borderRadius: 1,
            p: 1,
            mb: 1
          }}
        >
          <ListItemText
            primary={
              <Typography variant="body1" sx={{ fontSize: "1rem" }}>
                {t(h.reasonCode)}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  );

  const filteredHoldTextField = (
    <TextField
      id="filteredHold"
      label={t("add hold")}
      type="string"
      select
      fullWidth
      variant="outlined"
      value=""
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        sx: { fontSize: "1rem", flexGrow: 1 }
      }}
      // if there are no more holds to be selected, disable the field
      disabled={!availableHolds.length}
    >
      {availableHolds.map((item) => (
        <MenuItem
          key={item.reasonCode}
          value={item.reasonCode.toLocaleLowerCase()}
          onClick={() => {
            handleSelectHold(item);
          }}
        >
          {t(item.reasonCode)}
          <IconButton>
            <AddCircleOutlineIcon />
          </IconButton>
        </MenuItem>
      ))}
    </TextField>
  );

  useEffect(() => {
    if (!isOpen) {
      setNewSelectedCompartment(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (workstation?.autostoreGridId && compartmentData && isOpen) {
      // TODO: refactor out, side effect of `inventoryToModify` dependency in cycle counts
      void getVariantData({
        variantId: compartmentData.inventory.variantId,
        binId: compartmentData.inventory.bin.binId,
        limit: 50,
        offset: 0,
        autostoreGridId: isAutostoreView(window.location.search)
          ? workstation.autostoreGridId
          : undefined,
        inventoryId: compartmentData.inventory.inventoryId
      });
    }
  }, [compartmentData, getVariantData, workstation, isOpen]);

  const closeDialog = () => {
    onClose();
    setNewSelectedCompartment(null);
    setNewSelectedBin(null);
  };

  return (
    <Dialog open={isOpen} onClose={closeDialog} fullWidth maxWidth="xl">
      <DialogTitle>{t("select compartment to place on hold")}</DialogTitle>
      <DialogContent sx={{ mx: 4 }}>
        <Stack gap={2}>
          <MultiPort
            nextEmptyBinByPort={nextEmptyBinByPort}
            nextBinInventoryByPort={nextBinInventoryByPort}
            portStateByPort={portStateByPort}
            workstation={workstation}
            onBinClick={(compartment: number | undefined) => {
              if (compartment || compartment === 0)
                setNewSelectedCompartment(compartment);
            }}
            setSelectedBinCallback={(
              bin: NextEmptyBinResponse | NextBinResponse
            ) => setNewSelectedBin(bin)}
            selectedCompartment={newOrCurrentSelectedCompartment ?? undefined}
            currentSelectedBinId={
              newOrCurrentSelectedBin?.openBinResponse.binId
            }
            allowSelectingCompartmentsWithInventoryOnly
          />

          <Stack
            direction="row"
            gap={5}
            justifyContent="center"
            alignItems="center"
          >
            <CompartmentDataCard compartmentData={compartmentData} />
            <Stack gap={1} flexBasis={200}>
              {currentHoldTextField}
              {filteredHoldTextField}
              {ConfirmButton}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default connector(InventoryHoldModal);
