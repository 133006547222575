import { formatDateTime } from "@qubit/autoparts";
import { createSelector } from "@reduxjs/toolkit";

import { StoreState } from "~/redux/reducers";

import { BatchDto, FulfillmentCenterDto, ToteSummaryDto } from "~/types/api";

import { selectUsersFulfillmentCenter } from "./storeSelectors";

export const selectToteLabels = createSelector(
  selectUsersFulfillmentCenter,
  (_, batch: BatchDto) => batch,
  (_, _batch, totes: ToteSummaryDto[] | undefined) => totes,
  (
    usersFC: FulfillmentCenterDto | null | undefined,
    batch: BatchDto | undefined,
    batchTotes: ToteSummaryDto[] | undefined
  ) =>
    batch &&
    batchTotes
      ?.toSorted((a, b) => (a.totePosition || 0) - (b.totePosition || 0))
      .map((tote) => {
        const pick = batch.picks.find(
          (pick) => pick.assignedToteId === tote.toteId
        );
        const putwallPosition = `${tote.putWallIdentifier}${tote.putWallLaneIdentifier}`;
        return {
          toteId: tote.toteId,
          externalOrderId: tote.externalOrderId || "",
          firstName: pick?.firstName || "",
          lastName: pick?.lastName || "",
          orderWindow: formatDateTime(tote.pickingEndTime, usersFC?.timeZone),
          position: tote.totePosition || 0,
          toteNumber: tote.toteNumber,
          zone: tote.temperatureZone,
          batchName: batch.batchName,
          putwallPosition,
          orderType: tote.orderType
        };
      })
);

export const selectBatchWithCartNumber = (state: StoreState) =>
  state.batch.batchWithCartNumber;
