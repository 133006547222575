import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";

import { useToast } from "@qubit/autoparts";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import MultiPort from "~/features/autostoreBin/MultiPort";
import { setBinsFlags } from "~/features/cycleCounts/cycleCounts.slice";
import MovementsBins from "~/features/manageFlaggedInventory/MovementsBins";

import { mixpanelTrack, useMixpanelPageName } from "~/lib/mixpanel-tracking";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { GetPortResponse, closeBin } from "~/redux/actions";

import { fetchPortStatus, nextEmptyBin } from "~/redux/actions/autostore";
import { NextBinInventory } from "~/redux/reducers/autostore";
import {
  selectThisWorkstation,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";

import {
  useLazyGetInventoryMovementsStateQuery,
  useLazyPostStartInventoryMovementsQuery,
  usePostFlagBinMutation
} from "~/redux/warehouse/autostoreGrid.hooks";
import { usePostFlagBinInventoryMovementsMutation } from "~/redux/warehouse/inventory.hooks";
import { warehouseApi } from "~/redux/warehouse/warehouseApi";
import {
  BinMovementAssignment,
  BinMovementState,
  InventoryMovementResponse,
  NextEmptyBinResponse,
  WorkstationDto
} from "~/types/api";

type FlagBinModal = {
  isOpen: boolean;
  onClose: () => void;
  workstation: WorkstationDto | null;
  nextEmptyBinByPort: {
    [portId: number]: NextEmptyBinResponse | null;
  };
  portStateByPort: {
    [portId: number]: {
      getPortResponse: GetPortResponse;
      timestamp: Date;
    };
  };
  nextBinInventoryByPort?: {
    [portId: number]: NextBinInventory;
  };
  shouldCloseBinUponSubmit: boolean;
  onSuccess?: (binId: number) => void;
  destinationBin?: BinMovementState | null;
  currentBins?: InventoryMovementResponse | undefined;
  selectedRow?: BinMovementAssignment | undefined;
  selectedBin?: number | null;
  handleCleanup?: () => void;
};

function FlagBinModal(props: FlagBinModal) {
  const {
    isOpen,
    onClose,
    onSuccess,
    nextEmptyBinByPort,
    portStateByPort,
    workstation,
    nextBinInventoryByPort,
    shouldCloseBinUponSubmit,
    destinationBin
  } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const trackedPageName = useMixpanelPageName();
  const [selectedFlag, setSelectedFlag] = useState("");
  const flaggedReasons = ["dirty", "damaged", "misconfigured"] as const;
  const [selectedBinForFlag, setSelectedBinForFlag] =
    useState<NextEmptyBinResponse | null>(null);
  const selectedGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const selectedWorkstationId = useAppSelector(selectThisWorkstation);
  const { errorToast, successToast } = useToast();
  const [postFlagBinInvenotryMovements] =
    usePostFlagBinInventoryMovementsMutation();
  const [postFlagBin] = usePostFlagBinMutation();

  const [getInventoryMovementsState] = useLazyGetInventoryMovementsStateQuery();

  const [postStartInventoryMovements] =
    useLazyPostStartInventoryMovementsQuery();

  const handleClose = () => {
    setSelectedFlag("");
    setSelectedBinForFlag(null);
    onClose();
    dispatch(setBinsFlags(null));
    mixpanelTrack({
      trackedPageName,
      type: "Button Click",
      label: "Close Flag Bin Modal"
    });
  };

  const flagBinInvMove = async (reason: string) => {
    try {
      if (workstation) {
        await postFlagBinInvenotryMovements({
          autostoreGridId: workstation?.autostoreGridId,
          reason,
          workstationId: workstation?.id
        });

        if (selectedGridId && selectedWorkstationId) {
          await getInventoryMovementsState({
            autostoreGridId: selectedGridId,
            workstationId: selectedWorkstationId.id
          }).unwrap();
          await postStartInventoryMovements({
            autostoreGridId: selectedGridId,
            workstationId: selectedWorkstationId.id
          }).unwrap();
        }
        handleClose();
        successToast("Inventory Movements Flagged");
        props.handleCleanup?.();
      }
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    }
  };

  const flagBin = async (taskId: number, portId: number, binId: number) => {
    try {
      if (workstation && (selectedBinForFlag || props.selectedBin)) {
        const { autostoreGridId } = workstation;
        try {
          await postFlagBin({
            autostoreGridId: autostoreGridId,
            portId: portId,
            binId: binId,
            reason: selectedFlag
          }).unwrap();

          setSelectedFlag(selectedFlag);
          // dont close the bin and fetch a new one when we flagged it for counting-tasks
          if (selectedFlag && shouldCloseBinUponSubmit) {
            await dispatch(
              closeBin({ autostoreGridId, binId, portId, taskId })
            );
            await dispatch(nextEmptyBin({ portId, autostoreGridId }));
            await dispatch(fetchPortStatus({ portId, autostoreGridId }));
            dispatch(warehouseApi.util.invalidateTags(["cubing data"]));
          }
          if (onSuccess) {
            onSuccess(binId);
          } else {
            successToast(t("flag set successfully"));
          }
        } catch (error) {
          errorToast(getMessageFromRtkError(error));
        }

        handleClose();
      }
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    }
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="lg" onClose={handleClose}>
      <DialogTitle>{t("flag bin")}</DialogTitle>
      <DialogContent sx={{ margin: "30px 30px 0" }}>
        <Grid container direction="column" alignItems="center">
          {destinationBin && (
            <MovementsBins
              currentBins={props.currentBins}
              selectedRow={props.selectedRow}
              selectedBin={props.destinationBin}
              isFlagBinModalOpen={isOpen}
            />
          )}
          {!destinationBin && (
            <MultiPort
              currentSelectedBinId={props.selectedBin}
              nextEmptyBinByPort={nextEmptyBinByPort}
              nextBinInventoryByPort={nextBinInventoryByPort}
              portStateByPort={portStateByPort}
              workstation={workstation}
              selectedBin={selectedBinForFlag}
              setSelectedBinCallback={setSelectedBinForFlag}
              data-testid="multi-port-component"
              showBinsFlags={true}
            />
          )}
          <TextField
            value={selectedFlag}
            onChange={(e) => setSelectedFlag(e.target.value)}
            label={t("flag type")}
            variant="outlined"
            style={{ width: "200px", marginTop: "30px" }}
            data-testid="flag-dropdown"
            select
          >
            {flaggedReasons.map((option) => (
              <MenuItem
                key={option}
                value={option.toLocaleLowerCase()}
                style={{ padding: "1.5em 0 1.5em 0.5em" }}
                data-testid={`flag-type-option-${option.toLowerCase()}`}
              >
                <Typography>{t(option)}</Typography>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", padding: "30px" }}>
        <Button
          size="large"
          variant="contained"
          sx={{
            fontWeight: "normal"
          }}
          onClick={() => {
            if (workstation) {
              if (selectedBinForFlag)
                void flagBin(
                  selectedBinForFlag.openBinResponse.taskId,
                  selectedBinForFlag.openBinResponse.portId,
                  selectedBinForFlag.openBinResponse.binId
                );
              //if a bin is not selected on the modal but on the main page
              else if (
                props.selectedBin &&
                nextBinInventoryByPort &&
                portStateByPort
              ) {
                {
                  workstation?.ports.map((port) => {
                    const { portId } = port;
                    if (
                      portStateByPort[portId].getPortResponse.selectedBin ===
                      props.selectedBin
                    )
                      void flagBin(
                        portStateByPort[portId].getPortResponse.selectedTask,
                        portStateByPort[portId].getPortResponse.portId,
                        portStateByPort[portId].getPortResponse.selectedBin
                      );
                  });
                }
              } else if (destinationBin) {
                void flagBinInvMove(selectedFlag);
              }
              mixpanelTrack({
                trackedPageName,
                type: "Button Click",
                label: "Flag Bin",
                eventProperties: {
                  ...selectedBinForFlag,
                  selectedFlag
                }
              });
              onClose();
            }
          }}
          disabled={
            !selectedFlag ||
            (!selectedBinForFlag && !destinationBin && !props.selectedBin)
          }
        >
          {t("set flag")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FlagBinModal;
