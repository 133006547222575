import { Box, Stack, Tab, Tabs } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useDispatch } from "react-redux";

import { useAppSelector } from "~/app/store";
import { useClientConfig } from "~/hooks/useClientConfig";
import { useDevCheats } from "~/hooks/useDevCheats";
import { useNavbar } from "~/hooks/useNavbar";
import { useTabNavLocation } from "~/hooks/useTabNavLocation";
import { resetSiteWorkstationStatus } from "~/redux/actions/workstations";
import { StoreState } from "~/redux/reducers";
import { useGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";

import AndonBoard from "./AndonBoard";
import AndonCompactFullscreen from "./AndonCompactFullscreen";
import AndonCompactSetupTab from "./AndonCompactSetupTab";
import Events from "./Events";
import {
  clearAndonWorkstationsAndGrids,
  clearEventData,
  setAndonViewOption,
  selectCurrentAndonBoardView,
  selectFetchWorkstationsInterval
} from "./andon.slice";

const mapStateToProps = (state: StoreState) => ({
  usersFcId: state.store.usersFulfillmentCenter?.fulfillmentCenterId || null
});

const connector = connect(mapStateToProps, {
  resetSiteWorkstationStatus
});

type PropsFromRedux = ConnectedProps<typeof connector>;
type AndonProps = PropsFromRedux;

export function Andon(props: AndonProps): JSX.Element {
  const { t } = useTranslation();
  const fetchWorkstationsInterval = useAppSelector(
    selectFetchWorkstationsInterval
  );
  const { an_andonFeaturesEnabled, an_displayEventFeatures } =
    useClientConfig();
  const dispatch = useDispatch();

  const { refetch: refetchWorkstations } = useGetWorkstationsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skipPollingIfUnfocused: true,
    pollingInterval: fetchWorkstationsInterval || 0
  });

  const validTabs = ["andon-board", "andon-compact", "events"];
  const { tabIndex, handleTabIndexChange } = useTabNavLocation({
    defaultTab: "andon-board",
    validTabs,
    key: "andon-tab"
  });

  const andonViewOption = useAppSelector(selectCurrentAndonBoardView);
  const isFullScreen = ["compact fullscreen", "board fullscreen"].includes(
    andonViewOption
  );
  const { setMenuItems } = useNavbar({
    isHidden: isFullScreen,
    viewTitle: "nav.viewname.andon"
  });
  useDevCheats({ showEventSimulator: true });

  useEffect(() => {
    setMenuItems([
      {
        textContent: "Clear Andon Statuses and Fetch Workstations",
        actionCb: () => {
          dispatch(clearAndonWorkstationsAndGrids());
          void refetchWorkstations();
          props.resetSiteWorkstationStatus();
        }
      },
      {
        textContent: "Clear Events",
        actionCb: () => {
          dispatch(clearEventData());
        }
      }
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <Stack
        id="andon-container"
        sx={{
          width: "100%",
          height: isFullScreen ? "100vh" : "unset",
          paddingLeft: isFullScreen ? 0 : undefined,
          paddingRight: isFullScreen ? 0 : undefined
        }}
        onClick={() => {
          if (isFullScreen) dispatch(setAndonViewOption("default"));
        }}
        role="button"
        aria-label="exit full screen"
        tabIndex={0}
        onKeyDown={(event) => {
          if ((isFullScreen && event.key === "Enter") || event.key === " ") {
            event.preventDefault();
            dispatch(setAndonViewOption("default"));
          }
        }}
      >
        {!isFullScreen && (
          <>
            <Box
              id="tabs-and-buttons"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Tabs
                value={tabIndex}
                onChange={(_event, newValue) =>
                  handleTabIndexChange(newValue as string)
                }
                aria-label="status tabs"
                sx={{
                  ml: 2,
                  mb: 2
                }}
                variant="scrollable"
                scrollButtons="auto"
              >
                {an_andonFeaturesEnabled && (
                  <Tab label={t("andon board")} value="andon-board" />
                )}
                {an_andonFeaturesEnabled && (
                  <Tab label={t("andon compact")} value="andon-compact" />
                )}
                {an_displayEventFeatures && (
                  <Tab label={t("events")} value="events" />
                )}
              </Tabs>
            </Box>
            <Box
              id="tab-content"
              flexGrow={1}
              sx={{
                height: "calc(100%-180px)",
                width: "100%",
                overflowX: "hidden"
              }}
            >
              {tabIndex === "andon-compact" && an_andonFeaturesEnabled && (
                <AndonCompactSetupTab />
              )}
              {tabIndex === "andon-board" && an_andonFeaturesEnabled && (
                <AndonBoard />
              )}
              {tabIndex === "events" && an_displayEventFeatures && <Events />}
            </Box>
          </>
        )}

        {/* full screen version */}
        {andonViewOption === "compact fullscreen" &&
          an_andonFeaturesEnabled && <AndonCompactFullscreen />}
        {andonViewOption === "board fullscreen" && an_andonFeaturesEnabled && (
          <AndonBoard />
        )}
      </Stack>
    </>
  );
}

export default connector(Andon);
