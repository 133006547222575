import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, Typography, Paper, Stack, IconButton } from "@mui/material";
import ReactJson from "react-json-view";

import { useAppSelector } from "~/app/store";
import { selectServerConfig } from "~/redux/selectors/appSelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { ClientConfigResponse } from "~/redux/warehouse/clientConfig";

type GenericConfigObject = Record<string, unknown>;

export type DiffResult =
  | {
      _changeType: "ADDED";
      newValue: unknown;
    }
  | {
      _changeType: "DELETED";
      oldValue: unknown;
    }
  | {
      _changeType: "CHANGED";
      oldValue: unknown;
      newValue: unknown;
    }
  | { [key: string]: DiffResult }
  | Array<DiffResult | undefined>;

export function differenceDeep(
  oldVal: unknown,
  newVal: unknown
): DiffResult | undefined {
  if (Array.isArray(oldVal) && Array.isArray(newVal)) {
    const oldArray = oldVal as unknown[];
    const newArray = newVal as unknown[];
    const maxLen = Math.max(oldArray.length, newArray.length);

    // typed array of (DiffResult | undefined)
    const result = Array.from<unknown, DiffResult | undefined>(
      { length: maxLen },
      () => undefined
    );

    let changed = false;
    for (let i = 0; i < maxLen; i++) {
      if (i >= oldArray.length) {
        // ADDED
        changed = true;
        result[i] = {
          _changeType: "ADDED",
          newValue: newArray[i]
        };
      } else if (i >= newArray.length) {
        // DELETED
        changed = true;
        result[i] = {
          _changeType: "DELETED",
          oldValue: oldArray[i]
        };
      } else {
        const subDiff = differenceDeep(oldArray[i], newArray[i]);
        if (subDiff !== undefined) {
          changed = true;
          result[i] = subDiff;
        }
      }
    }
    return changed ? result : undefined;
  }

  if (
    oldVal &&
    newVal &&
    typeof oldVal === "object" &&
    typeof newVal === "object" &&
    !Array.isArray(oldVal) &&
    !Array.isArray(newVal)
  ) {
    const oldObj = oldVal as Record<string, unknown>;
    const newObj = newVal as Record<string, unknown>;
    const allKeys = new Set([...Object.keys(oldObj), ...Object.keys(newObj)]);
    const diffObj: Record<string, DiffResult> = {};
    let changed = false;

    for (const key of allKeys) {
      if (!(key in oldObj)) {
        changed = true;
        diffObj[key] = {
          _changeType: "ADDED",
          newValue: newObj[key]
        };
      } else if (!(key in newObj)) {
        changed = true;
        diffObj[key] = {
          _changeType: "DELETED",
          oldValue: oldObj[key]
        };
      } else {
        const subDiff = differenceDeep(oldObj[key], newObj[key]);
        if (subDiff !== undefined) {
          changed = true;
          diffObj[key] = subDiff;
        }
      }
    }
    return changed ? diffObj : undefined;
  }

  if (oldVal !== newVal) {
    if (oldVal === undefined) {
      return { _changeType: "ADDED", newValue: newVal };
    }
    if (newVal === undefined) {
      return { _changeType: "DELETED", oldValue: oldVal };
    }
    return {
      _changeType: "CHANGED",
      oldValue: oldVal,
      newValue: newVal
    };
  }

  // No difference
  return undefined;
}

export function safeJsonParse(
  clientConfigResponse: ClientConfigResponse | undefined
): GenericConfigObject | null {
  if (!clientConfigResponse || !clientConfigResponse.client_config) {
    return null;
  }
  try {
    const parsed: unknown = JSON.parse(clientConfigResponse.client_config);
    return typeof parsed === "object" &&
      parsed !== null &&
      !Array.isArray(parsed)
      ? (parsed as GenericConfigObject)
      : null;
  } catch {
    return null;
  }
}

function sortDataRecursively(value: unknown): unknown {
  if (Array.isArray(value)) {
    return value
      .map(sortDataRecursively)
      .sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
  }
  if (value && typeof value === "object") {
    const obj = value as Record<string, unknown>;
    const sortedEntries = Object.entries(obj).sort(([keyA], [keyB]) =>
      keyA.localeCompare(keyB)
    );
    const newObj: Record<string, unknown> = {};
    for (const [key, val] of sortedEntries) {
      newObj[key] = sortDataRecursively(val);
    }
    return newObj;
  }
  return value;
}

function copyJsonToClipboard(data: GenericConfigObject | null) {
  if (!data) return;
  const jsonString = JSON.stringify(data, null, 2);

  if (navigator.clipboard?.writeText) {
    navigator.clipboard.writeText(jsonString).catch(() => {
      // do nothing
    });
  }
}

const CombinedClientConfigurations = () => {
  const parsedServerConfig = useAppSelector(selectServerConfig);
  const localClientConfig = useAppSelector(selectClientConfig);

  // Sort for canonical comparison
  const canonicalServerConfig = parsedServerConfig
    ? (sortDataRecursively(parsedServerConfig) as GenericConfigObject)
    : null;
  const canonicalLocalConfig = localClientConfig
    ? (sortDataRecursively(localClientConfig) as GenericConfigObject)
    : null;

  const isIdentical =
    JSON.stringify(canonicalServerConfig) ===
    JSON.stringify(canonicalLocalConfig);

  let differences: DiffResult | undefined;
  if (!isIdentical && canonicalServerConfig && canonicalLocalConfig) {
    differences = differenceDeep(canonicalServerConfig, canonicalLocalConfig);
  }

  return (
    <Box sx={{ p: 2 }}>
      <Stack direction="row" spacing={2} alignItems="flex-start">
        {/* Server Configuration */}
        <Paper sx={{ p: 2, flex: 1 }} elevation={2}>
          <Stack direction="row" alignItems="center" spacing={1} mb={1}>
            <Typography variant="h6" gutterBottom sx={{ mb: 0 }}>
              Server Configuration
            </Typography>
            {/* Copy button for server JSON */}
            {parsedServerConfig && (
              <IconButton
                aria-label="Copy server configuration"
                size="small"
                onClick={() => copyJsonToClipboard(parsedServerConfig)}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            )}
          </Stack>
          {parsedServerConfig ? (
            <div data-testid="server-config">
              <ReactJson
                src={parsedServerConfig}
                name={null}
                indentWidth={4}
                enableClipboard={false}
                displayDataTypes={false}
                quotesOnKeys={false}
                shouldCollapse={(field) => !!field.name}
                style={{ fontSize: 16 }}
              />
            </div>
          ) : (
            <Typography color="text.secondary">
              No valid server configuration found.
            </Typography>
          )}
        </Paper>

        {/* Comparison */}
        <Paper sx={{ p: 2 }} elevation={2}>
          <Typography variant="h6" gutterBottom textAlign="center">
            Comparison
          </Typography>
          {isIdentical ? (
            <Typography
              color="success.main"
              variant="subtitle1"
              textAlign="center"
            >
              Perfect match!
            </Typography>
          ) : (
            <>
              <Typography
                color="error.main"
                variant="subtitle1"
                textAlign="center"
                mb={2}
              >
                They do NOT match
              </Typography>
              {differences ? (
                <ReactJson
                  src={differences}
                  name="diff"
                  indentWidth={2}
                  displayDataTypes={false}
                  quotesOnKeys={false}
                  style={{ fontSize: 14, maxWidth: 300 }}
                />
              ) : (
                <Typography variant="body2" color="text.secondary">
                  Unable to determine differences.
                </Typography>
              )}
            </>
          )}
        </Paper>

        {/* Local (Redux) Configuration */}
        <Paper sx={{ p: 2, flex: 1 }} elevation={2}>
          <Stack direction="row" alignItems="center" spacing={1} mb={1}>
            <Typography variant="h6" gutterBottom sx={{ mb: 0 }}>
              Local (Redux) Configuration
            </Typography>
            {/* Copy button for local JSON */}
            {localClientConfig && (
              <IconButton
                aria-label="Copy local configuration"
                size="small"
                onClick={() => copyJsonToClipboard(localClientConfig)}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            )}
          </Stack>
          {localClientConfig && (
            <div data-testid="local-client-config">
              <ReactJson
                src={localClientConfig}
                name={null}
                indentWidth={4}
                enableClipboard={false}
                displayDataTypes={false}
                quotesOnKeys={false}
                shouldCollapse={(field) => !!field.name}
                style={{ fontSize: 16 }}
              />
            </div>
          )}
        </Paper>
      </Stack>
    </Box>
  );
};

export default CombinedClientConfigurations;
