import { CheckmarkCircle20Px } from "@locaisolutions/icons";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  useTheme
} from "@mui/material";
import { useToast } from "@qubit/autoparts";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { AutostoreBin } from "~/features/autostoreBin";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";

import {
  selectThisWorkstation,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";
import { useReconfigureBinCompartmentsMutation } from "~/redux/warehouse/autostoreGrid.hooks";
import { AutostoreBinConfigurationDto } from "~/types/api";

import { AlertInfo } from "./AlertInfo";

export function EscalateModal({
  open,
  onClose,
  onRelease,
  binConfiguration,
  binNumber
}: {
  open: boolean;
  onClose: () => void;
  onRelease: () => Promise<void>;
  binConfiguration: AutostoreBinConfigurationDto;
  binNumber: number;
}) {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { errorToast, successToast } = useToast();
  const [reconfigureBinCompartments, { isLoading }] =
    useReconfigureBinCompartmentsMutation();

  const selectedWorkstation = useAppSelector(selectThisWorkstation);
  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);

  const [hasBeenReconfigured, setHasBeenReconfigured] = useState(false);

  useEffect(() => {
    if (
      binConfiguration.configurationId ===
      "f601be02-9e56-4071-b80c-84cf8a4f93be"
    ) {
      setHasBeenReconfigured(true);
    }
  }, [binConfiguration.configurationId]);

  const alertMessage = hasBeenReconfigured
    ? t("you are escalating to exterior maintenance. are you sure?")
    : t("please remove all bin dividers in order to escalate the bin");

  const handleReconfigureCompartments = async () => {
    if (!selectedWorkstation || !autostoreGridId) {
      return;
    }
    try {
      await reconfigureBinCompartments({
        autostoreGridId,
        binNumber,
        configurationId: "f601be02-9e56-4071-b80c-84cf8a4f93be",
        workstationId: selectedWorkstation.id
      }).unwrap();

      successToast(`Successfully reconfigured bin to have 1 compartment`);
      setHasBeenReconfigured(true);
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  const handleClose = () => {
    setHasBeenReconfigured(false);
    onClose();
  };

  const handleRelease = async () => {
    setHasBeenReconfigured(false);
    await onRelease();
  };

  return (
    <Dialog open={open} maxWidth="lg" onClose={handleClose}>
      <DialogTitle>{t("bin escalation")}</DialogTitle>
      <DialogContent sx={{ margin: "30px 30px 0" }}>
        <Stack direction="column" spacing={2} alignItems="center">
          <AlertInfo message={alertMessage} />
          <Box width="100%" justifyItems="center" position="relative">
            <AutostoreBin
              state="Bin Opened"
              pickQuantity={0}
              pickCompartment={null}
              numberOfRows={binConfiguration.horizontalCompartmentCount}
              numberOfColumns={binConfiguration.verticalCompartmentCount}
              selectedCompartment={hasBeenReconfigured ? 0 : undefined}
            />
            {hasBeenReconfigured && (
              <CheckmarkCircle20Px
                fill={palette.success.main}
                height="80px"
                width="80px"
                style={{
                  top: "calc(50% - 40px)",
                  right: "calc(50% - 40px)",
                  position: "absolute"
                }}
              />
            )}
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", p: 3 }}>
        <Button variant="subtle" size="large" onClick={handleClose}>
          {t("cancel")}
        </Button>
        <Button
          size="large"
          onClick={
            hasBeenReconfigured ? handleRelease : handleReconfigureCompartments
          }
          disabled={isLoading}
        >
          {hasBeenReconfigured ? t("release bin") : t("confirm configuration")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
