import { Box } from "@mui/material";
import { Fragment } from "react/jsx-runtime";

import { useAppSelector } from "~/app/store";
import { useClientConfig } from "~/hooks/useClientConfig";

import {
  selectPickingState,
  selectCurrentPick
} from "~/redux/selectors/autostoreSelectors";

import { useAddedTotes } from "./hooks/useAddedTotes";
import AutostoreTote from "./tote/AutostoreTote";

type AutostorePickingTotesProps = {
  handleToteClick: (args: {
    toteId: string;
    pickId: string;
    reporter?: string;
  }) => Promise<void>;
};

export const AutostorePickingTotes = ({
  handleToteClick
}: AutostorePickingTotesProps) => {
  const { ap_fusionPortScreenEnabled, ap_totesPlacement } = useClientConfig();
  const pickingState = useAppSelector(selectPickingState);
  const currentPick = useAppSelector(selectCurrentPick);
  const { originalTotes, addedTotes } = useAddedTotes();

  return (
    <Box
      display="grid"
      sx={{
        // "right" view is 4 wide, split view is 2 wide with spacer column
        gridTemplateColumns:
          ap_totesPlacement === "split" ? "1fr 2fr 1fr" : "1fr 1fr 1fr 1fr",
        // right view is 2 rows tall, split view is 3 tall
        gridTemplateRows:
          ap_totesPlacement === "split" ? "1fr 1fr 1fr" : "1fr 1fr",
        rowGap: 1.5,
        width: "100%"
      }}
    >
      {pickingState &&
        originalTotes.map((tote, i) => {
          const allTotesAtPosition = addedTotes
            // select all added totes that are in our position
            .filter((addedTote) => addedTote.oldToteId === tote.toteId)
            .map((at) => ({
              toteId: at.toteId,
              externalToteId: at.externalToteId
            }));

          // add the original tote at the back
          allTotesAtPosition.unshift({
            toteId: tote.toteId,
            externalToteId: tote.externalToteId
          });

          // move the currently assigned tote to the front of the tote stack.
          allTotesAtPosition.sort((a, b) => {
            if (currentPick?.assignedToteId === a.toteId) {
              return 1;
            }
            if (currentPick?.assignedToteId === b.toteId) {
              return -1;
            }
            return 0;
          });

          const pickClickCallback = async () => {
            if (!currentPick || !currentPick.assignedToteId) return;
            await handleToteClick({
              toteId: currentPick.assignedToteId,
              pickId: currentPick.pickId
            });
          };

          return (
            <Fragment key={i}>
              <Box
                style={{
                  width: "100%",
                  marginBottom: 0,
                  position: "relative",
                  minHeight: ap_fusionPortScreenEnabled ? "300px" : "370px"
                }}
              >
                {allTotesAtPosition.map((currentTote, index) => {
                  const isActiveTote =
                    currentTote.toteId === currentPick?.assignedToteId;
                  const leftPosition = 50 + index;
                  const topPosition = index * 2;

                  return (
                    <Box
                      key={`toteId-${currentTote.toteId}`}
                      style={{
                        position: "absolute",
                        left: `${leftPosition}%`,
                        marginTop: `${topPosition}%`,
                        transform: "translateX(-50%)",
                        width: "100%"
                      }}
                    >
                      <AutostoreTote
                        toteId={currentTote.toteId}
                        position={i + 1}
                        externalToteId={currentTote.externalToteId}
                        isActiveTote={isActiveTote}
                        onClickCb={pickClickCallback}
                        isLastStackedTote={
                          index === allTotesAtPosition.length - 1
                        }
                      />
                    </Box>
                  );
                })}
              </Box>
              {/* create space for bin/product column */}
              {ap_totesPlacement === "split" && (i + 1) % 2 === 1 && <Box />}
            </Fragment>
          );
        })}
    </Box>
  );
};
