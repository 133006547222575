import { getUtcDateInBrowserTime, isExpired } from "@qubit/autoparts";
import moment from "moment";
import { useMemo } from "react";

import { useAppSelector } from "~/app/store";

import { checkIsExpiration, isExpirationValid } from "~/lib/helpers";
import {
  selectSelectedRow,
  inventoryDate
} from "~/redux/selectors/autostorePutawaySelectors";
import { PutAwayTaskSummaryDto } from "~/types/api";

import { useClientConfig } from "./useClientConfig";

export function useIsInventoryDateValid(
  putawayTasks: PutAwayTaskSummaryDto[],
  hasDateBeenRemoved: boolean
): boolean {
  // Pass the putawayTasks parameter into the selector:
  const selectedRow = useAppSelector((state) =>
    selectSelectedRow(state, putawayTasks)
  );
  const invDate = useAppSelector(inventoryDate);
  const clientConfig = useClientConfig();

  return useMemo(() => {
    const { inv_inventoryDateLabel, inv_inventoryDateRequired } = clientConfig;
    const isExpirationRequired =
      selectedRow?.product?.isExpirationRequired ?? false;

    // If no expiration is required or no date is provided, validity is true.
    if (
      !checkIsExpiration(inv_inventoryDateLabel) ||
      (!isExpirationRequired && !invDate)
    ) {
      return !hasDateBeenRemoved;
    }

    const formattedExpDate = getUtcDateInBrowserTime(invDate);
    const expirationDate = moment(formattedExpDate);
    const isInventoryExpired = isExpired(moment(), expirationDate);
    const isValid =
      !isInventoryExpired &&
      !!isExpirationValid(
        inv_inventoryDateRequired || isExpirationRequired,
        expirationDate
      );

    return isValid && !hasDateBeenRemoved;
  }, [clientConfig, invDate, selectedRow, hasDateBeenRemoved]);
}
