import { Box, Stack } from "@mui/material";
import { ProgressButton } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import { t } from "i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { useClientConfig } from "~/hooks/useClientConfig";
import { isSiteUsingShipments } from "~/lib/helpers";
import { mixpanelTrack } from "~/lib/mixpanel-tracking";
import { setNextPickingBinLoading } from "~/redux/actions";
import {
  selectBinIsPresent,
  selectPickingState,
  selectShowTwoBinComponents
} from "~/redux/selectors/autostoreSelectors";
import { selectOutOfStockModalStatus } from "~/redux/selectors/outOfStockSelectors";
import { selectIsRecurringScheduleFc } from "~/redux/selectors/storeSelectors";
import { useGetWaveProgressQuery } from "~/redux/warehouse/batches.hooks";
import { useGetInventoryByIdQuery } from "~/redux/warehouse/inventory.hooks";
import { useGetRecurringSchedulesQuery } from "~/redux/warehouse/recurringSchedule.hooks";
import { PickDto } from "~/types/api";

import { trackedPageName } from "./AutostorePicking";
import { PreviousBinButton } from "./PreviousBinButton";
import ProblemSolveModalButton from "./ProblemSolveModalButton";
import BatchProgressBar from "./batchProgressBar/BatchProgressBar";
import { setOutOfStockDialogStatus } from "./outOfStockDialog/outOfStockDialog.slice";

type Props = {
  currentPick: PickDto | null;
  handleFetchNextPickBin: () => Promise<void>;
};

export default function TopActionButtons({
  currentPick,
  handleFetchNextPickBin
}: Props) {
  const dispatch = useAppDispatch();
  const binIsPresent = useAppSelector(selectBinIsPresent);
  const outOfStockModalStatus = useAppSelector(selectOutOfStockModalStatus);
  const isRecurringScheduleFc = useAppSelector(selectIsRecurringScheduleFc);
  const showTwoBinComponents = useAppSelector(selectShowTwoBinComponents);
  const pickingState = useAppSelector(selectPickingState);

  const { ap_fusionPortScreenEnabled } = useClientConfig();
  const displayTwoBinComponents =
    ap_fusionPortScreenEnabled && showTwoBinComponents;

  const { data: inventory } = useGetInventoryByIdQuery(
    currentPick ? { inventoryId: currentPick.inventoryId } : skipToken,
    { refetchOnMountOrArgChange: true }
  );
  const isInventoryOnHold = !!inventory?.holds?.length;
  const isBinFlagged = inventory?.bin?.autostoreBin?.isFlagged;
  const shouldShowFetchNextBinButton =
    binIsPresent && !currentPick && !outOfStockModalStatus;

  const { areShipmentsEnabled } = useGetRecurringSchedulesQuery(undefined, {
    skip: !isRecurringScheduleFc,
    selectFromResult: (result) => ({
      areShipmentsEnabled: isSiteUsingShipments(result.data)
    })
  });

  const { data: waveProgress, isLoading: isWaveProgressBarLoading } =
    useGetWaveProgressQuery(
      pickingState && areShipmentsEnabled
        ? { batchId: pickingState.batchId }
        : skipToken,
      {
        refetchOnMountOrArgChange: true
      }
    );

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      width="100%"
      px={2}
      zIndex={1}
    >
      <Stack direction="row" gap={2}>
        <PreviousBinButton />
        {shouldShowFetchNextBinButton && (
          <ProgressButton
            buttonSize="large"
            emphasis="high"
            responsive
            variant="contained"
            color="secondary"
            fullWidth
            onClick={async () => {
              mixpanelTrack({
                trackedPageName,
                type: "Button Click",
                label: "Skip Bin"
              });
              await handleFetchNextPickBin();
              dispatch(setNextPickingBinLoading(false));
            }}
            style={{ width: "208px" }}
          >
            {t("skip bin")}
          </ProgressButton>
        )}
      </Stack>

      {areShipmentsEnabled && (
        <Box
          width={displayTwoBinComponents ? "45%" : "30%"}
          position="absolute"
          left="50%"
          sx={{ transform: "translateX(-50%)" }}
        >
          <BatchProgressBar
            totalBatches={
              waveProgress && !isWaveProgressBarLoading
                ? waveProgress.totalBatches
                : 0
            }
            completedBatches={
              waveProgress && !isWaveProgressBarLoading
                ? waveProgress.completedBatches
                : 0
            }
            isLoading={isWaveProgressBarLoading && !waveProgress}
          />
        </Box>
      )}

      <Stack direction="row" gap={2}>
        <ProgressButton
          id="out-of-stock-button"
          buttonSize="large"
          emphasis="high"
          responsive
          variant="contained"
          color="secondary"
          fullWidth
          onClick={(): void => {
            mixpanelTrack({
              trackedPageName,
              type: "Button Click",
              label: "Out of stock"
            });

            dispatch(setOutOfStockDialogStatus(true));
          }}
          disabled={!binIsPresent}
          style={{ width: "208px" }}
        >
          {t("out of stock")}
        </ProgressButton>
        <ProblemSolveModalButton
          id="bin-hold-button"
          buttonSize="large"
          emphasis="high"
          responsive
          variant="contained"
          color="secondary"
          sx={{ width: "208px" }}
          fullWidth
          disabled={
            !binIsPresent || !inventory || !!(isBinFlagged || isInventoryOnHold)
          }
        >
          {t("hold bin")}
        </ProblemSolveModalButton>
      </Stack>
    </Stack>
  );
}
