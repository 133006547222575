import { useAppSelector } from "~/app/store";
import useFlag from "~/config/flags";

export const useClientConfig = () => {
  const flag = useFlag();

  return useAppSelector(
    (state) =>
      flag.useClientConfigApiCall
        ? state.app.clientConfig // fetched client config
        : state.site.clientConfig // locally defined client config
  );
};
