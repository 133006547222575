import AttentionIcon from "@locaisolutions/icons/dist/icons20px/Attention20Px";
import CartIcon from "@locaisolutions/icons/dist/icons20px/Cart20Px";
import ServiceIcon from "@locaisolutions/icons/dist/icons20px/Service20Px";
import ManualOpsIcon from "@locaisolutions/icons/dist/icons20px/Warehouse20Px";
import BatchIcon from "@locaisolutions/icons/dist/icons24px/Batch24Px";
import BatchAddIcon from "@locaisolutions/icons/dist/icons24px/BatchAdd24Px";
import BinIcon from "@locaisolutions/icons/dist/icons24px/Bin24Px";
import AnalyticsIcon from "@locaisolutions/icons/dist/icons24px/ChartBar24Px";
import R5Robot24Px from "@locaisolutions/icons/dist/icons24px/R5Robot24Px";
import OrdersIcon from "@locaisolutions/icons/dist/icons24px/Requirements24Px";
import SettingsIcon from "@locaisolutions/icons/dist/icons24px/Settings24Px";

import { List, ListItemButton, Box, ListItemText, Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ConfirmationModal, GridDesignIcon } from "@qubit/autoparts";
import { t } from "i18next";

import { SyntheticEvent, useState } from "react";
import { useLocation, Link as RouterLink, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { useFlag } from "~/config/flags";
import { setPickedQuantityModalStatus } from "~/features/autostorePicking/confirmPickQuantityModal/confirmPickQuantityModal.slice";
import {
  setIsAdjustingBins,
  setSelectedSummaries
} from "~/features/inventory/inventory.slice";
import { useClientConfig } from "~/hooks/useClientConfig";
import { useSideNav } from "~/hooks/useSideNav";
import {
  isAutostoreView,
  isWorkstationWithSinglePort,
  parentPortIdFoundInWorkstation
} from "~/lib/helpers";

import {
  closePort,
  closeWorkstation,
  fetchPortStatus,
  setConfirmationModalVisibilityStatus
} from "~/redux/actions";
import {
  selectUserIsAdmin,
  selectUsersClientId
} from "~/redux/selectors/authSelectors";
import { selectIsAdjustingBins } from "~/redux/selectors/inventorySelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";

import {
  selectSitePortId,
  selectThisWorkstation,
  selectWorkstationAvailablePorts
} from "~/redux/selectors/workstationsSelectors";

import { useLazyGetAutostoreGridStatusQuery } from "~/redux/warehouse/autostoreGrid.hooks";

import { useGetBinConfigurationsQuery } from "~/redux/warehouse/bin.hooks";

const AltBatchIcon = styled(BatchIcon)({
  "& path": {
    fill: "#185BD1"
  }
});

const AltBatchAddIcon = styled(BatchAddIcon)({
  id: "alt-batch-add-icon",
  "& path": {
    fill: "#185BD1"
  }
});

const parseQueryParams = (search: string): Record<string, string> => {
  const queryParams = new URLSearchParams(search);
  const params: Record<string, string> = {};
  queryParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
};

export const drawerWidth = "216px";
export const navBarHeight = "48px";

/**
 * A temporary Drawer containing global navigation links.
 * @constructor
 */
export const NavigationDrawer = () => {
  const isAdjustingBins = useAppSelector(selectIsAdjustingBins);
  const usersFulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const usersClientId = useAppSelector(selectUsersClientId);
  const { manualOpsEnabled, binCleaningEnabled } = useClientConfig();
  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const workstationAvailablePorts = useAppSelector(
    selectWorkstationAvailablePorts
  );
  const sitePortId = useAppSelector(selectSitePortId);
  const isAdmin = useAppSelector(selectUserIsAdmin);
  const isConfirmationModalOpen = useAppSelector(
    (state) => state.autostore.isConfirmationModalOpen
  );
  const { isAutostorePicking, isSideNavOpen, setIsSideNavOpen } = useSideNav();
  const selectedAutostoreGridId = siteWorkstation?.autostoreGridId;

  const showLowInventoryPage = useFlag().lowInventoryPage;

  const { pathname, search } = useLocation();

  const currentPagePathname = pathname.split("/").pop()?.toLowerCase();

  const [fetchAutostoreGridStatus] = useLazyGetAutostoreGridStatusQuery();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryParams = parseQueryParams(search);
  const isAutostoreQueryParamPresent = queryParams.autostore === "true";

  const [navigateToUrl, setNavigateToUrl] = useState<string>("");

  const getIsSelectedBinCleaningWorkstation = () => {
    if (siteWorkstation) {
      const findPortWithCleaningConfirguration = siteWorkstation.ports.find(
        (port) => port.configuration.toLowerCase() === "cleaning"
      );
      if (findPortWithCleaningConfirguration) {
        return true;
      }
      return false;
    }
    return false;
  };

  const getShouldShowConfirmModal = async () => {
    let shouldShowConfirmModal = false;
    if (!siteWorkstation) return shouldShowConfirmModal;
    await Promise.all(
      workstationAvailablePorts.map((port) =>
        dispatch(fetchPortStatus({ portId: port }))
      )
    ).then((statuses) => {
      shouldShowConfirmModal = statuses.some(
        (status) =>
          status && (status.selectedBin > 0 || status.selectedTask > 0)
      );
    });
    return shouldShowConfirmModal;
  };

  const handleNavigate = (toUrl: string, e?: SyntheticEvent): void => {
    // cleanup workstation on any off-page navigation
    if (
      (isAutostoreView(search) || pathname.includes("autostore")) &&
      selectedAutostoreGridId &&
      sitePortId
    ) {
      if (e) e.preventDefault();
      fetchAutostoreGridStatus(selectedAutostoreGridId)
        .then(async ({ data: systemStatus }) => {
          if (systemStatus?.kind === "error") {
            navigate(toUrl);
          } else {
            if (await getShouldShowConfirmModal()) {
              setNavigateToUrl(toUrl);
              dispatch(setConfirmationModalVisibilityStatus(true));
              setIsSideNavOpen(false);
            } else {
              setIsSideNavOpen(false);
              navigate(toUrl);
            }
          }
        })
        .catch(() => {
          navigate(toUrl);
        });
    } else if (
      // if on bin-maintenance screen, with selected grid & port, open navigate away confirmation modal
      (currentPagePathname === "bin-maintenance" ||
        currentPagePathname === "manage-flagged") &&
      selectedAutostoreGridId &&
      sitePortId
    ) {
      if (e) e.preventDefault();
      setNavigateToUrl(toUrl);
      dispatch(setConfirmationModalVisibilityStatus(true));
      setIsSideNavOpen(false);
    } else {
      // if not on autostore screen, navigate away without confirmation
      setIsSideNavOpen(false);
      navigate(toUrl);
    }
  };

  const confirmNavigateAway = async () => {
    dispatch(setConfirmationModalVisibilityStatus(false));
    const isMultiportWorkstation = parentPortIdFoundInWorkstation(
      siteWorkstation?.ports
    );
    const isSinglePortWorkstation =
      isWorkstationWithSinglePort(siteWorkstation);

    if (isMultiportWorkstation || isSinglePortWorkstation) {
      await dispatch(closePort());
    } else {
      await dispatch(closeWorkstation());
    }
    if (isAdjustingBins) {
      dispatch(setIsAdjustingBins(false));
      dispatch(setSelectedSummaries([]));
    }
    if (navigateToUrl.includes("logout")) {
      navigate("/logout");
    } else navigate(navigateToUrl);
  };

  const showAutostoreLink =
    usersFulfillmentCenter?.pickingConfigurations.includes("Autostore") &&
    !getIsSelectedBinCleaningWorkstation();

  // always show batch links for now
  const showBatchLinks =
    usersFulfillmentCenter?.pickingConfigurations.includes("Manual") ||
    usersFulfillmentCenter?.pickingConfigurations.includes("Autostore");

  const showCreateBatchLink =
    showBatchLinks && usersFulfillmentCenter
      ? usersFulfillmentCenter.ecommerceConfiguration === "WmsAndEcommerce"
      : false;

  const showCartLink =
    usersFulfillmentCenter?.waveCompletionConfiguration === "TotesVerified" &&
    usersFulfillmentCenter?.toteConfiguration !== "NoTotes";

  const { hasMultipleBinConfigurationTypes } = useGetBinConfigurationsQuery(
    undefined,
    {
      skip: !usersClientId || !usersFulfillmentCenter,
      selectFromResult: ({ data }) => ({
        hasMultipleBinConfigurationTypes: data && data.length > 1
      })
    }
  );

  const drawerLinks = [
    ...(manualOpsEnabled
      ? [
          {
            text: t("nav.link.manual ops"),
            to: "/",
            icon: () => <ManualOpsIcon />,
            altIcon: (color: string) => <ManualOpsIcon fill={color} />,
            additionalRoutes: [
              "/putaway",
              "manual-inventory/product",
              "manual-inventory/bin",
              "inventory-holds",
              "cycle-count",
              "pick-batches",
              "cart-prep",
              "ship"
            ]
          }
        ]
      : []),
    ...(showAutostoreLink
      ? [
          {
            text: t("nav.link.autostore"),
            to: "/autostore-main",
            icon: () => <R5Robot24Px />,
            altIcon: (color: string) => <R5Robot24Px fill={color} />,
            additionalRoutes: ["autostore"]
          }
        ]
      : []),
    {
      text: t("nav.link.orders"),
      to: "/orders",
      icon: () => <OrdersIcon />,
      altIcon: (color: string) => <OrdersIcon fill={color} />
    },
    ...(showBatchLinks
      ? [
          {
            text: t("nav.link.batches"),
            to: "/admin-batches",
            icon: () => <BatchIcon />,
            altIcon: () => <AltBatchIcon data-testid="alt-batch-icon" />
          }
        ]
      : []),
    ...(showCreateBatchLink
      ? [
          {
            text: t("nav.link.create batches"),
            to: "/create-batches",
            icon: () => <BatchAddIcon />,
            altIcon: () => <AltBatchAddIcon data-testid="alt-batch-add-icon" />
          }
        ]
      : []),
    ...(showCartLink
      ? [
          {
            text: t("nav.link.carts"),
            to: "/carts",
            icon: () => <CartIcon />,
            altIcon: (color: string) => <CartIcon fill={color} />
          }
        ]
      : []),
    {
      text: t("nav.link.analytics"),
      to: "/analytics",
      icon: () => <AnalyticsIcon />,
      altIcon: (color: string) => <AnalyticsIcon fill={color} />
    },
    ...(showLowInventoryPage
      ? [
          {
            text: t("nav.viewname.low inventory"),
            to: "/low-inventory",
            icon: () => <AttentionIcon />,
            altIcon: (color: string) => <AttentionIcon fill={color} />
          }
        ]
      : []),
    ...(binCleaningEnabled && getIsSelectedBinCleaningWorkstation()
      ? [
          {
            text: t("nav.viewname.bin maintenance"),
            to: "/bin-maintenance",
            icon: () => <BinIcon />,
            altIcon: (color: string) => <BinIcon fill={color} />
          }
        ]
      : []),
    ...(hasMultipleBinConfigurationTypes
      ? [
          {
            text: t("nav.viewname.bin reconfiguration"),
            to: "/start-bin-reconfiguration",
            icon: () => <GridDesignIcon />,
            altIcon: (color: string) => <GridDesignIcon fill={color} />
          }
        ]
      : []),
    ...(isAdmin
      ? [
          {
            text: t("service and support"),
            to: "/service-support",
            icon: () => <ServiceIcon />,
            altIcon: (color: string) => <ServiceIcon fill={color} />
          }
        ]
      : []),
    {
      text: t("nav.link.settings"),
      to: "/settings",
      icon: () => <SettingsIcon />,
      altIcon: (color: string) => <SettingsIcon fill={color} />,
      additionalRoutes: ["order-creation"]
    }
  ];

  return (
    <>
      <Drawer
        anchor="left"
        variant="temporary"
        open={isSideNavOpen}
        onClose={(): void => {
          setIsSideNavOpen(!isSideNavOpen);
        }}
        sx={{
          "& .MuiDrawer-paper": {
            top: navBarHeight,
            bottom: `-${navBarHeight}`,
            height: `calc(100% - ${navBarHeight})`,
            width: drawerWidth
          }
        }}
      >
        <div data-testid="drawer" role="presentation">
          <List>
            {drawerLinks.map((item) => {
              let isSelected: boolean;

              if (
                item.to === "/autostore-main" &&
                isAutostoreQueryParamPresent
              ) {
                isSelected = true;
              } else if (item.to === "/" && !isAutostoreQueryParamPresent) {
                isSelected =
                  pathname === item.to ||
                  item.additionalRoutes?.some((route) =>
                    pathname.includes(route)
                  ) ||
                  false;
              } else {
                isSelected =
                  pathname.includes(item.to) ||
                  item.additionalRoutes?.some((route) =>
                    pathname.includes(route)
                  ) ||
                  false;
              }
              return (
                <RouterLink
                  key={item.text}
                  to={item.to}
                  style={{ color: "black", textDecoration: "none" }}
                  onClick={(e) => handleNavigate(item.to, e)}
                >
                  <ListItemButton
                    sx={{
                      backgroundColor: isSelected ? "primary.light" : "inherit",
                      "&:hover": {
                        backgroundColor: isSelected
                          ? "primary.light"
                          : "inherit"
                      }
                    }}
                  >
                    <Box
                      sx={{
                        width: "40px",
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      {isSelected ? item.altIcon("#185BD1") : item.icon()}
                    </Box>
                    <ListItemText
                      primary={item.text}
                      sx={{
                        fontSize: 16,
                        padding: "0 14px",
                        color: isSelected ? "primary.dark" : "text.primary"
                      }}
                    />
                  </ListItemButton>
                </RouterLink>
              );
            })}
          </List>
        </div>
      </Drawer>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        confirmCb={() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          confirmNavigateAway();
        }}
        closeCb={() => {
          dispatch(setConfirmationModalVisibilityStatus(false));
          if (
            isAutostorePicking &&
            usersFulfillmentCenter?.pickQuantityConfirmationEnabled
          ) {
            dispatch(
              setPickedQuantityModalStatus({
                isShown: false,
                pickedQuantity: null,
                selectedBinId: null
              })
            );
            // next PR
            // setActivePickingModal(null);
          }
        }}
        modalTitle={t("are you sure you want to navigate out of the page")}
        modalText=""
      />
    </>
  );
};
