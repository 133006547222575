import {
  Box,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Alert,
  Stack
} from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useAppSelector } from "~/app/store";

import {
  selectAdminSummariesGrid,
  selectWorkstationStatus,
  setSelectedAdminSummariesGridId,
  setSelectedWorkstationStatus,
  WorkstationStatusType,
  workstationStatusTypes
} from "~/features/serviceSupport/serviceSupport.slice";
import { useGetAutostoreGridsQuery } from "~/redux/warehouse/autostoreGrid.hooks";

const WorkstationSelectionContainer = () => {
  const selectedGridId = useAppSelector(selectAdminSummariesGrid);
  const selectedWorkstationStatus = useAppSelector(selectWorkstationStatus);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data: autostoreGrids } = useGetAutostoreGridsQuery();

  useEffect(() => {
    if (
      !autostoreGrids?.length ||
      (autostoreGrids?.length ?? 0) !== 1 ||
      !!selectedGridId
    )
      return;

    dispatch(setSelectedWorkstationStatus("Active"));
    dispatch(
      setSelectedAdminSummariesGridId(autostoreGrids[0].autostoreGridId)
    );
  }, [autostoreGrids, dispatch, selectedGridId]);

  return (
    <Stack flexDirection="column" gap={2}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(150px, 300px))",
          gridTemplateRows: "repeat(2,auto)",
          columnGap: 3
        }}
      >
        <FormControl>
          <FormLabel id="grid-selector">{t("autostore grid")}</FormLabel>
          <Select
            labelId="grid-selector"
            variant="outlined"
            value={autostoreGrids ? selectedGridId : ""}
            onChange={(e: SelectChangeEvent) =>
              dispatch(setSelectedAdminSummariesGridId(e.target.value))
            }
            displayEmpty
          >
            <MenuItem value="">{t("none")}</MenuItem>
            {autostoreGrids?.map((grid) => (
              <MenuItem key={grid.autostoreGridId} value={grid.autostoreGridId}>
                {grid.autostoreGridName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel id="workstations-status-selector">
            {t("workstation status")}
          </FormLabel>
          <Select
            labelId="workstations-status-selector"
            variant="outlined"
            value={selectedWorkstationStatus ?? ""}
            onChange={(e: SelectChangeEvent) =>
              dispatch(
                setSelectedWorkstationStatus(
                  e.target.value as WorkstationStatusType
                )
              )
            }
            displayEmpty
          >
            <MenuItem value="">{t("none")}</MenuItem>
            {workstationStatusTypes.map((workstationStatusType) => (
              <MenuItem
                key={`workstation-status-${workstationStatusType}`}
                value={workstationStatusType}
              >
                {workstationStatusType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {!!autostoreGrids && !selectedGridId && (
        <Alert severity="info">{t("no autostore grid selected")}</Alert>
      )}
    </Stack>
  );
};

export default WorkstationSelectionContainer;
