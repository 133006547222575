import "moment-timezone";
import { useMediaQuery, Pagination } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  ScanningIndicator,
  mobileWidth,
  tabletPortrait,
  useScanIndicator,
  useToast
} from "@qubit/autoparts";
import { isGuid } from "is-guid";
import { useEffect, useMemo, useState } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";

import envConstants from "~/config/envConstants";
import { BatchStatus } from "~/features/batch/batch.type";

import { useDebounce } from "~/hooks/useDebounce";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";

import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";
import {
  buildBatchStatusFilterAdmin,
  buildBatchTypeFilter
} from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { useGetBatchesQuery } from "~/redux/warehouse/batches.hooks";

import AdminBatchTable from "./AdminBatchTable";
import { AdminBatchesSearch } from "./AdminBatchesSearch";
import { AdminBatchesToolbar } from "./AdminBatchesToolbar";
import {
  resetSelectedBatch,
  selectBatch,
  setPage,
  setToteSearch
} from "./adminBatches.slice";

export type Props = { viewTitle?: ViewNameTranslation };

export function Batches({ viewTitle }: Props) {
  const isMobile = useMediaQuery(mobileWidth, { noSsr: true });
  const isTabletPortrait = useMediaQuery(tabletPortrait);
  const dispatch = useAppDispatch();
  const { errorToast } = useToast();
  const { setMenuItems, setToolbar, setDatePicker } = useNavbar({
    centerComponent: useMemo(() => <AdminBatchesSearch />, []),
    viewTitle
  });

  const fetchBatchFilters = useAppSelector(
    (state) => state.batch.fetchBatchFilters
  );

  const getLabelsError = useAppSelector((state) => state.home.getLabelsError);
  const page = useAppSelector((state) => state.adminBatches.page);
  const selectedBatch = useAppSelector(
    (state) => state.adminBatches.selectedBatch
  );
  const selectedFulfillmentCenter = useAppSelector(
    selectUsersFulfillmentCenter
  );
  const searchText = useAppSelector((state) => state.adminBatches.searchText);
  const toteSearch = useAppSelector((state) => state.adminBatches.toteSearch);

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const [scanState, setScanState] = useScanIndicator();
  const [scannedBarcode, setScannedBarcode] = useState<string | null>(null);

  const debouncedSearchText = useDebounce(searchText, 250);

  useKeyDownHandler();
  useBarcodeScanner({
    findScanMatch: (toteId: string) => {
      setScannedBarcode(toteId);
      setScanState("success");
      if (isGuid(toteId)) {
        dispatch(setToteSearch(toteId));
        return true;
      }

      return false;
    },
    deps: [selectedDate]
  });

  useEffect(() => {
    if (getLabelsError) {
      errorToast(getLabelsError);
    }
  }, [errorToast, getLabelsError]);

  useEffect(() => {
    if (selectedBatch) {
      setToolbar(<AdminBatchesToolbar />);
    } else {
      setToolbar(undefined);
    }
  }, [selectedBatch, setToolbar]);

  useEffect(() => {
    if (envConstants.DEV_CHEATS_UAT) {
      setMenuItems([
        {
          textContent: "clear selected date",
          actionCb: () => setSelectedDate(null)
        }
      ]);
    }
  }, [setMenuItems]);

  useEffect(() => {
    setDatePicker({
      selectedDate,
      onDateChange: setSelectedDate
    });
  }, [selectedDate, setDatePicker]);

  useEffect(() => {
    dispatch(setPage(1));
  }, [dispatch, selectedDate]);

  let startTimeStamp: Date | undefined;
  let endTimeStamp: Date | undefined;
  if (selectedDate) {
    startTimeStamp = new Date(selectedDate);
    endTimeStamp = new Date(selectedDate);
    startTimeStamp.setHours(0, 0, 0, 0);
    endTimeStamp.setHours(24, 0, 0, 0);
  }
  const limit = isMobile ? 20 : 40;

  const defaultStatuses: BatchStatus[] = [
    "Scheduled",
    "Processing",
    "Picked",
    "Dropped",
    "Suspended"
  ];

  const searchParams = {
    limit,
    offset: (page - 1) * limit,
    status: fetchBatchFilters.selectedBatchStatusesAdmin.length
      ? fetchBatchFilters.selectedBatchStatusesAdmin
      : defaultStatuses,
    ...(selectedDate && { startTimeStamp, endTimeStamp }),
    ...(debouncedSearchText && { search: debouncedSearchText }),
    batchType: fetchBatchFilters.selectedBatchTypes,
    // it would be nice to be able to search multiple temperature zones
    ...(fetchBatchFilters.selectedTempZones.length && {
      temperatureZone: fetchBatchFilters.selectedTempZones[0]
    }),
    ...(fetchBatchFilters.selectedOrderType && {
      orderType: fetchBatchFilters.selectedOrderType
    }),
    ...(toteSearch && {
      toteId: toteSearch,
      searchByCartIdIfToteIdProvided: true
    }),
    isPrioritizeProcessing: true
  };

  const {
    data: batchResponse,
    error: getBatchesError,
    isFetching: batchesLoading
  } = useGetBatchesQuery(searchParams, { refetchOnMountOrArgChange: true });

  const totalPageCount = batchResponse
    ? Math.ceil(batchResponse.totalBatchCount / limit)
    : 0;

  useEffect(() => {
    if (getBatchesError) {
      errorToast(getMessageFromRtkError(getBatchesError));
    }
  }, [errorToast, getBatchesError]);

  useEffect(() => {
    dispatch(resetSelectedBatch());
    dispatch(setPage(1));
    dispatch(setToteSearch(""));
  }, [dispatch]);

  useEffect(() => {
    if (batchResponse && selectedBatch) {
      const filterSelectedBatch = batchResponse.batches.find(
        (batch) => batch.batchId === selectedBatch.batchId
      );
      if (
        filterSelectedBatch &&
        filterSelectedBatch.status !== selectedBatch.status
      ) {
        dispatch(selectBatch(filterSelectedBatch));
      }
    }
  }, [batchResponse, selectedBatch, dispatch]);

  const availableBatchStatuses = buildBatchStatusFilterAdmin(
    selectedFulfillmentCenter
  );

  const availableBatchTypes = buildBatchTypeFilter(selectedFulfillmentCenter);

  const hasFilters =
    !!fetchBatchFilters.selectedTempZones.length ||
    !!(
      fetchBatchFilters.selectedBatchStatusesAdmin.length &&
      fetchBatchFilters.selectedBatchStatusesAdmin.length !==
        availableBatchStatuses.length
    ) ||
    !!(
      fetchBatchFilters.selectedBatchTypes.length &&
      fetchBatchFilters.selectedBatchTypes.length !== availableBatchTypes.length
    );

  return (
    <Grid
      container
      direction="column"
      style={{
        flexWrap: "nowrap",
        maxWidth: "100vw"
      }}
    >
      <Grid
        item
        style={{
          flex: "1 1 auto",
          padding: "10px 0 0 0",
          overflow: "hidden"
        }}
      >
        <Grid
          container
          direction="column"
          style={{ height: "100%", flexWrap: "nowrap" }}
          alignItems="center"
          spacing={4}
        >
          <Grid
            item
            xs={12}
            style={{ width: isTabletPortrait ? "100%" : "95%" }}
          >
            <AdminBatchTable
              hasFilters={hasFilters}
              batchesLoading={batchesLoading}
              batchesError={
                getBatchesError
                  ? getMessageFromRtkError(getBatchesError)
                  : undefined
              }
              selectedBatch={selectedBatch}
              unselectBatch={() => dispatch(resetSelectedBatch())}
              selectBatch={(batch) => dispatch(selectBatch(batch))}
            />
          </Grid>
          <Grid item xs={12} style={{ padding: "16px 0 48px" }}>
            {totalPageCount > 1 && (
              <Pagination
                count={totalPageCount}
                page={page}
                onChange={(_e, p) => {
                  dispatch(setPage(p));
                }}
                shape="rounded"
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <ScanningIndicator
        scanState={scanState}
        scannedBarcode={scannedBarcode}
        placeholderText="Scan Tote"
      />
    </Grid>
  );
}
