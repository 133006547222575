import { Automatic20Px } from "@locaisolutions/icons";
import { useTheme } from "@mui/material";
import { parse, stringify } from "qs";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";

import useFlag from "~/config/flags";

import { mixpanelTrack, useMixpanelPageName } from "~/lib/mixpanel-tracking";
import { sendUserTaskSelectedEvent } from "~/redux/actions/userEvents";
import { selectUserCanCycleCount } from "~/redux/selectors/authSelectors";
import { selectWorkstationHasInventoryRole } from "~/redux/selectors/workstationsSelectors";

import { MainButton } from "./MainButton";

export type Props = {
  route: string;
};

export function CycleCountsButton({ route }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const { search }: { search: string } = useLocation();
  const trackedPageName = useMixpanelPageName();

  const usePermissions = useFlag().feUsePermissions;

  const userCanCycleCount = useAppSelector(
    selectUserCanCycleCount(usePermissions)
  );
  const isInventoryRoleFound = useAppSelector(
    selectWorkstationHasInventoryRole
  );

  const onCycleCountButtonClicked = async () => {
    if (userCanCycleCount && isInventoryRoleFound) {
      await dispatch(sendUserTaskSelectedEvent("CYCLECOUNT"));

      navigate({
        pathname: route,
        search: stringify({
          ...parse(search, { ignoreQueryPrefix: true }),
          autostore: true
        })
      });
      mixpanelTrack({
        type: "Button Click",
        label:
          route === "/autostore-cyclecount"
            ? "Cycle Counts V1 Button"
            : "Cycle Counts V2 Button",
        trackedPageName
      });
    }
  };

  const alert =
    (!isInventoryRoleFound && t("no inventory role found")) ||
    (!userCanCycleCount && t("user has insufficient privileges")) ||
    undefined;

  const alertKind =
    (!isInventoryRoleFound && "workstation") ||
    (!userCanCycleCount && "user") ||
    undefined;

  return (
    <MainButton
      alert={alert}
      alertKind={alertKind}
      disabled={!userCanCycleCount || !isInventoryRoleFound}
      onClick={onCycleCountButtonClicked}
      variant="md"
      icon={
        <Automatic20Px
          width={30}
          height={30}
          fill={palette.primary.contrastText}
        />
      }
    >
      {t("nav.viewname.cycle counts")}
    </MainButton>
  );
}
