import { ModeGoodsIn20Px } from "@locaisolutions/icons";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";

import useFlag from "~/config/flags";

import { mixpanelTrack, useMixpanelPageName } from "~/lib/mixpanel-tracking";
import { sendUserTaskSelectedEvent } from "~/redux/actions/userEvents";
import { selectUserCanDecant } from "~/redux/selectors/authSelectors";
import {
  selectThisWorkstation,
  selectWorkstationHasInductionRole
} from "~/redux/selectors/workstationsSelectors";

import { MainButton } from "./MainButton";

export type Props = {
  route: string;
};

export function InductionButton({ route }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { search }: { search: string } = useLocation();
  const dispatch = useAppDispatch();
  const trackedPageName = useMixpanelPageName();

  const isInductionRoleFound = useAppSelector(
    selectWorkstationHasInductionRole
  );
  const usePermissions = useFlag().feUsePermissions;
  const disableDecanting = !useAppSelector(selectUserCanDecant(usePermissions));
  const hasWorkstation = !!useAppSelector(selectThisWorkstation);

  const onInductionButtonClicked = async () => {
    if (!disableDecanting && (isInductionRoleFound || !hasWorkstation)) {
      await dispatch(sendUserTaskSelectedEvent("INDUCTION"));
      navigate({ pathname: route, search });
      mixpanelTrack({
        type: "Button Click",
        label:
          route === "/autostore-putaway"
            ? "Induction V1 Button"
            : "Induction V2 Button",
        trackedPageName
      });
    }
  };

  const alert =
    (!isInductionRoleFound && t("no induction role found")) ||
    (disableDecanting && t("user has insufficient privileges")) ||
    undefined;

  const alertKind =
    (!isInductionRoleFound && "workstation") ||
    (disableDecanting && "user") ||
    undefined;

  return (
    <MainButton
      alert={alert}
      alertKind={alertKind}
      onClick={onInductionButtonClicked}
      disabled={(!isInductionRoleFound && hasWorkstation) || disableDecanting}
      icon={
        <ModeGoodsIn20Px
          width={30}
          height={30}
          fill={palette.primary.contrastText}
        />
      }
    >
      {t("nav.link.induction")}
    </MainButton>
  );
}
