import { Typography } from "@mui/material";
import {
  ASTableV2,
  ASTableV2Body,
  ASTableV2Cell,
  ASTableV2Header,
  ASTableV2Row,
  ASTableV2RowEmpty
} from "@qubit/autoparts";

import { skipToken } from "@reduxjs/toolkit/query";
import { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";

import { Recipient } from "~/features/andon/Messenger";
import {
  AndonWorkstation,
  selectAndonWorkstations
} from "~/features/andon/andon.slice";
import {
  selectAdminSummariesGrid,
  selectWorkstationStatus
} from "~/features/serviceSupport/serviceSupport.slice";
import { useGetUnifyPortErrorsQuery } from "~/redux/warehouse/unify.openApi";

import AdminSummariesContentRow from "./AdminSummariesContentRow";

interface AdminSummariesTableProps {
  openMessageComponent: (
    event: React.MouseEvent | React.KeyboardEvent,
    newRecipients: Recipient[]
  ) => void;
}

const emptyArray: number[] = [];

const workstationAllPorts = (workstation: AndonWorkstation) => {
  if (!workstation) return emptyArray;
  const ports = workstation.ports.map((p) => p.portId);
  const parentPorts = workstation.parentPort
    ? [workstation.parentPort.portId]
    : [];
  return [...ports, ...parentPorts];
};

const AdminSummariesTable = (props: AdminSummariesTableProps) => {
  const { t } = useTranslation();
  const pageRowLimit = 5;
  const selectedGridId = useAppSelector(selectAdminSummariesGrid);
  const selectedWorkstationStatus = useAppSelector(selectWorkstationStatus);
  const andonWorkstations = useAppSelector(selectAndonWorkstations);
  const [currentPage, setCurrentPage] = useState(1);

  const workstations = useMemo(() => {
    if (!selectedWorkstationStatus.length || !selectedGridId) return [];
    return Object.values(andonWorkstations).filter(
      (ws) =>
        ws.workstation.autostoreGridId === selectedGridId &&
        selectedWorkstationStatus === ws.workstation.status
    );
  }, [andonWorkstations, selectedGridId, selectedWorkstationStatus]);

  const { portErrorCountByWorkstation, isFetchingUnifyPortErrors } =
    useGetUnifyPortErrorsQuery(
      selectedGridId
        ? {
            autostoreGridId: selectedGridId,
            workstationId: null,
            limit: null
          }
        : skipToken,
      {
        selectFromResult: ({ data, isFetching }) => {
          return {
            isFetchingUnifyPortErrors: isFetching,
            portErrorCountByWorkstation: workstations?.reduce<{
              [key: string]: number;
            }>((acc, curr) => {
              const allPorts = workstationAllPorts(curr);
              // eslint-disable-next-line no-param-reassign
              acc[curr.id] =
                data?.filter((d) => allPorts.includes(d.item.portId)).length ??
                0;
              return acc;
            }, {})
          };
        }
      }
    );

  const sortedWorkstations = useMemo(() => {
    if (!portErrorCountByWorkstation) return workstations;
    return [...workstations].sort(
      (a, b) =>
        (portErrorCountByWorkstation[b.workstation.id] || 0) -
        (portErrorCountByWorkstation[a.workstation.id] || 0)
    );
  }, [workstations, portErrorCountByWorkstation]);

  const workstationsToDisplay = useMemo(() => {
    return sortedWorkstations.slice(
      (currentPage - 1) * pageRowLimit,
      pageRowLimit * currentPage
    );
  }, [currentPage, sortedWorkstations, pageRowLimit]);

  const totalPageCount = workstations.length
    ? Math.ceil(workstations.length / pageRowLimit)
    : 0;

  return (
    <ASTableV2
      gridTemplateColumns="repeat(9, auto)"
      gridTemplateRows="auto"
      isLoading={isFetchingUnifyPortErrors}
      pagination={{
        pageCount: totalPageCount,
        page: currentPage,
        setPage: setCurrentPage
      }}
    >
      <ASTableV2Header>
        <ASTableV2Row aria-label="active workstations header">
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("device id")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("user")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("parent port id")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("ports")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("mode")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("roles")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell />
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("port errors")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell />
        </ASTableV2Row>
      </ASTableV2Header>
      {!isFetchingUnifyPortErrors && (
        <ASTableV2Body>
          {!workstationsToDisplay?.length && (
            <ASTableV2RowEmpty>{t("no workstations found")}</ASTableV2RowEmpty>
          )}
          {workstationsToDisplay.map((activeWorkstation: AndonWorkstation) => {
            return (
              <AdminSummariesContentRow
                {...props}
                activeWorkstation={activeWorkstation}
                portErrorsCount={
                  portErrorCountByWorkstation?.[activeWorkstation.id]
                }
                key={activeWorkstation.id}
              />
            );
          })}
        </ASTableV2Body>
      )}
    </ASTableV2>
  );
};

export default AdminSummariesTable;
