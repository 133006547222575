import { Box, Container } from "@mui/system";
import { ProductCard } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";

import { useGetInventoryByIdQuery } from "~/redux/warehouse/inventory.hooks";

import { PickingStateV2FocusedPick } from "~/types/api";

type CurrentPickProductViewProps = {
  currentPick: PickingStateV2FocusedPick;
  loadingPick?: boolean;
};
const CurrentPickProductView = ({
  currentPick,
  loadingPick = false
}: CurrentPickProductViewProps) => {
  const { data: inventory } = useGetInventoryByIdQuery(
    !loadingPick ? { inventoryId: currentPick.inventoryId } : skipToken,
    { refetchOnMountOrArgChange: true }
  );
  return (
    <Box minHeight="480px" width="100%">
      <Container maxWidth="sm" sx={{ m: 0 }} disableGutters>
        {!loadingPick ? (
          <ProductCard.Root
            productName={currentPick.productName}
            imageFileName={currentPick.imageFilename}
            quantity={inventory?.count?.value}
            disabled
            disableGutters
          >
            <ProductCard.Sku sku={currentPick.sku} />
            <ProductCard.ProductCount
              count={{
                value: currentPick.unitAmount,
                units: currentPick.unitFormatted
              }}
            />
          </ProductCard.Root>
        ) : (
          <ProductCard.Loading />
        )}
      </Container>
    </Box>
  );
};

export default CurrentPickProductView;
