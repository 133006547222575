import { Alert, Button, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { skipToken } from "@reduxjs/toolkit/query";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { AutostoreBin } from "~/features/autostoreBin";
import { usePortStatusQuery } from "~/hooks/usePortStatus";
import { parsePortSide } from "~/lib/parsePortSide";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  selectAdminSummariesWorkstation,
  selectAdminSummariesWorkstationAvailablePorts
} from "~/redux/selectors/workstationsSelectors";
import {
  useGetInventoryMovementsStateQuery,
  usePostStartInventoryMovementsQuery
} from "~/redux/warehouse/autostoreGrid.hooks";
import { useGetFocusedPickQuery } from "~/redux/warehouse/autostorePicking.hooks";

import {
  BinMovementAssignment,
  InventoryMovementResponse,
  PickingStateV2FocusedPick,
  WorkstationSummaryDto
} from "~/types/api";

const WorkstationPortView = ({
  portId,
  portSideIndex,
  workstation,
  childPortId,
  currentTaskForBinAtPort,
  currentInventoryMovementBins,
  focusedPick
}: {
  portId: number;
  childPortId: number;
  portSideIndex: number;
  workstation: WorkstationSummaryDto;
  currentTaskForBinAtPort?: BinMovementAssignment;
  currentInventoryMovementBins?: InventoryMovementResponse;
  focusedPick?: PickingStateV2FocusedPick;
}) => {
  const {
    activityState,
    horizontalCompartmentCount,
    verticalCompartmentCount,
    binState,
    error,
    refetch,
    ...portStatus
  } = usePortStatusQuery(portId, portSideIndex, workstation);
  const { t } = useTranslation();
  const parentPortId = workstation?.ports?.[0]?.parentPortId || null;
  const isInPickingMode = workstation.mode.toLowerCase() === "picking";
  const isInProblemSolvingMode =
    workstation.mode.toLowerCase() === "problem solving";
  const currentPick =
    isInPickingMode && portStatus?.selectedBin !== 0 ? focusedPick : null;

  //"problem solving"/inventory mode
  let currentSelectedBin;
  if (
    isInProblemSolvingMode &&
    currentInventoryMovementBins &&
    currentTaskForBinAtPort
  ) {
    if (
      currentInventoryMovementBins.sourceBin.autostoreBinId ===
      portStatus?.selectedBin
    )
      currentSelectedBin = currentInventoryMovementBins.sourceBin;
    else if (
      currentInventoryMovementBins.destinationBin.autostoreBinId ===
      portStatus?.selectedBin
    ) {
      currentSelectedBin = currentInventoryMovementBins.destinationBin;
    }
  }

  const isPortClosed = portStatus?.selectedBin === 0;

  const compartmentNumberWithStatuses = useMemo(() => {
    if (!binState?.compartments) return [];
    return binState.compartments.map((compartment) => {
      const { autostoreCompartmentNumber, isAutostoreBinCompartmentEmpty } =
        compartment;
      return {
        autostoreCompartmentNumber,
        isAutostoreBinCompartmentEmpty
      };
    });
  }, [binState?.compartments]);

  const portSide = binState?.portSide && parsePortSide(binState?.portSide);
  const isCurrentPortActive =
    (portSide === "Right" && portSideIndex === 1) ||
    (portSide === "Left" && portSideIndex === 0);

  //quantity
  const pickQuantity = isInPickingMode && currentPick?.quantity?.value;
  const selectedTaskQuantity =
    isInProblemSolvingMode && currentTaskForBinAtPort?.quantity?.value;
  const asBinQuantityToDisplay = pickQuantity || selectedTaskQuantity || "";

  //compartmentNumber
  const currentPickCompartment =
    isInPickingMode && currentPick?.compartmentNumber;
  const selectedTaskCompartment =
    isInProblemSolvingMode && currentSelectedBin?.compartmentNumber;
  const possiblePickCompartmentvalue =
    currentPickCompartment || selectedTaskCompartment;
  const compartmentNumberToDisplay =
    portStatus?.selectedBin !== 0 && !!possiblePickCompartmentvalue
      ? possiblePickCompartmentvalue - 1
      : null;

  // horizontalCompartmentCount & verticalCompartmentCount
  const numberOfRows = horizontalCompartmentCount || 1;
  const numberOfColumns = verticalCompartmentCount || 1;
  const numberOfRowsToDisplay =
    isCurrentPortActive && workstation.multiPortEnabled
      ? numberOfRows
      : workstation.multiPortEnabled
        ? 1
        : numberOfRows;
  const numberOfColumnsToDisplay =
    isCurrentPortActive && workstation.multiPortEnabled
      ? numberOfColumns
      : workstation.multiPortEnabled
        ? 1
        : numberOfColumns;

  const currentPickBin = currentPick?.autostoreBinId
    ? Number(currentPick?.autostoreBinId)
    : undefined;
  const autostoreBinId = portStatus?.selectedBin || currentPickBin;
  const binInformation = `${t("bin")} ${autostoreBinId}`;
  const activityStateToDisplay = isPortClosed ? "Port Closed" : activityState;

  return (
    <Box
      sx={{
        gridRow: "2/3",
        p: 1,
        width: "100%"
      }}
      key={`port-${portId || "none"}-${portSideIndex}`}
      aria-label="autostore bin"
    >
      {!!error && portSideIndex === 0 && (
        <Alert
          sx={{
            gridColumn: workstation?.multiPortEnabled ? "span 2" : "unset"
          }}
          variant="outlined"
          severity="error"
          action={<Button onClick={refetch}>Retry</Button>}
        >
          {getMessageFromRtkError(error)}
        </Alert>
      )}
      {!error && (
        <Stack flexDirection="column">
          {parentPortId && workstation.multiPortEnabled && (
            <Typography
              fontSize={"1.8rem"}
              fontWeight={500}
              textAlign="center"
              gridRow="1/2"
            >
              {`${t("port")} ${childPortId}`}
            </Typography>
          )}
          <AutostoreBin
            state={activityStateToDisplay}
            binId={autostoreBinId}
            pickQuantity={asBinQuantityToDisplay}
            pickCompartment={compartmentNumberToDisplay}
            numberOfRows={numberOfRowsToDisplay}
            numberOfColumns={numberOfColumnsToDisplay}
            hideBinId={isInPickingMode}
            compartmentNumberWithStatuses={compartmentNumberWithStatuses}
            isSelectedCompartmentInventoryOnHold={
              (currentPick?.isOnHold || false) && isCurrentPortActive
            }
          />
          {autostoreBinId && isInPickingMode && isCurrentPortActive && (
            <Typography fontSize={"1.5rem"} textAlign="center">
              {binInformation}
            </Typography>
          )}
        </Stack>
      )}
    </Box>
  );
};

const WorkstationPort = (props: {
  workstation: WorkstationSummaryDto;
  portId: number;
  currentTaskForBinAtPort?: BinMovementAssignment;
  currentInventoryMovementBins?: InventoryMovementResponse;
  focusedPick?: PickingStateV2FocusedPick;
}) => {
  const { workstation, portId } = props;
  const targetPorts = useMemo(
    () =>
      workstation?.ports.filter(
        (port) => port.parentPortId === portId || port.portId === portId
      ),
    [workstation?.ports, portId]
  );

  return (
    <>
      {targetPorts?.map((p, i) => (
        <WorkstationPortView
          {...props}
          key={p.portId}
          childPortId={p.portId}
          portSideIndex={i}
        />
      ))}
    </>
  );
};

const PortBinsView = () => {
  const { t } = useTranslation();
  const workstation = useAppSelector(selectAdminSummariesWorkstation);
  const availablePorts = useAppSelector(
    selectAdminSummariesWorkstationAvailablePorts
  );
  const workstationId = workstation?.id;
  const autostoreGridId = workstation?.autostoreGridId;
  const isInProblemSolvingMode =
    workstation?.mode?.toLowerCase() === "problem solving";
  const isInPickingMode = workstation?.mode?.toLowerCase() === "picking";

  const { data: selectedInventoryMovementTasks } =
    useGetInventoryMovementsStateQuery(
      autostoreGridId && workstationId && isInProblemSolvingMode
        ? { autostoreGridId, workstationId }
        : skipToken
    );
  const { data: currentInventoryMovementBins } =
    usePostStartInventoryMovementsQuery(
      autostoreGridId && workstationId && isInProblemSolvingMode
        ? { autostoreGridId, workstationId }
        : skipToken
    );
  const { data: focusedPick } = useGetFocusedPickQuery(
    workstationId && isInPickingMode ? { workstationId } : skipToken
  );
  const currentTaskForBinAtPort = useMemo(() => {
    if (
      !selectedInventoryMovementTasks ||
      !selectedInventoryMovementTasks?.length ||
      !currentInventoryMovementBins
    )
      return;
    return selectedInventoryMovementTasks.find(
      (task) =>
        task.binId ===
          currentInventoryMovementBins?.sourceBin?.autostoreBinId &&
        task.compartmentNumber ===
          currentInventoryMovementBins.sourceBin.compartmentNumber
    );
  }, [selectedInventoryMovementTasks, currentInventoryMovementBins]);

  const parentPortId = workstation?.ports?.[0]?.parentPortId || null;
  const columns = workstation?.multiPortEnabled
    ? availablePorts.length + 1
    : availablePorts.length;

  if (!workstation) return <></>;

  return (
    <Box
      display="grid"
      gridTemplateRows={parentPortId ? "30px 1fr" : "300px"}
      gap={3}
      justifyContent="flex-start"
      width="100%"
    >
      {parentPortId && (
        <Typography
          fontSize={"1.8rem"}
          fontWeight={500}
          gridRow="1/2"
          textAlign="center"
        >
          {`${t("Parent Port")} ${parentPortId}`}
        </Typography>
      )}
      <Box
        display="grid"
        gridTemplateColumns={
          parentPortId && workstation.multiPortEnabled
            ? "auto"
            : `repeat(${columns}, minmax(250px, 400px))`
        }
        columnGap={3}
      >
        {availablePorts.map((portId) => (
          <Box
            display="grid"
            gridTemplateRows={parentPortId !== portId ? "30px auto" : "auto"}
            gridTemplateColumns={
              parentPortId && workstation.multiPortEnabled
                ? `repeat(${columns}, minmax(250px, 400px))`
                : "minmax(250px, 400px)"
            }
            rowGap={1}
            columnGap={3}
            justifyContent="center"
            alignContent="center"
            width="100%"
            key={`avalable-port-${portId || "none"}`}
            aria-label="workstation port"
          >
            {parentPortId !== portId && (
              <Typography
                fontSize={"1.8rem"}
                fontWeight={500}
                textAlign="center"
                gridRow="1/2"
              >
                {`${t("port")} ${portId}`}
              </Typography>
            )}
            <WorkstationPort
              key={portId}
              portId={portId}
              workstation={workstation}
              currentTaskForBinAtPort={currentTaskForBinAtPort}
              currentInventoryMovementBins={currentInventoryMovementBins}
              focusedPick={focusedPick ?? undefined}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PortBinsView;
