import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, IconButton, Stack, TextField, styled } from "@mui/material";
import { useToast } from "@qubit/autoparts";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { PutAwayTaskSummaryDto } from "~/types/api";

import {
  selectChangedQuantity,
  setChangedQuantity
} from "./autostorePutaway.slice";

const AdjustQuantityButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  backgroundColor: theme.palette.gray.dark,
  borderRadius: "10px",
  ":hover": {
    backgroundColor: theme.palette.darkGray.light
  }
}));

type Props = {
  selectedRow: PutAwayTaskSummaryDto;
};

export const QuantityField = (props: Props) => {
  const { selectedRow } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { warningToast } = useToast();
  const quantityMaxValue = 10000;
  const remainingValue = selectedRow?.remaining.value || quantityMaxValue;

  const changedQuantity = useAppSelector(selectChangedQuantity);

  const handleQuantityChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const inputValue = parseInt(e.target.value || "0", 10);
    if (inputValue <= remainingValue && inputValue < quantityMaxValue) {
      dispatch(setChangedQuantity(inputValue));
    } else if (inputValue > remainingValue) {
      warningToast(
        t("max quantity for selected item is") + ` ${remainingValue}`
      );
    }
  };

  const onClickIncreaseQuantity = () => {
    if (!selectedRow) {
      return;
    }
    const previousQuantity = changedQuantity ?? selectedRow.remaining.value;
    const newQuantity =
      changedQuantity === undefined ? 1 : previousQuantity + 1;
    if (newQuantity <= remainingValue) {
      dispatch(setChangedQuantity(newQuantity));
    } else if (selectedRow?.remaining) {
      warningToast(
        t("max quantity for selected item is") + ` ${remainingValue}`
      );
    }
  };

  const onClickDecreaseQuantity = () => {
    if (!selectedRow) {
      return;
    }
    const previousQuantity = changedQuantity ?? selectedRow.remaining.value;
    const newQuantity =
      changedQuantity === undefined ? 0 : previousQuantity - 1;
    if (newQuantity > 0) {
      dispatch(setChangedQuantity(newQuantity));
    } else {
      warningToast("Quantity must be greater than zero");
    }
  };

  return (
    <Box sx={{ display: "flex", gap: 1, height: "100%" }}>
      <TextField
        label={t("quantity")}
        variant="outlined"
        inputProps={{
          style: {
            fontSize: "48px",
            textAlign: "center"
          }
        }}
        InputLabelProps={{
          sx: {
            color: "gray.main.dark",
            fontSize: 22
          },
          shrink: true
        }}
        sx={{
          "& .MuiInputBase-root": {
            height: "100%"
          },
          "& .MuiOutlinedInput-notchedOutline > legend": {
            fontSize: "unset"
          }
        }}
        onChange={handleQuantityChange}
        value={changedQuantity || ""}
        error={!changedQuantity}
      />

      <Stack direction="column" gap="12px" justifyContent="center">
        <AdjustQuantityButton
          aria-label="quantity-increase"
          onClick={onClickIncreaseQuantity}
          size="large"
        >
          <KeyboardArrowUpIcon />
        </AdjustQuantityButton>
        <AdjustQuantityButton
          aria-label="quantity-decrease"
          onClick={onClickDecreaseQuantity}
          size="large"
          disabled={!changedQuantity}
        >
          <KeyboardArrowDownIcon />
        </AdjustQuantityButton>
      </Stack>
    </Box>
  );
};
