import { createSelector } from "@reduxjs/toolkit";

import { AppSelector, selectState } from "~/app/store";
import { getSelectedAdminSummariesWorkstationId } from "~/features/serviceSupport/serviceSupport.slice";
import { findRole } from "~/lib/helpers";

import { groupBy } from "~/lib/shared";
import { StoreState } from "~/redux/reducers";

import { workstationApi } from "~/redux/warehouse/workstation";

import { selectUsersFulfillmentCenter } from "./storeSelectors";

export const selectThisWorkstation = (state: StoreState) =>
  state.workstations.siteWorkstation;
export const selectWorkstationId = (state: StoreState) =>
  state.workstations.siteWorkstation?.id;
export const selectWorkstationAutostoreGridId = (state: StoreState) =>
  state.workstations.siteWorkstation?.autostoreGridId;
export const selectWorkstationOutboundPortId = (state: StoreState) =>
  state.workstations.siteWorkstation?.ports.find(
    (p) => p.cleaningDirection === "Outbound"
  )?.portId;
export const selectWorkstationInboundPortId = (state: StoreState) =>
  state.workstations.siteWorkstation?.ports.find(
    (p) => p.cleaningDirection === "Inbound"
  )?.portId;
export const selectSelectedPortId = (state: StoreState) =>
  state.workstations.sitePortId;
export const selectWorkstationHasPickingRole: AppSelector<boolean> =
  createSelector([selectThisWorkstation], (siteWorkstation) =>
    findRole(siteWorkstation?.roles, "picking")
  );
export const selectWorkstationHasInventoryRole: AppSelector<boolean> =
  createSelector([selectThisWorkstation], (siteWorkstation) =>
    findRole(siteWorkstation?.roles, "inventory")
  );
export const selectWorkstationHasInductionRole: AppSelector<boolean> =
  createSelector([selectThisWorkstation], (siteWorkstation) =>
    findRole(siteWorkstation?.roles, "induction")
  );
export const selectThisWorkstationInboundCleaningPortId: AppSelector<
  number | undefined
> = createSelector(
  [selectThisWorkstation],
  (siteWorkstation) =>
    siteWorkstation?.ports.find((port) => port.cleaningDirection === "Inbound")
      ?.portId
);

export const selectNextEmptyBinByPort = (state: StoreState) =>
  state.autostore.nextEmptyBinByPort;

export const selectSitePortIds = (state: StoreState) =>
  state.workstations.siteAllPortIds;

export const selectSitePortId = (state: StoreState) =>
  state.workstations.sitePortId;

export const selectWorkstationAvailablePorts: AppSelector<number[]> =
  createSelector([selectThisWorkstation], (workstation) => {
    const ports = groupBy(workstation?.ports || [], (p) =>
      workstation?.multiPortEnabled && p.parentPortId
        ? `${p.parentPortId}`
        : `${p.portId}`
    );
    return Object.keys(ports).map((portId) => parseInt(portId, 10));
  });

export const selectAdminSummariesWorkstation = createSelector(
  [getSelectedAdminSummariesWorkstationId, selectState],
  (workstationId, state) => {
    const { data } = workstationApi.endpoints.getWorkstations.select()(state);
    return data?.find((ws) => ws.id === workstationId);
  }
);

export const selectAdminSummariesWorkstationAvailablePorts = createSelector(
  [selectAdminSummariesWorkstation],
  (workstation) => {
    const ports = groupBy(workstation?.ports || [], (p) =>
      workstation?.multiPortEnabled && p.parentPortId
        ? `${p.parentPortId}`
        : `${p.portId}`
    );

    return Object.keys(ports).map((portId) => parseInt(portId, 10));
  }
);

const emptyArray: number[] = [];

export const selectWorkstationAllPorts: AppSelector<number[]> = createSelector(
  [selectThisWorkstation],
  (workstation) => {
    if (!workstation) return emptyArray;
    const ports = workstation.ports.map((p) => p.portId);
    const parentPorts = workstation.ports
      .map((p) => p.parentPortId)
      .filter<number>((p) => p !== undefined);
    return [...ports, ...parentPorts];
  }
);

export const selectIfWorkstationsTabShouldBeEnabled: AppSelector<boolean> =
  createSelector(
    [selectUsersFulfillmentCenter],
    (usersFulfillmentCenter) =>
      !!usersFulfillmentCenter?.pickingConfigurations.includes("Autostore")
  );
