import { Box } from "@mui/material";

import { ProductCard, useToast } from "@qubit/autoparts";

import { skipToken } from "@reduxjs/toolkit/query";
import { useCallback } from "react";

import { ScanningVerification } from "~/api/scanningVerifyMethod";
import { useAppDispatch, useAppSelector } from "~/app/store";
import envConstants from "~/config/envConstants";
import useFlag from "~/config/flags";

import { usePortStatusQuery } from "~/hooks/usePortStatus";
import { displayPickData } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { setIsPickQuantityConfirmed } from "~/redux/actions";
import { selectIsPickQuantityConfirmed } from "~/redux/selectors/autostoreSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";

import {
  selectWorkstationAutostoreGridId,
  selectWorkstationAvailablePorts,
  selectWorkstationId
} from "~/redux/selectors/workstationsSelectors";
import { useGetFocusedPickQuery } from "~/redux/warehouse/autostorePicking.hooks";

import { useVerifyPickMutation } from "~/redux/warehouse/pick.hooks";

import { BarcodeInput } from "./BarcodeInput";

import { setPickedQuantityModalStatus } from "./confirmPickQuantityModal/confirmPickQuantityModal.slice";

export default function FocusedPickInfoCard() {
  const dispatch = useAppDispatch();

  const fulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const pickQuantityConfirmationEnabled =
    fulfillmentCenter?.pickQuantityConfirmationEnabled ?? false;
  const workstationId = useAppSelector(selectWorkstationId);
  const gridId = useAppSelector(selectWorkstationAutostoreGridId);
  const isPickQuantityConfirmed = useAppSelector(selectIsPickQuantityConfirmed);

  // only expecting one parent port at this time since picking does not support independent multi-ports
  const [portId] = useAppSelector(selectWorkstationAvailablePorts);
  const portStatus = usePortStatusQuery(portId);
  const binIsPresent = portStatus.isReady;

  const { data: focusedPick, isLoading: isFocusedPickLoading } =
    useGetFocusedPickQuery(workstationId ? { workstationId } : skipToken);

  const [verifyPick] = useVerifyPickMutation();

  const { errorToast } = useToast();

  const simulateUpcScanFeatureFlag = useFlag().simulateUpcScan;

  const matchEnteredUpcOrSku = useCallback(
    async (enteredUpc?: string) => {
      if (!focusedPick) return false;
      const pickId = focusedPick.pickId;
      const finalUpcOrSku =
        enteredUpc || focusedPick.primaryUpc || focusedPick.sku;

      try {
        await verifyPick({
          upcOrSku: finalUpcOrSku,
          pickId,
          verifyMethod: ScanningVerification.INPUT,
          workstationId: workstationId
        });
        // When the pick quantity is 1, we don't want to show the pick quantity confirmation modal
        if (focusedPick.quantity?.value === 1) {
          dispatch(setIsPickQuantityConfirmed(true));
          dispatch(
            setPickedQuantityModalStatus({
              isShown: false,
              pickedQuantity: null,
              selectedBinId: null
            })
          );

          // The user should be able to complete the pick from PTL also
          await displayPickData({
            portId,
            pickId,
            batchId: focusedPick.batchId,
            gridId
          });
        } else {
          // When the pick quantity is > 1, display the pick quantity confirmation modal.
          // colin: PTL should be enabled later, but where?
          //  PTL should probably be enabled in response to the pick quantity confirmed = true or the choreography that manages that flow.
          dispatch(
            setPickedQuantityModalStatus({
              isShown: true,
              pickedQuantity: focusedPick.quantity?.value,
              selectedBinId: focusedPick.autostoreBinId
            })
          );
        }
        return true;
      } catch (error) {
        errorToast(getMessageFromRtkError(error));
        return false;
      }
    },
    [
      dispatch,
      errorToast,
      verifyPick,
      gridId,
      workstationId,
      portId,
      focusedPick
    ]
  );

  const confirmationRequired =
    pickQuantityConfirmationEnabled &&
    simulateUpcScanFeatureFlag &&
    binIsPresent &&
    !isPickQuantityConfirmed;

  return (
    <Box mt={"1.25em"}>
      {!isFocusedPickLoading && focusedPick ? (
        <ProductCard.Root
          productName={focusedPick.productName}
          quantity={focusedPick.countOnHand?.value}
          imageFileName={focusedPick.imageFilename}
          onProductImageClick={matchEnteredUpcOrSku}
          disabled={!confirmationRequired}
        >
          {pickQuantityConfirmationEnabled && (
            <BarcodeInput matchEnteredUpcOrSku={matchEnteredUpcOrSku} />
          )}
          <ProductCard.Sku sku={focusedPick.sku} />
          <ProductCard.ProductCount
            count={{
              value: focusedPick.unitAmount,
              units: focusedPick.unitFormatted
            }}
          />
          {envConstants.SHOW_SCANNABLE_BAR_CODES === "true" && (
            <ProductCard.QRCode upc={focusedPick.primaryUpc} />
          )}
        </ProductCard.Root>
      ) : (
        <ProductCard.Loading innerHeight="32vH" />
      )}
    </Box>
  );
}
