import { Box, Stack } from "@mui/system";

import { skipToken } from "@reduxjs/toolkit/query";

import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { UnifyPortErrors } from "~/features/serviceSupport/UnifyPortErrors";
import { setSelectedAdminSummariesWorkstationId } from "~/features/serviceSupport/serviceSupport.slice";
import WorkstationsSelect from "~/features/workstationConfig/WorkstationsSelect";

import { debounce } from "~/hooks/useDebounce";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import { useGridV2Subscription, usePickSubscription } from "~/lib/signalr";
import {
  selectAdminSummariesWorkstation,
  selectAdminSummariesWorkstationAvailablePorts
} from "~/redux/selectors/workstationsSelectors";

import { useGetFocusedPickQuery } from "~/redux/warehouse/autostorePicking.hooks";

import { warehouseApi } from "~/redux/warehouse/warehouseApi";
import { useLazyGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";
import { AutostoreEvent, SignalRPickEventDto } from "~/types/api";

import CurrentPickProductView from "./CurrentPickProductView";
import PortBinsView from "./PortBinsView";
import WorkstationDetailsView from "./WorkstationDetailsView";

type AdminSummariesWorkstationViewProps = {
  viewTitle?: ViewNameTranslation;
};

const AdminSummariesWorkstationView = (
  props: AdminSummariesWorkstationViewProps
) => {
  const { viewTitle } = props;
  const dispatch = useAppDispatch();
  const workstation = useAppSelector(selectAdminSummariesWorkstation);
  const workstationId = workstation?.id;
  const autostoreGridId = workstation?.autostoreGridId;
  const isInProblemSolvingMode =
    workstation?.mode?.toLowerCase() === "problem solving";
  const isInPickingMode = workstation?.mode?.toLowerCase() === "picking";
  const availablePorts = useAppSelector(
    selectAdminSummariesWorkstationAvailablePorts
  );

  useNavbar({ viewTitle });
  const { data: focusedPick, isFetching: isLoadingPick } =
    useGetFocusedPickQuery(
      workstationId && isInPickingMode ? { workstationId } : skipToken,
      {
        refetchOnMountOrArgChange: true
      }
    );
  const [triggerFetchWorkstations] = useLazyGetWorkstationsQuery();

  const pickSubscription = (event: SignalRPickEventDto) => {
    const doesPickEventIncludeWsPort = event.portId
      ? availablePorts.includes(event.portId)
      : false;
    if (
      isInPickingMode &&
      event.pick?.status?.toLowerCase() != "completed" &&
      doesPickEventIncludeWsPort
    ) {
      void debouncedRefetchFocusedPick();
    }
  };

  const debouncedRefetchInventoryTasks = debounce(() => {
    dispatch(warehouseApi.util.invalidateTags(["start inventory movements"]));
    dispatch(warehouseApi.util.invalidateTags(["inventory movements"]));
  }, 1000);

  const debouncedRefetchWorkstations = debounce(() => {
    void triggerFetchWorkstations();
  }, 1000);
  const debouncedRefetchFocusedPick = debounce(() => {
    dispatch(warehouseApi.util.invalidateTags(["focused pick"]));
  }, 1000);

  const gridSubscription = (event: AutostoreEvent) => {
    if (!workstation) return;

    if (["PortMode", "BinRequested"].includes(event.case)) {
      const doesGridEventIncludeWsPort =
        "portId" in event.event &&
        availablePorts.includes(event.event.portId as number);

      if (!doesGridEventIncludeWsPort || event.event.gridId !== autostoreGridId)
        return;

      if (event.case === "PortMode") {
        const { portMode } = event.event;
        switch (portMode) {
          case "Open":
            void debouncedRefetchWorkstations();
            break;
          case "Sequence":
            void debouncedRefetchInventoryTasks();
            break;
        }
      }

      if (event.case === "BinRequested" && isInProblemSolvingMode) {
        void debouncedRefetchInventoryTasks();
      }
    }
  };
  useEffect(() => {
    if (!workstation) return;

    void debouncedRefetchWorkstations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workstation]);

  usePickSubscription(pickSubscription);
  useGridV2Subscription(gridSubscription);

  return (
    <Box
      sx={{
        p: 3,
        display: "grid",
        gridTemplateColumns: "minmax(200px, 300px) 1fr",
        gap: 3,
        width: "95%",
        "@media (max-width: 1350px)": {
          gridTemplateColumns: "1fr",
          gridTemplateRows: "120px 1fr",
          p: 2,
          pl: 3,
          gap: 1
        }
      }}
    >
      <WorkstationsSelect
        dropdownWorkstationId={workstation?.id || ""}
        onWorkstationChange={(workstationId) => {
          dispatch(setSelectedAdminSummariesWorkstationId(workstationId));
        }}
      />
      {workstation && (
        <Stack
          gridColumn="2/3"
          flexDirection="column"
          gap={5}
          alignItems="flex-start"
          width="100%"
          pl={3}
          sx={{
            "@media (max-width: 1350px)": {
              gridColumn: "1/2",
              pl: 0,
              justifyContent: "center"
            }
          }}
        >
          <WorkstationDetailsView />
          <PortBinsView />
          {focusedPick && isInPickingMode && (
            <CurrentPickProductView
              currentPick={focusedPick}
              loadingPick={isLoadingPick}
            />
          )}
          <Box width="100%">
            <UnifyPortErrors
              requestedGridId={workstation?.autostoreGridId}
              requestedWorkstationId={workstation?.id}
            />
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default AdminSummariesWorkstationView;
