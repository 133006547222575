import HomeIcon from "@locaisolutions/icons/dist/icons20px/Home20Px";

import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Skeleton,
  Typography,
  useTheme
} from "@mui/material";
import { ProgressButton, useToast, DialogRoot } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { StartPickingNextBatchButton } from "~/features/autostorePicking/StartPickingNextBatchButton";

import { useClientConfig } from "~/hooks/useClientConfig";
import { mixpanelStartPickingWrapper } from "~/lib/mixpanel-tracking";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  selectSelectedPortId,
  selectThisWorkstation
} from "~/redux/selectors/workstationsSelectors";
import { useGetStatusCountQuery } from "~/redux/warehouse/batches.hooks";

import SmallNumberStat from "./SmallNumberStat";

import { closeStartPickingModal } from "./startPicking.slice";

type StartPickingModalProps = {
  open: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg";
  onNextPickButtonClickCallback: () => Promise<string | null>;
  onHomeButtonClickCallback: () => (Promise<void> | void) | null;
  batchName?: string;
  title: string;
  text?: string;
  pickButtonText: string;
  closeEnabled?: boolean;
};

export function StartPickingModal(props: StartPickingModalProps) {
  const { t } = useTranslation();
  const { errorToast } = useToast();
  const dispatch = useAppDispatch();

  const {
    open,
    maxWidth,
    onHomeButtonClickCallback,
    onNextPickButtonClickCallback,
    batchName,
    title,
    text,
    pickButtonText,
    closeEnabled
  } = props;

  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const cartAssignmentEnabled = useClientConfig().cartAssignmentEnabled;
  const portId = useAppSelector(selectSelectedPortId);

  const {
    data: batchCount,
    isLoading,
    isError,
    error
  } = useGetStatusCountQuery(
    siteWorkstation && open
      ? {
          gridId: siteWorkstation?.autostoreGridId,
          portId
        }
      : skipToken,
    {
      refetchOnMountOrArgChange: true
    }
  );
  const { palette } = useTheme();

  useEffect(() => {
    if (error && open) {
      errorToast(getMessageFromRtkError(error));
      dispatch(closeStartPickingModal());
    }
  }, [errorToast, error, dispatch, open]);

  if (error) {
    return <></>;
  }

  return (
    <DialogRoot
      open={open}
      maxWidth={maxWidth}
      fullWidth
      onClose={() => {
        if (closeEnabled) dispatch(closeStartPickingModal());
      }}
    >
      <Paper>
        <DialogTitle>
          <Grid container direction="column" textAlign="center">
            <Grid item>
              <Typography
                sx={{ fontWeight: 500, fontSize: "24px" }}
                data-testid="title-dialog-text"
              >
                {title}
              </Typography>
            </Grid>
            <Grid item>
              {batchName && (
                <Typography
                  sx={{ fontSize: ".8em" }}
                  data-testid="title-dialog-text"
                >
                  {`Batch Name: ${batchName}`}
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Grid container direction="column">
            <Grid item>
              {text && (
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "1.25em"
                  }}
                  data-testid="dialog-content-text"
                >
                  {text}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              style={{ display: "flex", justifyContent: "center" }}
              aria-label="batch-status-count"
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center"
                }}
              >
                {isLoading && (
                  <Skeleton variant="rectangular" width="100%" height={56} />
                )}
                {!isError &&
                  batchCount &&
                  Object.keys(batchCount)
                    .sort()
                    .map((key) => {
                      let textToDisplay = "";
                      let someColor = palette.darkGray.main;
                      let backgroundColor = "white";

                      switch (key) {
                        case "cartReady":
                          if (!cartAssignmentEnabled) return null;
                          textToDisplay = t("cart ready");
                          someColor = palette.primary.main;
                          backgroundColor = palette.primary.light;
                          break;
                        case "scheduled":
                          textToDisplay = t("scheduled");

                          if (!cartAssignmentEnabled) {
                            someColor = palette.primary.main;
                            backgroundColor = palette.primary.light;
                          }
                          break;
                        case "suspended":
                          if (batchCount[key] === 0) return null;
                          textToDisplay = t("suspended");
                          break;
                        default:
                          return null;
                      }

                      return (
                        <SmallNumberStat
                          key={key}
                          label={textToDisplay}
                          number={batchCount[key]}
                          labelColor={someColor}
                          numberColor={someColor}
                          backroundColor={backgroundColor}
                        />
                      );
                    })}
              </Box>
            </Grid>

            <Grid item style={{ marginTop: "5vh" }}>
              <Grid container justifyContent="space-between">
                <Grid item xs={5}>
                  <ProgressButton
                    data-testid="home-button"
                    buttonSize="xLarge"
                    emphasis="high"
                    responsive
                    variant="contained"
                    color="secondary"
                    sx={{ textTransform: "capitalize" }}
                    fullWidth
                    startIcon={<HomeIcon />}
                    onClick={(): (Promise<void> | void) | null => {
                      mixpanelStartPickingWrapper(
                        "Start Picking Modal",
                        "Button Click",
                        "Home",
                        batchCount
                      );
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                      onHomeButtonClickCallback();
                    }}
                  >
                    {t("nav.link.home")}
                  </ProgressButton>
                </Grid>
                <Grid item xs={5}>
                  <StartPickingNextBatchButton
                    startBatchCallback={async () => {
                      mixpanelStartPickingWrapper(
                        "Start Picking Modal",
                        "Button Click",
                        "Start/Next Pick",
                        batchCount
                      );
                      return await onNextPickButtonClickCallback();
                    }}
                  >
                    {pickButtonText}
                  </StartPickingNextBatchButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Paper>
    </DialogRoot>
  );
}
