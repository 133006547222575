import { LoadingPage, useToast } from "@qubit/autoparts";
import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import envConstants from "~/config/envConstants";
import { useClientConfig } from "~/hooks/useClientConfig";
import {
  clearFulfillmentCenter,
  fetchFulfillmentCenter
} from "~/redux/actions/store";
import { sendUserEventAction } from "~/redux/actions/userEvents";
import { selectClientConfigReady } from "~/redux/selectors/appSelectors";
import { selectAuth0UserId } from "~/redux/selectors/authSelectors";
import { useLazyGetAllFulfillmentCentersQuery } from "~/redux/warehouse/fulfillmentCenter.hooks";

import { handleAuthCallback } from "./login.slice";

const connector = connect(null, {
  fetchFulfillmentCenter,
  clearFulfillmentCenter,
  sendUserEventAction
});

type PropsFromRedux = ConnectedProps<typeof connector>;
type AuthCallBackProps = PropsFromRedux;

function AuthCallback(props: AuthCallBackProps) {
  const {
    fetchFulfillmentCenter,
    clearFulfillmentCenter,
    sendUserEventAction
  } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { errorToast } = useToast();
  const { t } = useTranslation();

  const [getAllFulfillmentCenters] = useLazyGetAllFulfillmentCentersQuery();

  const siteFulfillmentCenterId = useAppSelector(
    (state) => state.workstations.siteFulfillmentCenterId
  );
  const loginPath = useAppSelector((state) => state.site.loginPath);
  const loginPathToUse: string = loginPath || "/login";

  const { manualOpsEnabled } = useClientConfig();

  const clientConfigReady = useAppSelector(selectClientConfigReady);

  const currentUserId = useAppSelector(selectAuth0UserId);

  const [authCallbackSuccess, setAuthCallbackSuccess] = useState<
    boolean | null
  >(null);
  const [authCallbackStarted, setAuthCallbackStarted] = useState(false);

  useEffect(() => {
    if (envConstants.RUNTIME_ENVIRONMENT !== "production") return;
    if (currentUserId) mixpanel.identify(currentUserId);
  }, [currentUserId]);

  useEffect(() => {
    if (!authCallbackStarted) {
      setAuthCallbackStarted(true);
      const performAuthCallback = async () => {
        const resultAction = await dispatch(handleAuthCallback());
        if (handleAuthCallback.fulfilled.match(resultAction)) {
          setAuthCallbackSuccess(true);
        } else {
          setAuthCallbackSuccess(false);
        }
      };
      void performAuthCallback();
    }
  }, [authCallbackStarted, dispatch]);

  useEffect(() => {
    if (!clientConfigReady) return; // Wait for the flag.
    if (authCallbackSuccess === null) return; // Wait until auth callback completes.

    const handleSuccess = async () => {
      try {
        await sendUserEventAction("UserLoggedIn");
      } catch {
        errorToast("Failed to send user event action");
      }

      try {
        const clientFcs = await getAllFulfillmentCenters().unwrap();
        const clientFcIds = clientFcs.map((fc) => fc.fulfillmentCenterId) || [];

        if (
          siteFulfillmentCenterId &&
          clientFcIds.includes(siteFulfillmentCenterId)
        ) {
          await fetchFulfillmentCenter(siteFulfillmentCenterId);
        } else {
          clearFulfillmentCenter();
        }
      } catch {
        clearFulfillmentCenter();
      }

      const nextPage = manualOpsEnabled ? "/" : "/autostore-main";
      navigate(nextPage);
    };

    const handleError = () => {
      navigate(loginPathToUse);
    };

    if (authCallbackSuccess) {
      void handleSuccess();
    } else {
      handleError();
    }
  }, [
    clientConfigReady,
    authCallbackSuccess,
    clearFulfillmentCenter,
    errorToast,
    fetchFulfillmentCenter,
    getAllFulfillmentCenters,
    manualOpsEnabled,
    navigate,
    sendUserEventAction,
    siteFulfillmentCenterId,
    loginPathToUse
  ]);

  return <LoadingPage loadingMessage={t("logging on")} />;
}

export default connector(AuthCallback);
