import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import { Box, Container, Grid, useMediaQuery } from "@mui/material";
import Paper from "@mui/material/Paper";
import {
  mobileWidth,
  ErrorPanel,
  Heading,
  Placeholder
} from "@qubit/autoparts";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { OrderTable } from "~/features/orderTable/OrderTable";
import { useClientConfig } from "~/hooks/useClientConfig";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { useGetOrderSummariesQuery } from "~/redux/warehouse/orders.hooks";
import { OrderSummaryDto } from "~/types/api";

import { CreateBatchesToolbar } from "./CreateBatchesToolbar";
import { setSelectedDate, setSelectedOrders } from "./createBatches.slice";

type Props = { viewTitle?: ViewNameTranslation };
function NoOrders() {
  const { t } = useTranslation();
  return (
    <Placeholder container justifyContent="center" alignItems="center">
      <Grid item>
        <SentimentDissatisfiedIcon />
      </Grid>
      <Grid item>
        <Heading>{` ${t("no unbatched")} ${t("nav.viewname.orders")}`}</Heading>
      </Grid>
    </Placeholder>
  );
}

export function CreateBatches(props: Props) {
  const { viewTitle } = props;

  const dispatch = useAppDispatch();

  const selectedOrderIds = useAppSelector(
    (state) => state.createBatches.selectedOrderIds
  );
  const selectedDate = useAppSelector(
    (state) => state.createBatches.selectedDate
  );
  const handleDateChange = useCallback(
    (date: Date | null): void => {
      dispatch(setSelectedDate(date || null));
    },
    [dispatch]
  );
  const datePicker = useMemo(() => {
    return { selectedDate, onDateChange: handleDateChange };
  }, [selectedDate, handleDateChange]);

  const { setToolbar } = useNavbar({
    viewTitle,
    datePicker
  });

  useEffect(() => {
    if (selectedOrderIds.length > 0) {
      setToolbar(<CreateBatchesToolbar />);
    } else {
      setToolbar(undefined);
    }
  }, [setToolbar, selectedOrderIds]);

  const { ord_includeSpokes } = useClientConfig();

  const batches = useAppSelector((state) => state.home.batches);
  const fulfillmentCenter = useAppSelector(
    (state) => state.store.usersFulfillmentCenter
  );

  const batchedOrderIds = new Set(
    batches.flatMap((b) => b.orderSummaries.map((o) => o.orderId))
  );

  const { orders, isOrdersFetching, fetchOrdersError } =
    useGetOrderSummariesQuery(
      {
        date: selectedDate,
        includeSpokes: ord_includeSpokes,
        statuses: ["Received"]
      },
      {
        selectFromResult: ({ data, isFetching, error }) => ({
          orders: (data ?? [])
            ?.filter((o) => !batchedOrderIds.has(o.orderId))
            .sort((a, b) => {
              const { pickingEndTime: aPet, lineItemCount: aLineItems } = a;
              const { pickingEndTime: bPet, lineItemCount: bLineItems } = b;

              const aPetTime = new Date(aPet).getTime();
              const bPetTime = new Date(bPet).getTime();

              // Compare timestamps
              if (aPetTime !== bPetTime) {
                return aPetTime - bPetTime;
              }

              return bLineItems - aLineItems;
            }),
          isOrdersFetching: isFetching,
          fetchOrdersError: error
        })
      }
    );

  useEffect(() => {
    if (!selectedDate) {
      dispatch(setSelectedDate(new Date()));
    }
  }, [selectedDate, dispatch]);

  const isMobile = useMediaQuery(mobileWidth);

  const formatOrderStatus = (order: OrderSummaryDto) =>
    order.status === "Locked" || order.status === "Received"
      ? `(${order.lineItemCount}) Items`
      : order.status;

  return (
    <Container
      sx={{
        marginTop: 4,
        flexWrap: "nowrap",
        minHeight: "100vh",
        maxWidth: "100vw"
      }}
    >
      {fetchOrdersError ? (
        <Container>
          <ErrorPanel message={getMessageFromRtkError(fetchOrdersError)} />
        </Container>
      ) : (
        <Box padding="2px" marginTop="15px">
          <Paper style={{ margin: "0 auto", width: isMobile ? "100%" : "95%" }}>
            {!isOrdersFetching && orders.length === 0 ? (
              <NoOrders />
            ) : (
              <OrderTable
                title={"Create Batches"}
                fulfillmentCenter={fulfillmentCenter}
                formatOrderStatus={formatOrderStatus}
                onOrderSelected={(order) => dispatch(setSelectedOrders(order))}
                orders={orders}
                ordersLoading={isOrdersFetching}
                selectedDate={selectedDate}
                selectedOrderIds={selectedOrderIds}
              />
            )}
          </Paper>
        </Box>
      )}
    </Container>
  );
}
