import {
  Info24Px,
  PersonCircle24Px,
  Workstation24Px
} from "@locaisolutions/icons";
import {
  Box,
  ButtonBase,
  ButtonBaseProps,
  Stack,
  Typography,
  useTheme
} from "@mui/material";
import { mergeSx } from "merge-sx";

interface Props extends ButtonBaseProps {
  alert?: string;
  alertKind?: "workstation" | "user";
  variant?: "sm" | "md" | "lg";
  icon?: React.ReactNode;
}

const variantProps = {
  sm: {
    height: "calc((100vh - 120px)/2)",
    maxHeight: "200px"
  },
  md: {
    height: "calc((100vh - 120px)/2)",
    maxHeight: "200px"
  },
  lg: {
    height: "calc((100vh - 120px))",
    maxHeight: "420px"
  }
};

export function MainButton(props: Props) {
  const {
    alert,
    alertKind,
    disabled,
    sx,
    variant = "lg",
    icon,
    children,
    ...rest
  } = props;
  const theme = useTheme();
  const alertIcon = (alertKind &&
    {
      user: <PersonCircle24Px fill={theme.palette.error.main} />,
      workstation: <Workstation24Px fill={theme.palette.error.main} />
    }[alertKind]) || <Info24Px />;
  return (
    <ButtonBase
      disabled={disabled}
      sx={mergeSx(
        {
          display: "flex",
          justifyContent: "center",
          position: "relative",
          textAlign: "center",
          width: "100%",
          ...variantProps[variant]
        },
        sx
      )}
      {...rest}
    >
      <Box
        display="grid"
        gridTemplateColumns={icon ? "min-content min-content" : "1fr"}
        bgcolor={theme.palette.primary.main}
        color={theme.palette.primary.contrastText}
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        textAlign={icon ? "left" : "center"}
        justifyContent="center"
        alignItems="center"
        borderRadius={1}
        gap={1}
        sx={{
          fontSize: "1.5rem",
          opacity: disabled ? 0.5 : 1
        }}
      >
        {icon}
        {children}
      </Box>

      {alert && (
        <Stack
          direction="row"
          spacing={1}
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          bgcolor={theme.palette.background.default}
          color={theme.palette.text.primary}
          margin={0.2}
          justifyContent="center"
          alignItems="center"
          borderRadius={0.8}
          height="58px"
          role="note"
        >
          <Box>{alertIcon}</Box>
          <Typography variant="body2" lineHeight="1" textAlign="left">
            {alert}
          </Typography>
        </Stack>
      )}
    </ButtonBase>
  );
}
