import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

import { useClientConfig } from "~/hooks/useClientConfig";

export function RedirectToAutostoreMain({ children }: PropsWithChildren) {
  const manualOpsEnabled = useClientConfig().manualOpsEnabled;

  return manualOpsEnabled === false ? (
    <Navigate to="/autostore-main" replace />
  ) : (
    children
  );
}
