import { Container } from "@mui/material";
import { ErrorPanel } from "@qubit/autoparts";
import { useCallback, useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { OrderTable } from "~/features/orderTable/OrderTable";

import { useClientConfig } from "~/hooks/useClientConfig";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { useGetOrderSummariesQuery } from "~/redux/warehouse/orders.hooks";
import { OrderSummaryDto } from "~/types/api";

import { NoOrders } from "./NoOrders";
import { OrdersSearch } from "./OrdersSearch";
import { OrdersToolbar } from "./OrdersToolbar";
import { selectOrder, setSelectedDate } from "./orders.slice";

type Props = { viewTitle?: ViewNameTranslation };

function byWindowThenName(a: OrderSummaryDto, b: OrderSummaryDto) {
  const aWindow = a.pickingEndTime || new Date();
  const bWindow = b.pickingEndTime || new Date();
  if (aWindow === bWindow) {
    return a.customerName.localeCompare(b.customerName);
  }
  return aWindow < bWindow ? -1 : 1;
}

export function Orders(props: Props) {
  const { viewTitle } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { ord_includeSpokes } = useClientConfig();
  const fulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const orderFilter = useAppSelector((state) => state.ordersSlice.orderFilter);
  const selectedDate = useAppSelector(
    (state) => state.ordersSlice.selectedDate
  );
  const selectedOrderIds = useAppSelector(
    (state) => state.ordersSlice.selectedOrderIds
  );
  const orderStatusFilter = useAppSelector(
    (state) => state.ordersSlice.orderStatusFilter
  );

  const handleOrderSelected = useCallback(
    (order: OrderSummaryDto) => {
      dispatch(selectOrder(order.orderId));
    },
    [dispatch]
  );

  const {
    data: orders,
    error: ordersError,
    isLoading: isOrdersLoading
  } = useGetOrderSummariesQuery(
    {
      date: selectedDate,
      fcTimeZone: fulfillmentCenter?.timeZone,
      includeSpokes: ord_includeSpokes,
      search: orderFilter,
      statuses: orderStatusFilter
    },
    // TODO: refetchOnMountOrArgChange can be removed when batch creation invalidates orders through rtk query
    { refetchOnMountOrArgChange: true }
  );

  const handleDateChange = useCallback(
    (date: Date | null): void => {
      dispatch(setSelectedDate(date || null));
    },
    [dispatch]
  );

  const datePicker = useMemo(() => {
    return { selectedDate, onDateChange: handleDateChange };
  }, [selectedDate, handleDateChange]);

  const { setToolbar } = useNavbar({
    centerComponent: useMemo(() => <OrdersSearch />, []),
    datePicker,
    viewTitle
  });

  useEffect(() => {
    if (selectedOrderIds.length) {
      setToolbar(<OrdersToolbar />);
    } else {
      setToolbar(undefined);
    }
  }, [selectedOrderIds.length, setToolbar]);

  useEffect(() => {
    if (!selectedDate) {
      dispatch(setSelectedDate(new Date()));
    }
  }, [dispatch, selectedDate]);

  const noOrders =
    !isOrdersLoading && orders && orders.length === 0 && !ordersError;

  return (
    <Container sx={{ p: 4 }}>
      {!!ordersError && (
        <ErrorPanel message={getMessageFromRtkError(ordersError)} />
      )}
      {!ordersError && (
        <>
          {noOrders ? (
            <NoOrders />
          ) : (
            <OrderTable
              fulfillmentCenter={fulfillmentCenter}
              onOrderSelected={handleOrderSelected}
              orders={orders?.slice().sort(byWindowThenName) || []}
              ordersLoading={isOrdersLoading}
              selectedDate={selectedDate}
              selectedOrderIds={selectedOrderIds}
              title={t("orders")}
            />
          )}
        </>
      )}
    </Container>
  );
}
