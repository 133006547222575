import {
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack
} from "@mui/material";
import { t } from "i18next";

import { useGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 200
    }
  }
};
type WorkstationSelectProps = {
  dropdownWorkstationId: string;
  onWorkstationChange: (workstationId: string) => void;
  disableNoSelectedWsOption?: boolean;
};

const WorkstationsSelect = (props: WorkstationSelectProps) => {
  const {
    dropdownWorkstationId,
    onWorkstationChange,
    disableNoSelectedWsOption = false
  } = props;
  const { sortedWorkstations } = useGetWorkstationsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      sortedWorkstations: data?.toSorted(
        (a, b) =>
          a.autostoreGridName.localeCompare(b.autostoreGridName) ||
          a.deviceId.localeCompare(b.deviceId)
      )
    })
  });
  return (
    <Stack>
      <InputLabel id="workstation-select" shrink>
        {t("workstation")}
      </InputLabel>
      <Select
        labelId="workstation-select"
        style={{ width: 300, marginBottom: 10 }}
        value={sortedWorkstations ? dropdownWorkstationId : ""}
        onChange={(e: SelectChangeEvent) => onWorkstationChange(e.target.value)}
        MenuProps={MenuProps}
        displayEmpty
      >
        {sortedWorkstations?.map((workstation) => (
          <MenuItem key={workstation.deviceId} value={workstation.id}>
            {`${workstation.deviceId}`}
          </MenuItem>
        ))}
        {!disableNoSelectedWsOption && (
          <MenuItem value="">
            <em>{t("no workstation selected")}</em>
          </MenuItem>
        )}
      </Select>
    </Stack>
  );
};

export default WorkstationsSelect;
