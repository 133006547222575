import { Box, Grid, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { AnimatedCheckMark, AnimatedX, ProgressButton } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";

import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import { useAppSelector } from "~/app/store";

import envConstants from "~/config/envConstants";
import { useClientConfig } from "~/hooks/useClientConfig";
import { ternaryIff } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { usePickSubscription } from "~/lib/signalr";
import { selectPtlSimulationEnabled } from "~/redux/selectors/PTLselectors";
import {
  selectBinIsPresent,
  selectCartNumberConfirmed,
  selectIsPickQuantityConfirmed,
  selectNextPickingStateLoading,
  selectSignalR
} from "~/redux/selectors/autostoreSelectors";
import { selectStartPickingModalIsOpen } from "~/redux/selectors/pickingSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { selectWorkstationId } from "~/redux/selectors/workstationsSelectors";
import {
  useGetFocusedPickQuery,
  useGetTotesForBatchQuery
} from "~/redux/warehouse/autostorePicking.hooks";
import { SignalRPickEventDto } from "~/types/api";

import { AutostoreToteHeader } from "./AutostoreToteHeader";
import ExpressIcon from "./ExpressIcon";
import PTL from "./PTL";
import { ToteProgressBar } from "./ToteProgressBar";

function AutostoreTote(props: {
  toteId: Guid;
  position: number;
  externalToteId?: Guid;
  isActiveTote: boolean;
  onClickCb: () => Promise<void | null> | null;
  isLastStackedTote?: boolean;
}) {
  const { palette } = useTheme();
  const {
    toteId,
    position,
    externalToteId,
    isActiveTote,
    onClickCb,
    isLastStackedTote
  } = props;

  const [isExpressOrderState, setIsExpressOrderState] = useState(true);
  const lastRefetchOccurredOn = useRef<number | null>(null);
  const binIsReady = useAppSelector(selectBinIsPresent);
  const nextPickingStateLoading = useAppSelector(selectNextPickingStateLoading);
  const cartNumberConfirmed = useAppSelector(selectCartNumberConfirmed);
  const pickingIsComplete = useAppSelector(selectStartPickingModalIsOpen);
  const fulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const isPickQuantityConfirmed = useAppSelector(selectIsPickQuantityConfirmed);
  const ptlSimulationEnabled = useAppSelector(selectPtlSimulationEnabled);
  const workstationId = useAppSelector(selectWorkstationId);
  const signalR = useAppSelector(selectSignalR);
  const [searchParams] = useSearchParams();
  const { data: focusedPick, refetch: refetchFocusedPick } =
    useGetFocusedPickQuery(
      workstationId ? { workstationId: workstationId } : skipToken,
      {
        refetchOnMountOrArgChange: true,
        pollingInterval:
          signalR?.state?.toLowerCase() === "disconnected" ? 2000 : undefined
      }
    );
  const batchId = searchParams.get("batchId") || focusedPick?.batchId;

  const { tote } = useGetTotesForBatchQuery(
    batchId && position ? { batchId } : skipToken,
    {
      pollingInterval:
        signalR?.state?.toLowerCase() === "disconnected" ? 2000 : undefined,
      refetchOnMountOrArgChange: 5,
      selectFromResult: ({ data }) => ({
        tote: data
          ?.flatMap((pos) => pos.totes)
          ?.find((tote) => tote.toteId === toteId)
      })
    }
  );
  const {
    ap_confirmCartNumberEnabled,
    ap_fusionPortScreenEnabled,
    ap_isTopAreaOfToteClickable
  } = useClientConfig();

  const fusionPortScreen = ap_fusionPortScreenEnabled;
  const isTopAreaClickable = ap_isTopAreaOfToteClickable;

  const pickReady =
    isActiveTote &&
    binIsReady &&
    !nextPickingStateLoading &&
    (!ap_confirmCartNumberEnabled || cartNumberConfirmed);

  // state that should indicate that pick quantity is confirmed
  const confirmDisabled =
    (!!fulfillmentCenter &&
      fulfillmentCenter.pickQuantityConfirmationEnabled &&
      !isPickQuantityConfirmed) ||
    !binIsReady;

  const isToteConfirmDisabled = !!(
    confirmDisabled ||
    !pickReady ||
    pickingIsComplete
  );

  const refetchFocusedPickAndToteContent = () => {
    try {
      const currentDate = Date.now();
      if (lastRefetchOccurredOn.current) {
        const refetchCalledBeforeSeconds =
          currentDate - lastRefetchOccurredOn.current;
        lastRefetchOccurredOn.current = currentDate;
        if (refetchCalledBeforeSeconds < 1000) return;
      }
      lastRefetchOccurredOn.current = currentDate;
      void refetchFocusedPick();
    } catch (e) {
      getMessageFromRtkError(e);
    }
  };

  // Show Checkmark icon on the tote box if:
  // - All picks in the tote are in the 'Completed' status
  // - There is at least one completed pick in the tote and tote is in the 'Picked' status
  const getShowCheckmark = () => {
    if (tote && tote.picks) {
      const isEveryPickCompleted = tote.picks.every(
        (p) => !!p.completedTimestamp
      );
      if (isEveryPickCompleted) return true;

      const isSomePickCompleted = tote.picks.some(
        (p) => !!p.completedTimestamp
      );

      return isSomePickCompleted && tote.pickedTimestamp;
    }
    return false;
  };

  // Show X icon on the tote box if:
  // - If the tote is in the 'Canceled' status
  // - All picks in the tote are in the 'Canceled' status
  const getShowX = () => {
    if (!tote) return false;
    if (tote.canceledTimestamp) return true;
    const isEveryPickCanceled = tote.picks?.every((p) => !!p.canceledTimestamp);
    return !!isEveryPickCanceled;
  };

  const showCheckmark = getShowCheckmark();
  const showX = getShowX();

  useEffect(() => {
    if (tote) {
      const isExpressOrderLocal = tote.priority?.toLowerCase() === "express";
      setIsExpressOrderState(isExpressOrderLocal);
    }
  }, [tote]);

  const pickSubscription = (event: SignalRPickEventDto) => {
    if (event.batchId === batchId && event.pick.assignedToteId === toteId) {
      refetchFocusedPickAndToteContent();
    }
  };
  usePickSubscription(pickSubscription);

  let toteStyle: React.CSSProperties = {
    backgroundColor:
      isActiveTote && !pickingIsComplete
        ? ternaryIff(
            isToteConfirmDisabled,
            palette.gray.contrastText,
            palette.primary.main
          )
        : palette.secondary.main,
    color:
      isActiveTote && !pickingIsComplete
        ? palette.primary.contrastText
        : palette.darkGray.light
  };
  if (showCheckmark)
    toteStyle = {
      backgroundColor: palette.success.light,
      color: palette.success.main
    };

  const matchingPickQuantity = focusedPick && focusedPick.quantity.value;

  const showPickQuantity = isActiveTote;

  const checkColor =
    pickReady && !pickingIsComplete ? palette.success.main : "#0000001f";

  return (
    <Stack
      style={{
        textAlign: "center",
        padding: "0 4px",
        maxWidth: 240,
        margin: "0 auto",
        gap: 8
      }}
      aria-label="Autostore Tote"
    >
      <AutostoreToteHeader
        tote={tote}
        isActiveTote={isActiveTote}
        toteId={toteId}
        pickReady={pickReady}
        position={position}
        isLastStackedTote={isLastStackedTote}
        confirmDisabled={confirmDisabled}
      />
      <Box sx={{ position: "relative" }}>
        {!isTopAreaClickable && (
          <Box
            sx={{
              height: "35px",
              width: "100%",
              position: "absolute",
              top: "-7%",
              zIndex: 1
            }}
          />
        )}
        <ProgressButton
          buttonSize="xLarge"
          emphasis="high"
          responsive
          variant="contained"
          data-testid={isActiveTote && pickReady ? "activeTote" : ""}
          aria-label="active tote"
          disabled={isToteConfirmDisabled}
          onClick={async () => {
            try {
              await onClickCb();
              refetchFocusedPickAndToteContent();
            } catch (e) {
              getMessageFromRtkError(e);
            }
          }}
          style={toteStyle}
          sx={{
            "@keyframes pulse": {
              from: {
                boxShadow: "0 0 0 0px rgba(0, 0, 0, 0.4)"
              },
              to: {
                boxShadow: "0 0 0 20px rgba(0, 0, 0, 0)"
              }
            },
            width: "100%",
            ...(pickReady &&
              !pickingIsComplete &&
              !isToteConfirmDisabled && { animation: "pulse 2s infinite ease" })
          }}
        >
          <Box
            height={
              (envConstants.ENABLE_PTL_SIMULATION === "true" &&
                ptlSimulationEnabled) ||
              fusionPortScreen
                ? 140
                : 240
            }
            width="100%"
          >
            <Grid
              container
              spacing={2}
              direction="row"
              wrap="nowrap"
              style={{
                height: "100%",
                position: "relative"
              }}
            >
              <Grid item xs={4}>
                {isLastStackedTote && (
                  <Box textAlign="left">
                    <Typography variant="h3">{`P${
                      tote?.totePosition || ""
                    }`}</Typography>
                  </Box>
                )}
              </Grid>

              {(showCheckmark || showX) &&
                isExpressOrderState &&
                isLastStackedTote && (
                  <Grid item sx={{ position: "absolute", top: 0, right: -5 }}>
                    <ExpressIcon
                      expressIconSizePx={25}
                      translateVerticallyExpressIconPercentage={10}
                      translateVerticallyExpressTextPercentage={-55}
                      expressTextVariant="caption"
                      expressTextWeight={700}
                    />
                  </Grid>
                )}
              <Grid
                item
                xs={4}
                container
                justifyContent="center"
                style={{
                  marginTop: fusionPortScreen ? "0.75em" : "1.25em"
                }}
              >
                {/* checkmark */}
                {showCheckmark && isLastStackedTote && (
                  <AnimatedCheckMark
                    checkColor={checkColor}
                    size={fusionPortScreen ? 60 : 100}
                  />
                )}
                {showX && isLastStackedTote && (
                  <AnimatedX
                    color={palette.autostoreRed.main}
                    size={fusionPortScreen ? 60 : 100}
                  />
                )}
                {!(showCheckmark || showX) &&
                  isExpressOrderState &&
                  isLastStackedTote && (
                    <ExpressIcon
                      expressIconSizePx={50}
                      translateVerticallyExpressIconPercentage={10}
                      translateVerticallyExpressTextPercentage={-45}
                      expressTextVariant="body2"
                      expressTextWeight={700}
                    />
                  )}
              </Grid>
              <Grid
                item
                xs={4}
                container
                alignItems="flex-end"
                justifyContent="flex-end"
                style={{
                  position: fusionPortScreen ? "absolute" : undefined,
                  right: fusionPortScreen ? 0 : undefined,
                  bottom: fusionPortScreen ? "-14%" : undefined
                }}
              >
                {showPickQuantity && (
                  <Typography variant="h3">
                    {matchingPickQuantity || ""}
                  </Typography>
                )}
              </Grid>
              {externalToteId && isLastStackedTote && (
                <Grid
                  item
                  xs={12}
                  style={{
                    position: "absolute",
                    bottom: fusionPortScreen ? "-10%" : "2%",
                    left: "0%",
                    width: "70%"
                  }}
                >
                  {externalToteId && (
                    <Tooltip
                      title={<Typography>{externalToteId}</Typography>}
                      arrow
                    >
                      <Typography variant="h5" noWrap>
                        {externalToteId}
                      </Typography>
                    </Tooltip>
                  )}
                </Grid>
              )}
            </Grid>
          </Box>
        </ProgressButton>
      </Box>
      {envConstants.ENABLE_PTL_SIMULATION === "true" &&
        ptlSimulationEnabled &&
        isLastStackedTote && <PTL position={tote?.totePosition || 0} />}
      {tote?.picks && isLastStackedTote && (
        <Stack
          direction="row"
          flexWrap="wrap"
          sx={{ maxWidth: 240, margin: "10px auto 0" }}
          id="progressBar"
          data-testid="progress-bar"
          justifyContent="center"
        >
          <ToteProgressBar
            allPicksForTote={tote.picks}
            pickingIsComplete={!!pickingIsComplete}
          />
        </Stack>
      )}
    </Stack>
  );
}

export default AutostoreTote;
