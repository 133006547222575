import { hubs } from "~/lib/signalr";
import {
  AutostoreEvent,
  BinStateDto,
  Point2D,
  PortResponse,
  SystemMode
} from "~/types/api";

import { autostoreGridApi } from "./autostoreGrid";

export const streamGetBinsOutsideGridUpdates: typeof autostoreGridApi.endpoints.getBinsOutsideGrid.Types.QueryDefinition.onCacheEntryAdded =
  async (
    { autostoreGridId },
    { cacheDataLoaded, cacheEntryRemoved, ...rest }
  ) => {
    await cacheDataLoaded;
    const subscription = hubs.gridV2.subscribe({
      next: (event: AutostoreEvent) => {
        if (
          event.case !== "BinModeChange" ||
          event.event.gridId !== autostoreGridId
        )
          return;

        switch (event.event.binMode) {
          case "X":
            rest.updateCachedData((binIds) => {
              if (!binIds.includes(event.event.binId))
                binIds.push(event.event.binId);
            });
            break;

          default:
            rest.updateCachedData((binIds) => {
              const index = binIds.indexOf(event.event.binId);
              if (index !== -1) binIds.splice(index, 1);
            });
            break;
        }
      }
    });
    await cacheEntryRemoved;
    subscription.unsubscribe();
  };

export const streamGetAutostoreGridConnectionModeUpdates: typeof autostoreGridApi.endpoints.getAutostoreGridConnectionMode.Types.QueryDefinition.onCacheEntryAdded =
  async (autostoreGridId, { cacheDataLoaded, cacheEntryRemoved, ...rest }) => {
    await cacheDataLoaded;
    const subscription = hubs.gridV2.subscribe({
      next: (event: AutostoreEvent) => {
        if (
          event.case !== "ConnectionMode" ||
          event.event.gridId !== autostoreGridId
        )
          return;

        rest.updateCachedData(() => ({
          connectionMode: event.event.connectionMode,
          gridId: autostoreGridId
        }));
      }
    });
    await cacheEntryRemoved;
    subscription.unsubscribe();
  };

const humanizeSystemMode = (mode: SystemMode) => {
  switch (mode) {
    case "RUN":
      return "Running";
    case "STP":
      return "Stopped";
    case "DSTP":
      return "Stopping";
    case "ALT":
      return "Alert";
    case "SVC":
      return "Service";
  }
};

export const streamGetAutostoreGridStatusUpdates: typeof autostoreGridApi.endpoints.getAutostoreGridStatus.Types.QueryDefinition.onCacheEntryAdded =
  async (autostoreGridId, { cacheDataLoaded, cacheEntryRemoved, ...rest }) => {
    await cacheDataLoaded;
    const subscription = hubs.gridV2.subscribe({
      next: (event: AutostoreEvent) => {
        if (
          event.case !== "SystemModeChanged" ||
          event.event.gridId !== autostoreGridId
        )
          return;

        rest.updateCachedData(() => ({
          kind: "connected",
          mode: humanizeSystemMode(event.event.systemMode),
          stopCode: null,
          gridId: autostoreGridId
        }));
      }
    });
    await cacheEntryRemoved;
    subscription.unsubscribe();
  };

export type PortResponseWithFuture = PortResponse & {
  futureBin?: number;
  futureTask?: number;
};

export const streamGetPortStatusUpdates: typeof autostoreGridApi.endpoints.getPortStatus.Types.QueryDefinition.onCacheEntryAdded =
  async (
    { autostoreGridId, portId },
    { cacheDataLoaded, cacheEntryRemoved, ...rest }
  ) => {
    await cacheDataLoaded;
    const subscription = hubs.gridV2.subscribe({
      next: (event: AutostoreEvent) => {
        rest.updateCachedData((draftData) => {
          switch (event.case) {
            case "PortMode":
              if (
                event.event.portId === portId &&
                event.event.gridId === autostoreGridId
              ) {
                if (event.event.portMode === "Closed") {
                  draftData.mode = "CLOSED";
                  draftData.isReady = false;
                  draftData.selectedBin = 0;
                  draftData.selectedTask = 0;
                  draftData.futureBin = undefined;
                  draftData.futureTask = undefined;
                } else {
                  draftData.mode = event.event.portMode.toUpperCase();
                }
              }
              break;
            case "BinModeChange":
              if (
                event.event.portId === portId &&
                event.event.gridId === autostoreGridId
              ) {
                switch (event.event.binMode) {
                  case "C":
                    draftData.isReady = false;
                    // early bin requested will set future
                    draftData.selectedBin = draftData.futureBin ?? 0;
                    draftData.selectedTask = draftData.futureTask ?? 0;
                    draftData.futureBin = undefined;
                    draftData.futureTask = undefined;
                    break;
                  case "O":
                    draftData.isReady = true;
                    draftData.selectedBin = event.event.binId;
                    break;
                  case "X":
                    draftData.isReady = false;
                    draftData.selectedBin = 0;
                    draftData.selectedTask = 0;
                }
              }
              break;
            case "BinRequested": {
              if (
                event.event.portId === portId &&
                event.event.gridId === autostoreGridId
              ) {
                if (draftData.selectedTask !== 0) {
                  // if we have a task, then we are early bin requested
                  draftData.futureBin = event.event.binId;
                  draftData.futureTask = event.event.taskId;
                } else {
                  // otherwise, we are late bin requested
                  draftData.selectedBin = event.event.binId;
                  draftData.selectedTask = event.event.taskId;
                }
              }
            }
          }
        });
      }
    });
    await cacheEntryRemoved;
    subscription.unsubscribe();
  };

const setLocation = (
  draftBin: BinStateDto,
  draftBinsByPos: Record<string, BinStateDto[]>,
  coordinate: Point2D,
  binCell?: BinStateDto[]
) => {
  const binIndex = binCell?.findIndex((b) => b.binId === draftBin.binId);

  draftBin.xpos = coordinate.x;
  draftBin.ypos = coordinate.y;

  if (binIndex !== undefined) {
    binCell?.splice(binIndex, 1);
  }

  if (coordinate) {
    const destinationBinCell =
      draftBinsByPos[`${coordinate.x}/${coordinate.y}`];
    if (destinationBinCell) {
      destinationBinCell.push(draftBin);
    } else {
      draftBinsByPos[`${coordinate.x}/${coordinate.y}`] = [draftBin];
    }
  }
};

const setUnknown = (
  draftBin: BinStateDto,
  draftBinsByPos: Record<string, BinStateDto[]>,
  binCell?: BinStateDto[]
) => {
  const binIndex = binCell?.findIndex((b) => b.binId === draftBin.binId);

  delete draftBin.xpos;
  delete draftBin.ypos;

  if (binIndex !== undefined) {
    binCell?.splice(binIndex, 1);
  }

  if (draftBinsByPos["unknown"]) {
    draftBinsByPos["unknown"].push(draftBin);
  } else {
    draftBinsByPos["unknown"] = [draftBin];
  }
};

export const streamGetLogPublisherStateUpdates: typeof autostoreGridApi.endpoints.getLogPublisherState.Types.QueryDefinition.onCacheEntryAdded =
  async (autostoreGridId, { cacheDataLoaded, cacheEntryRemoved, ...rest }) => {
    await cacheDataLoaded;
    const subscription = hubs.gridV2.subscribe({
      next: (event: AutostoreEvent) => {
        if (event.event.gridId !== autostoreGridId) return;

        switch (event.case) {
          case "PortMode":
            rest.updateCachedData((cache) => {
              const ports = Object.values(cache.portsByPos).find((ports) =>
                ports.find((p) => p.portId === event.event.portId)
              );
              if (!ports?.length) return cache;

              // multiportpuppet has a space, so need to cast
              ports.forEach((draftPort) => {
                draftPort.portMode = event.event
                  .portMode as typeof draftPort.portMode;
                if (event.event.portMode === "Closed") {
                  draftPort.isReady = false;
                  draftPort.selectedBin = 0;
                  draftPort.selectedTask = 0;
                }
              });

              return cache;
            });
            break;

          case "BinModeChange":
            rest.updateCachedData((cache) => {
              const ports = Object.values(cache.portsByPos).find((ports) =>
                ports.find((p) => p.portId === event.event.portId)
              );

              ports?.forEach((draftPort) => {
                switch (event.event.binMode) {
                  case "C":
                  case "X":
                    draftPort.isReady = false;
                    draftPort.selectedBin = 0;
                    draftPort.selectedTask = 0;
                    break;
                  case "O":
                    draftPort.isReady = true;
                    draftPort.selectedBin = event.event.binId;
                }
              });

              const binCell = Object.values(cache.binsByPos).find((bins) =>
                bins.find((bin) => bin.binId === event.event.binId)
              );
              const bin = binCell?.find(
                (bin) => bin.binId === event.event.binId
              );

              if (!bin) return;

              switch (event.event.binMode) {
                case "C":
                  bin.binMode = "Closed";
                  break;
                case "F":
                  bin.binMode = "Forecast";
                  break;
                case "G":
                  bin.binMode = "Grid";
                  break;
                case "O":
                  bin.binMode = "Open";
                  break;
                case "P":
                  bin.binMode = "Port";
                  break;
                case "R":
                  bin.binMode = "Prepared";
                  break;
                case "T":
                  bin.binMode = "To Grid";
                  break;
                case "X":
                  bin.binMode = "Outside";
                  break;
              }

              // bin mode x event has coordinates
              if (event.event.binMode === "X" || !event.event.coordinate) {
                setUnknown(bin, cache.binsByPos, binCell);
              } else if (
                bin.xpos !== event.event.coordinate.x &&
                bin.ypos !== event.event.coordinate.y
              ) {
                setLocation(
                  bin,
                  cache.binsByPos,
                  event.event.coordinate,
                  binCell
                );
              }

              return cache;
            });
            break;

          case "BinRequested":
            rest.updateCachedData((cache) => {
              const ports = Object.values(cache.portsByPos).find((ports) =>
                ports.find((p) => p.portId === event.event.portId)
              );

              ports?.forEach((draftPort) => {
                draftPort.selectedBin = event.event.binId;
                draftPort.selectedTask = event.event.taskId;
              });

              return cache;
            });
            break;

          case "BinLocationChange":
            rest.updateCachedData((cache) => {
              const binCell = Object.values(cache.binsByPos).find((bins) =>
                bins.find((bin) => bin.binId === event.event.binId)
              );
              const bin = binCell?.find(
                (bin) => bin.binId === event.event.binId
              );

              if (!bin) return cache;

              if (!event.event.coordinate) {
                setUnknown(bin, cache.binsByPos, binCell);
              } else if (
                bin.xpos !== event.event.coordinate?.x ||
                bin.ypos !== event.event.coordinate?.y
              ) {
                setLocation(
                  bin,
                  cache.binsByPos,
                  event.event.coordinate,
                  binCell
                );
              }

              return cache;
            });
        }
      }
    });

    await cacheEntryRemoved;
    subscription.unsubscribe();
  };
