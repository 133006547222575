import { Components, Theme } from "@mui/material";

export const muiList: Components<Omit<Theme, "components">> = {
  MuiList: {
    styleOverrides: {
      root: {
        // Prevent scrollbars from overlapping the time in DesktopTimePicker
        "&.MuiMultiSectionDigitalClockSection-root": {
          ".MuiMenuItem-root": {
            scrollbarWidth: "thin"
          }
        }
      }
    }
  }
};
