import Grid from "@mui/material/Grid";

import { ScanningIndicator, useScanIndicator } from "@qubit/autoparts";
import { isGuid } from "is-guid";
import { useCallback, useEffect, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";

import { CartTable } from "./CartTable";
import { CartsToolbar } from "./CartsToolbar";
import { scanBarcode, selectDate } from "./carts.slice";

type Props = { viewTitle?: ViewNameTranslation };

export function Carts(props: Props) {
  const { viewTitle } = props;
  const dispatch = useAppDispatch();

  const selectedDate = useAppSelector((state) => state.carts.selectedDate);
  const scannedBarcode = useAppSelector((state) => state.carts.scannedBarcode);
  const isBatchSelected = useAppSelector(
    (state) => !!state.carts.selectedBatch
  );

  const handleDateChange = useCallback(
    (date: Date | null): void => {
      dispatch(selectDate(date ?? new Date()));
    },
    [dispatch]
  );

  const datePicker = useMemo(() => {
    return { selectedDate, onDateChange: handleDateChange };
  }, [selectedDate, handleDateChange]);

  const { setToolbar } = useNavbar({
    datePicker,
    viewTitle
  });

  useEffect(() => {
    if (isBatchSelected) {
      setToolbar(<CartsToolbar />);
    } else {
      setToolbar(undefined);
    }
  }, [isBatchSelected, setToolbar]);

  // reset the date each time the page is mounted
  useEffect(() => {
    dispatch(selectDate(new Date()));
  }, [dispatch]);

  const [scanState, setScanState] = useScanIndicator();

  useKeyDownHandler();
  useBarcodeScanner({
    findScanMatch: (toteId: string) => {
      if (isGuid(toteId)) {
        dispatch(scanBarcode(toteId));
        setScanState("success");
      }
    }
  });

  return (
    <>
      <Grid
        container
        direction="column"
        style={{
          marginTop: 20,
          flexWrap: "nowrap",
          maxWidth: "100vw"
        }}
      >
        <Grid item style={{ flex: "1 1 auto", padding: "10px 0 0 0" }}>
          <Grid
            container
            direction="column"
            style={{ height: "100%", flexWrap: "nowrap" }}
          >
            <CartTable />
          </Grid>
        </Grid>
      </Grid>
      <ScanningIndicator
        scanState={scanState}
        scannedBarcode={scannedBarcode}
        placeholderText="Scan Tote"
      />
    </>
  );
}
