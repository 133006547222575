import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Button, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { mergeSx } from "merge-sx";

import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConnectedProps, connect } from "react-redux";

import RepickProductsModal from "~/features/autostorePicking/RepickProductsModal";
import { useClientConfig } from "~/hooks/useClientConfig";
import { useDisclosure } from "~/hooks/useDisclosure";
import { StoreState } from "~/redux/reducers";
import { useGetTotesForBatchQuery } from "~/redux/warehouse/autostorePicking.hooks";

import {
  menuItemStyle,
  menuStyle,
  typographyStyle
} from "./ToteOptionsMenuStyle";

const mapStateToProps = (state: StoreState) => ({
  pickingState: state.autostore.pickingState,
  batchTotes: state.batch.batchTotes
});

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;
type ToteOptionsMenuProps = PropsFromRedux & {
  showAddToteOption: boolean;
  addToteDisabled?: boolean;
  addToteCallback?: () => Promise<void> | void | null;
  showRepickProductsOption: boolean;
  isReprintLabelOptionEnabled: boolean;
  reprintLabelCallback?: () => Promise<void> | void | null;
  toteId: string;
  totePosition: number;
  isActiveTote: boolean;
};

export function ToteOptionsMenu(props: ToteOptionsMenuProps) {
  const {
    showAddToteOption,
    addToteDisabled,
    addToteCallback,
    showRepickProductsOption,
    isReprintLabelOptionEnabled,
    reprintLabelCallback,
    pickingState,
    totePosition,
    toteId
  } = props;

  const {
    open: isRepickProductsModalOpen,
    onOpen: onRepickProductsModalOpen,
    onClose: onRepickProductsModalClose
  } = useDisclosure();

  const { t } = useTranslation();
  const { palette } = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { tote } = useGetTotesForBatchQuery(
    pickingState?.batchId ? { batchId: pickingState.batchId } : skipToken,
    {
      selectFromResult: ({ data }) => ({
        tote: data
          ?.flatMap((pos) => pos.totes)
          ?.find((tote) => tote.toteId === toteId)
      })
    }
  );

  const { ap_applyingLabelProcessEnabled } = useClientConfig();
  const showReprintLabelOption = ap_applyingLabelProcessEnabled;

  const isAddToteOptionDisabled = useMemo(() => {
    if (!tote || !pickingState || addToteDisabled) return true;

    const isSelectedPickInTote = tote.picks.find(
      (pick) =>
        pick.pickId === pickingState.selectedPickId ||
        pick.pickId === pickingState.currentPicks[0]?.pickId
    );

    if (!isSelectedPickInTote) return true;

    if (tote.contents.length === 0) return isSelectedPickInTote.quantity < 2;

    const totalToteContent = tote.contents.reduce(
      (acc, toteContent) => toteContent.quantity.value + acc,
      0
    );
    return totalToteContent < 1 && isSelectedPickInTote.quantity < 2;
  }, [tote, pickingState, addToteDisabled]);

  const isShowReprintLabelOptionEnabled =
    showReprintLabelOption && isReprintLabelOptionEnabled;

  const isEnabledOptionFound =
    (showAddToteOption && !isAddToteOptionDisabled) ||
    showRepickProductsOption ||
    isShowReprintLabelOptionEnabled;

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onClickAddTote = () => {
    if (addToteCallback && showAddToteOption) {
      closeMenu();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      addToteCallback();
    }
  };

  const onClickRepickProducts = () => {
    if (showRepickProductsOption) {
      closeMenu();
      onRepickProductsModalOpen();
    }
  };

  const onClickReprintLabel = () => {
    if (reprintLabelCallback && showReprintLabelOption) {
      closeMenu();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      reprintLabelCallback();
    }
  };

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <Button
        id="three-dots-button"
        aria-controls={isOpen ? "three-dots-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? "true" : undefined}
        aria-label="tote menu"
        variant="text"
        onClick={openMenu}
        disabled={!isEnabledOptionFound}
        sx={mergeSx(menuStyle, {
          color: isEnabledOptionFound
            ? palette.primary.main
            : palette.secondary.main
        })}
      >
        {isOpen ? (
          <RemoveIcon fontSize="large" />
        ) : (
          <AddIcon fontSize="large" />
        )}
      </Button>

      <Menu
        id="three-dots-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenu}
        MenuListProps={{
          "aria-labelledby": "three-dots-button"
        }}
        disableScrollLock
      >
        {showAddToteOption && (
          <MenuItem
            onClick={onClickAddTote}
            disabled={isAddToteOptionDisabled}
            sx={menuItemStyle}
          >
            <Typography sx={typographyStyle}>{t("add tote")}</Typography>
          </MenuItem>
        )}
        {showRepickProductsOption && (
          <MenuItem onClick={onClickRepickProducts} sx={menuItemStyle}>
            <Typography sx={typographyStyle}>{t("repick products")}</Typography>
          </MenuItem>
        )}
        {showReprintLabelOption && (
          <MenuItem
            onClick={onClickReprintLabel}
            disabled={!isShowReprintLabelOptionEnabled}
            sx={menuItemStyle}
          >
            <Typography sx={typographyStyle}>{t("reprint label")}</Typography>
          </MenuItem>
        )}
      </Menu>
      {isRepickProductsModalOpen && (
        <RepickProductsModal
          open={isRepickProductsModalOpen}
          totePosition={totePosition}
          onClose={onRepickProductsModalClose}
        />
      )}
    </>
  );
}

export default connector(ToteOptionsMenu);
