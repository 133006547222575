import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ClientConfig, defaultClientConfig } from "~/config/clientConfig";
import { parseClientConfigJsonSafely } from "~/lib/helpers";
import { clientConfigApi } from "~/redux/warehouse/clientConfig";
export interface AppState {
  ready: {
    clientId: boolean;
    flags: {
      clientId: boolean;
      fcId: boolean;
    };
    authHeader: boolean;
    clientConfig: boolean;
  };
  clientConfig: ClientConfig;
}

export const initialState: AppState = {
  ready: {
    clientId: false, // to do
    flags: {
      clientId: false,
      fcId: false
    },
    authHeader: false,
    clientConfig: false
  },
  clientConfig: defaultClientConfig
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setClientIdReady(state, action: PayloadAction<boolean>) {
      state.ready.clientId = action.payload;
    },
    setFlagsReadyFcId(state, action: PayloadAction<boolean>) {
      state.ready.flags.fcId = action.payload;
    },
    setFlagsReadyClientId(state, action: PayloadAction<boolean>) {
      state.ready.flags.clientId = action.payload;
    },
    setClientConfigReady(state, action: PayloadAction<boolean>) {
      state.ready.clientConfig = action.payload;
    },
    setInterceptorReady(state, action: PayloadAction<boolean>) {
      state.ready.authHeader = action.payload;
    },
    resetAppReady(state) {
      state.ready = {
        clientId: false,
        flags: {
          clientId: false,
          fcId: false
        },
        authHeader: false,
        clientConfig: false
      };
    },
    setClientConfig(state, action: PayloadAction<ClientConfig>) {
      state.clientConfig = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      clientConfigApi.endpoints.getClientConfig.matchFulfilled,
      (state, action) => {
        const parsedConfig =
          (parseClientConfigJsonSafely(action.payload) as ClientConfig) ||
          defaultClientConfig;
        state.clientConfig = parsedConfig;
        state.ready.clientConfig = true;
      }
    );
  }
});

export const {
  setFlagsReadyFcId,
  setFlagsReadyClientId,
  setInterceptorReady,
  setClientIdReady,
  setClientConfigReady,
  resetAppReady,
  setClientConfig
} = appSlice.actions;

export default appSlice.reducer;
