import { useMemo } from "react";

import { BatchDto } from "~/types/api";

import { useClientConfig } from "./useClientConfig";

/**
 * Returns a boolean indicating whether a cart can be unassigned.
 * - If no batch is provided or no cart number is assigned, return false.
 * - Otherwise, if the client configuration enables cart assignment and
 *   the batch status is either "Scheduled" or "Cart Ready", return true.
 */
export function useCanUnassignCart(
  batch: BatchDto | undefined,
  cartNumber: string | undefined
): boolean {
  const clientConfig = useClientConfig();

  return useMemo(() => {
    if (!batch || !cartNumber) return false;
    return (
      clientConfig.cartAssignmentEnabled &&
      ["Scheduled", "Cart Ready"].includes(batch.status || "")
    );
  }, [clientConfig, batch, cartNumber]);
}
