import { StyledEngineProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { autostoreTheme, ErrorBoundary } from "@qubit/autoparts";
import dayjs from "dayjs";
import i18n from "i18next";
import { useLDClient } from "launchdarkly-react-client-sdk";
import moment from "moment";
import { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouterProvider } from "react-router-dom";

import { applyRedirectOn401Response } from "~/api/shared";
import { getUserClientId } from "~/api/usersTypes/auth0Profile";
import { warehouseService, setupAuthInterceptor } from "~/api/warehouse";

import envConstants from "~/config/envConstants";
import EventListeningComponent from "~/features/andon/EventListeningComponent";
import { useBeamer } from "~/hooks/useBeamer";
import { useMessagePopper } from "~/hooks/useMessagePopper";
import { useSetLdClientId } from "~/hooks/useSetLdClientId";
import { useSetLdFcId } from "~/hooks/useSetLdFcId";
import useSetSentryTags from "~/hooks/useSetSentryTags";
import { useUserMessages } from "~/hooks/useUserMessages";
import { useWebLock } from "~/hooks/useWebLock";
import { usePTLSubscription, useInitializeSignalRHubs } from "~/lib/signalr";
import { setPTLSimulationStateChange } from "~/redux/actions/ptl";
import { refreshClientConfig } from "~/redux/actions/site";
import { StoreState } from "~/redux/reducers";
import { selectIsUserLoggedIn } from "~/redux/selectors/authSelectors";
import { selectLocale } from "~/redux/selectors/siteSelectors";
import { useGetClientConfigQuery } from "~/redux/warehouse/clientConfig.hooks";

import { router } from "./Root";
import { setInterceptorReady } from "./app.slice";

import "~/lib/dayjs";

import { useAppSelector, useAppDispatch } from "./store";

const mapStateToProps = (state: StoreState) => ({
  clientId: state.login.profile ? getUserClientId(state.login.profile) : null,
  usersPort: state.workstations.siteWorkstation?.ports?.[0]?.portId ?? null,
  userId: state.login.profile?.userId || null,
  usersFulfillmentCenter: state.store.usersFulfillmentCenter,
  siteLanguageCode: state.site.languageCode,
  ptlSimulationEnabled: state.ptl.ptlSimulationEnabled
});

const connector = connect(mapStateToProps, {
  refreshClientConfig,
  setPTLSimulationStateChange
});

type PropsFromRedux = ConnectedProps<typeof connector>;
type AppProps = PropsFromRedux;

function App(props: AppProps) {
  const {
    clientId,
    usersPort,
    usersFulfillmentCenter,
    userId,
    siteLanguageCode,
    refreshClientConfig
  } = props;

  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);
  const interceptorReady = useAppSelector(
    (state) => state.app.ready.authHeader
  );

  const locale = useAppSelector(selectLocale);
  const authMethod = useAppSelector((state) => state.login.authMethod);
  const stateAccessToken = useAppSelector((state) => state.login.accessToken);

  const dispatch = useAppDispatch();

  const localeText = {
    cancelButtonLabel: "Cancel",
    okButtonLabel: "Confirm"
  };

  const sentryTags = {
    client: clientId,
    port: usersPort,
    fulfillmentCenter: usersFulfillmentCenter?.fulfillmentCenterId || null,
    userId: userId,
    clientRelease: envConstants.VERSION_TAG || envConstants.VERSION
  };

  // fetch client config when clientId and auth header are ready
  useGetClientConfigQuery(undefined, {
    skip: !clientId || !interceptorReady
  });

  useUserMessages();
  useWebLock();

  // launch darkly setup
  const ldClient = useLDClient();
  // set the clientId if fc is not defined
  useSetLdClientId({
    clientId,
    ldClient,
    fcId: usersFulfillmentCenter?.fulfillmentCenterId
  });
  // set both the client id and fc id if both are defined
  useSetLdFcId({
    clientId,
    ldClient,
    fcId: usersFulfillmentCenter?.fulfillmentCenterId
  });

  // auth inteceptor setup
  useEffect(() => {
    const initializeInterceptor = async () => {
      if (!authMethod || !isUserLoggedIn) return;

      const success = await setupAuthInterceptor({
        authMethod,
        stateAccessToken
      });

      if (success) {
        applyRedirectOn401Response(warehouseService, dispatch);
        dispatch(setInterceptorReady(true));
      }
    };

    void initializeInterceptor();
  }, [authMethod, isUserLoggedIn, stateAccessToken, dispatch]);

  useBeamer();

  // old redux notifications
  useMessagePopper();

  // do not run if no fulfillment center is selected
  useInitializeSignalRHubs({
    usersFulfillmentCenterId: usersFulfillmentCenter?.fulfillmentCenterId
  });

  usePTLSubscription(
    props.setPTLSimulationStateChange,
    envConstants.ENABLE_PTL_SIMULATION === "true" && props.ptlSimulationEnabled
  );

  useSetSentryTags(sentryTags);

  useEffect(() => {
    if (clientId) refreshClientConfig(clientId);
  }, [clientId, refreshClientConfig]);

  useEffect(() => {
    void i18n.changeLanguage(siteLanguageCode);
    moment.locale(siteLanguageCode);
    dayjs.locale(locale);
  }, [siteLanguageCode, locale]);

  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={locale}
        localeText={localeText}
      >
        <ErrorBoundary>
          <div
            id="App"
            style={{
              background: autostoreTheme.palette.background.default,
              minHeight: "100vh"
            }}
          >
            <RouterProvider
              router={router}
              future={{
                // uses useTransition instead of useState for updates. avoid UI blocking.
                v7_startTransition: true
              }}
            />
            <EventListeningComponent />
          </div>
        </ErrorBoundary>
      </LocalizationProvider>
    </StyledEngineProvider>
  );
}

export default connector(App);
