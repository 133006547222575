import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel
} from "@mui/material";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import { ProgressButton } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import envConstants from "~/config/envConstants";

import { useClientConfig } from "~/hooks/useClientConfig";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { togglePTLSimulationEnabled } from "~/redux/actions/ptl";
import { getServerVersion } from "~/redux/actions/version";
import { toggleConveyanceSimEnabled } from "~/redux/reducers/conveyance";
import {
  selectUserIsAdmin,
  selectUsersProfileName
} from "~/redux/selectors/authSelectors";
import { selectLanguageCode } from "~/redux/selectors/siteSelectors";

import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { useGetAutostoreGridQuery } from "~/redux/warehouse/autostoreGrid.hooks";

import { FulfillmentCenterSelect } from "./FulfillmentCenterSelect";
import { GridStateSynchronizer } from "./GridStateSynchronizer";
import { PrinterSettings } from "./PrinterSettings";
import { UserSettings } from "./UserSettings";
import { WorkstationPanel } from "./WorkstationPanel";
import {
  selectEnableDevInformation,
  toggleDevInfomation
} from "./devSettings.slice";

export const SettingsTabPanel = () => {
  const selectedFulfillmentCenter = useAppSelector(
    (state) => state.store.usersFulfillmentCenter
  );
  const ptlSimulationEnabled = useAppSelector(
    (state) => state.ptl.ptlSimulationEnabled
  );
  const conveyanceSimEnabled = useAppSelector(
    (state) => state.conveyance.conveyanceSimEnabled
  );
  const serverVersion = useAppSelector((state) => state.version.serverVersion);
  const usersName = useAppSelector(selectUsersProfileName);
  const loginPath = useAppSelector((state) => state.site.loginPath);
  const customLogoutRedirectPath = useAppSelector(
    (state) => state.site.customLoginPath
  );

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isAdmin = useAppSelector(selectUserIsAdmin);
  const devInformationEnabled = useAppSelector(selectEnableDevInformation);
  const languageCode = useAppSelector(selectLanguageCode);
  const workstation = useAppSelector(selectThisWorkstation);

  const {
    error: selectedAutostoreGridError,
    refetch: refetchSelectedAutostoreGrid
  } = useGetAutostoreGridQuery(workstation?.autostoreGridId ?? skipToken);

  // show and hide beamer selector upon mount and dismount
  useEffect(() => {
    const intervalId = setInterval(() => {
      const beamerSelector = document.getElementById("beamerSelector");
      if (beamerSelector) {
        clearInterval(intervalId);
        if (isAdmin) {
          beamerSelector.style.display = "block";
        }
      }
    }, 100);

    return () => {
      clearInterval(intervalId);
      const beamerSelector = document.getElementById("beamerSelector");

      if (beamerSelector) {
        beamerSelector.style.display = "none";
      }
    };
  }, [isAdmin]);

  const { dev_orderCreation, dev_ptlSimulation, dev_conveyanceSimulation } =
    useClientConfig();

  useEffect(() => {
    if (usersName?.includes("autostoresystem.com")) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      dispatch(getServerVersion());
    }
  }, [usersName, dispatch]);

  const isWorkstationConfigurer = useAppSelector(
    (state) => state.login.profile?.roles || []
  ).some((role) => role === "qubit-workstation-configurer");
  const areDevCheatsEnabled =
    envConstants.DEV_CHEATS_UAT === "true" ||
    envConstants.DEV_CHEATS_STAGING === "true";

  const showDevSection =
    dev_ptlSimulation ||
    dev_conveyanceSimulation ||
    dev_orderCreation ||
    areDevCheatsEnabled;

  return (
    <Stack p={2} spacing={4}>
      {isWorkstationConfigurer && (
        <Stack spacing={2}>
          <Typography variant="h6">{t("fulfillment center")}</Typography>

          <FulfillmentCenterSelect />
          <FormControl>
            <FormLabel>{t("fulfillment center")} ID</FormLabel>
            <FormHelperText>
              {t("selected fulfillment center id description")}
            </FormHelperText>
            <Typography>
              {selectedFulfillmentCenter?.fulfillmentCenterId}
            </Typography>
          </FormControl>
          <GridStateSynchronizer />
          {!!selectedAutostoreGridError && (
            <Alert
              sx={{ gridColumn: "1/-1" }}
              variant="outlined"
              severity="error"
              action={
                <Button onClick={refetchSelectedAutostoreGrid}>Retry</Button>
              }
            >
              {getMessageFromRtkError(selectedAutostoreGridError)}
            </Alert>
          )}
        </Stack>
      )}
      {isWorkstationConfigurer && (
        <>
          <Divider />
          <WorkstationPanel />
          <Divider />
        </>
      )}

      <Stack spacing={2}>
        <Typography variant="h6">{t("nav.viewname.user settings")}</Typography>
        <UserSettings />
      </Stack>

      {isWorkstationConfigurer &&
        selectedFulfillmentCenter?.printerConfiguration === "BrowserPrint" && (
          <>
            <Divider />
            <Stack>
              <Typography variant="h6">{t("nav.viewname.printer")}</Typography>
              <PrinterSettings maxWidth="sm" />
            </Stack>
          </>
        )}

      {showDevSection && (
        <>
          <Divider />
          <Stack spacing={2}>
            <Typography variant="h6">{t("nav.viewname.dev")}</Typography>
            <ProgressButton
              variant="subtle"
              onClick={() => navigate("/order-creation")}
            >
              {t("order creation")}
            </ProgressButton>

            <ProgressButton
              variant="subtle"
              onClick={() => navigate("/workstation-bin-maintenance")}
            >
              Workstation Maintenance
            </ProgressButton>

            {areDevCheatsEnabled && isAdmin && (
              <FormControl>
                <FormLabel htmlFor="dev-info-enabled">
                  {t("dev information enabled")}
                </FormLabel>
                <Switch
                  id="dev-info-enabled"
                  checked={devInformationEnabled}
                  onChange={(e) =>
                    dispatch(toggleDevInfomation(e.target.checked || false))
                  }
                />
              </FormControl>
            )}
            {(envConstants.ENABLE_PTL_SIMULATION === "true" ||
              dev_ptlSimulation) && (
              <FormControl>
                <FormLabel htmlFor="ptl-simulation-enabled">
                  {t("ptl simulation enabled")}
                </FormLabel>
                <Switch
                  id="ptl-simulation-enabled"
                  checked={ptlSimulationEnabled}
                  onChange={() => dispatch(togglePTLSimulationEnabled())}
                />
              </FormControl>
            )}
            {(envConstants.ENABLE_CONVEYANCE_SIMULATION === "true" ||
              dev_conveyanceSimulation) && (
              <FormControl>
                <FormLabel htmlFor="conveyance-simulation-enabled">
                  {t("conveyance simulation enabled")}
                </FormLabel>
                <Switch
                  id="conveyance-simulation-enabled"
                  checked={conveyanceSimEnabled}
                  onChange={() => dispatch(toggleConveyanceSimEnabled())}
                />
              </FormControl>
            )}
            {/* Enable i18next cimode to show only translation keys */}
            <FormControl>
              <FormLabel htmlFor="show-translation-keys">
                {t("show translation keys")}
              </FormLabel>
              <Switch
                id="show-translation-keys"
                checked={i18n.language === "cimode"}
                onChange={() => {
                  const newLanguage =
                    i18n.language !== "cimode" ? "cimode" : languageCode;
                  void i18n.changeLanguage(newLanguage);
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="login-path">{t("login path")}</FormLabel>
              <Box p={1}>
                <Typography id="login-path">{loginPath}</Typography>
              </Box>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="custom-logout-redirect-path">
                {t("custom logout redirect path")}
              </FormLabel>
              <Box p={1}>
                <Typography id="custom-logout-redirect-path">
                  {customLogoutRedirectPath || ""}
                </Typography>
              </Box>
            </FormControl>
          </Stack>
        </>
      )}

      {usersName?.includes("autostoresystem.com") && (
        <>
          <Divider />
          <Stack spacing={2}>
            <Typography variant="h6">{t("nav.viewname.version")}</Typography>
            <Box
              display="grid"
              gridTemplateColumns="auto 1fr"
              gap={1}
              columnGap={2}
              alignItems="center"
            >
              <Typography variant="subtitle1" color="textSecondary">
                Client Version
              </Typography>
              <Typography>
                {envConstants.VERSION_TAG || envConstants.VERSION}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Server Version
              </Typography>
              <Typography>{serverVersion.tag || serverVersion.hash}</Typography>
            </Box>
          </Stack>
        </>
      )}
    </Stack>
  );
};
