import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { WorkstationSummaryDto } from "~/types/api";

export const unifyErrorTypes = ["Port Errors", "Robot Errors"] as const;
export type UnifyErrorType = (typeof unifyErrorTypes)[number];

export type WorkstationStatusType = WorkstationSummaryDto["status"];
export const workstationStatusTypes: WorkstationStatusType[] = [
  "Active",
  "Inactive",
  "Faulted"
] as const;

type SetSpecificErrorViewType = {
  workstationId: string;
  autostoreGridId: string;
  errorType: UnifyErrorType;
};

type ServiceSupportState = {
  selectedGridId: Guid;
  selectedAdminSummariesGridId: Guid;
  selectedWorkstationId: Guid;
  selectedErrorType: UnifyErrorType;
  selectedWorkstationStatus: WorkstationStatusType | "";
  isSpecificErrorViewReady: boolean;
  adminSummariesData: {
    selectedWorkstationId: string;
  };
};

const initialState: ServiceSupportState = {
  selectedGridId: "",
  selectedAdminSummariesGridId: "",
  selectedWorkstationId: "",
  selectedErrorType: "Port Errors",
  selectedWorkstationStatus: "",
  isSpecificErrorViewReady: false,
  adminSummariesData: {
    selectedWorkstationId: ""
  }
};

export const serviceSupportSlice = createSlice({
  name: "serviceSupport",
  initialState,
  reducers: {
    setSelectedGridId(state, { payload }: PayloadAction<Guid>) {
      state.selectedGridId = payload;
    },
    setSelectedAdminSummariesGridId(state, { payload }: PayloadAction<Guid>) {
      state.selectedAdminSummariesGridId = payload;
    },
    setSelectedWorkstationId(state, { payload }: PayloadAction<Guid>) {
      state.selectedWorkstationId = payload;
    },
    setSelectedErrorType(state, { payload }: PayloadAction<UnifyErrorType>) {
      state.selectedErrorType = payload;
    },
    setSelectedWorkstationStatus(
      state: ServiceSupportState,
      action: PayloadAction<WorkstationStatusType | "">
    ) {
      state.selectedWorkstationStatus = action.payload;
    },
    setErrorViewForWorkstation(
      state,
      { payload }: PayloadAction<SetSpecificErrorViewType>
    ) {
      state.selectedErrorType = payload.errorType;
      state.selectedWorkstationId = payload.workstationId;
      state.selectedGridId = payload.autostoreGridId;
      state.isSpecificErrorViewReady = true;
    },
    setSpecificErrorViewStatus(state, { payload }: PayloadAction<boolean>) {
      state.isSpecificErrorViewReady = payload;
    },
    setSelectedAdminSummariesWorkstationId(
      state,
      { payload }: PayloadAction<string>
    ) {
      state.adminSummariesData.selectedWorkstationId = payload;
    }
  },
  selectors: {
    selectAdminSummariesGrid: (state: ServiceSupportState) =>
      state.selectedAdminSummariesGridId,
    selectWorkstationStatus: (state: ServiceSupportState) =>
      state.selectedWorkstationStatus,
    selectIsSpecificErrorViewReady: (state: ServiceSupportState) =>
      state.isSpecificErrorViewReady,
    getSelectedGridId: (state: ServiceSupportState) => state.selectedGridId,
    getSelectedWorkstationId: (state: ServiceSupportState) =>
      state.selectedWorkstationId,
    getSelectedErrorType: (state: ServiceSupportState) =>
      state.selectedErrorType,
    getSelectedAdminSummariesWorkstationId: (state: ServiceSupportState) =>
      state.adminSummariesData.selectedWorkstationId
  },
  extraReducers: (builder) => {
    builder.addCase(
      "store/UPDATE_USERS_FULFILLMENT_CENTER_SUCCESS",
      (state) => {
        state.selectedAdminSummariesGridId = "";
        state.selectedGridId = "";
      }
    );
  }
});
export const {
  setSelectedGridId,
  setSelectedWorkstationId,
  setSelectedErrorType,
  setSelectedWorkstationStatus,
  setSelectedAdminSummariesGridId,
  setErrorViewForWorkstation,
  setSpecificErrorViewStatus,
  setSelectedAdminSummariesWorkstationId
} = serviceSupportSlice.actions;

export const {
  selectAdminSummariesGrid,
  selectWorkstationStatus,
  selectIsSpecificErrorViewReady,
  getSelectedWorkstationId,
  getSelectedErrorType,
  getSelectedGridId,
  getSelectedAdminSummariesWorkstationId
} = serviceSupportSlice.selectors;
