import { useToast } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { debounce } from "~/hooks/useDebounce";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";

import { setCurrentEmptyBin } from "~/redux/actions";
import { StoreState } from "~/redux/reducers";
import {
  selectThisWorkstation,
  selectWorkstationId
} from "~/redux/selectors/workstationsSelectors";
import {
  useGetSuggestedCompartmentsAndMaxFillCountsQuery,
  useSelectBinCompartmentMutation
} from "~/redux/warehouse/cubing.hooks";
import {
  GetSuggestedCompartmentAndMaxFillResponse,
  NextEmptyBinResponse,
  PutAwayTaskSummaryDto
} from "~/types/api";

import {
  selectHasUserChangedBin,
  setShouldShowMaxQtyWarningBanner,
  selectSelectedCompartment,
  selectChangedQuantity,
  setOptimalBin,
  clearOptimalBin
} from "./autostorePutaway.slice";

/** Eliminate bins that don't have all these properties defined or already have
 * the product in them
 */
function filterIsValidBin(
  bin: GetSuggestedCompartmentAndMaxFillResponse,
  variantId?: Guid,
  nextEmptyBinByPort?: NextEmptyBinResponse | null
): bin is GetSuggestedCompartmentAndMaxFillResponse & {
  maxFillQuantity: number;
  binNumber: number;
  numberOfCompartments: number;
} {
  return (
    !!bin.maxFillQuantity &&
    !!bin.binNumber &&
    !!bin.numberOfCompartments &&
    !!nextEmptyBinByPort &&
    bin.inventoryInBin.every((inventory) => inventory.variantId !== variantId)
  );
}

/** Calculates the best bin based on the putaway quantity and current
 * cubingData by looking for the smallest `maxFillQuantity` that is large enough.
 *
 * Example: if the bins' `maxFillQuantity` are 10, 20, 30 and the putaway
 * quantity is 16 then the best bin would be the 20 quantity bin.
 */
function findOptimalBin({
  cubingData,
  portStateByPort,
  changedQuantity,
  nextEmptyBinByPort,
  variantId
}: {
  cubingData: GetSuggestedCompartmentAndMaxFillResponse[];
  portStateByPort: StoreState["autostore"]["portStateByPort"];
  changedQuantity?: number;
  nextEmptyBinByPort?: Record<number, NextEmptyBinResponse | null>;
  variantId?: string;
}) {
  if (!cubingData || !changedQuantity) {
    return;
  }

  let optimalBin: GetSuggestedCompartmentAndMaxFillResponse | undefined;
  let largestBin: GetSuggestedCompartmentAndMaxFillResponse | undefined;
  const potentialBins = cubingData.filter((bin) =>
    filterIsValidBin(bin, variantId, nextEmptyBinByPort?.[bin.portId])
  );

  // If there are no valid bins that means the selected product is already
  // present in all other bins. Return undefined to show the Get New Bin button
  if (!potentialBins) {
    return undefined;
  }

  // Find the smallest `maxFillQuantity` that will work with the current quantity
  for (const bin of potentialBins) {
    if (
      bin.maxFillQuantity >= changedQuantity &&
      (!optimalBin?.maxFillQuantity ||
        bin.maxFillQuantity < optimalBin.maxFillQuantity)
    ) {
      optimalBin = bin;
    }
  }

  // If the chosen bin is not 'Ready' look for another applicable bin
  if (
    optimalBin?.binNumber &&
    portStateByPort[optimalBin.portId] &&
    !portStateByPort[optimalBin.portId].getPortResponse.isReady
  ) {
    for (const bin of potentialBins) {
      if (
        bin.binNumber !== optimalBin.binNumber &&
        bin.maxFillQuantity >= changedQuantity &&
        portStateByPort[bin.portId]?.getPortResponse.isReady
      ) {
        optimalBin = bin;
      }
    }
  }

  // If we haven't found a bin it means the the putaway quantity exceeds all
  // suggested values. Choose the largest bin and show a warning banner.
  if (!optimalBin) {
    for (const bin of potentialBins) {
      if (
        !largestBin?.numberOfCompartments ||
        bin.numberOfCompartments < largestBin.numberOfCompartments
      ) {
        largestBin = bin;
      }
    }
  }

  return optimalBin || largestBin;
}

/** Cubing handles recommending the optimal bin and compartment to the user.
 *
 * Basic flow:
 * - User clicks on a row and we fetch the cubing data for that product
 * - This data returned includes the suggested compartment per bin and the
 *  maximum recommended quantity per bin
 * - This hook then calculates the best bin and comparment for the product
 *
 * Full list of logic and edge cases:
 * https://autostore.atlassian.net/wiki/spaces/LT/pages/5679742982/Induction+page+cubing+requirements
 */
export const useCubing = ({
  selectedRow
}: {
  selectedRow: PutAwayTaskSummaryDto | undefined;
}): {
  cubingData: GetSuggestedCompartmentAndMaxFillResponse[] | undefined;
  selectedBinCubingData: GetSuggestedCompartmentAndMaxFillResponse | undefined;
} => {
  const dispatch = useAppDispatch();
  const { errorToast } = useToast();
  const variantId = selectedRow?.product.variantId;

  const changedQuantity = useAppSelector(selectChangedQuantity);
  const hasUserChangedBin = useAppSelector(selectHasUserChangedBin);
  const selectedCompartment = useAppSelector(selectSelectedCompartment);
  const workstationId = useAppSelector(selectWorkstationId);
  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const currentEmptyBin = useAppSelector(
    (state) => state.autostore.currentEmptyBin
  );
  const nextEmptyBinByPort = useAppSelector(
    (state) => state.autostore.nextEmptyBinByPort
  );
  const portStateByPort = useAppSelector(
    (state) => state.autostore.portStateByPort
  );

  const {
    data: cubingData,
    selectedBinCubingData,
    error: cubingError
  } = useGetSuggestedCompartmentsAndMaxFillCountsQuery(
    !!variantId && !!workstationId ? { variantId, workstationId } : skipToken,
    {
      selectFromResult: (result) => ({
        selectedBinCubingData: result.data?.find(
          (bin) => bin.binNumber === currentEmptyBin?.openBinResponse.binId
        ),
        ...result
      })
    }
  );

  const [selectBinCompartment] = useSelectBinCompartmentMutation();
  // Debounce to avoid multiple calls
  const debouncedSelectBinCompartment = useMemo(
    () => debounce(selectBinCompartment, 200),
    [selectBinCompartment]
  );

  useEffect(() => {
    if (cubingError) {
      errorToast(getMessageFromRtkError(cubingError));
    }
  }, [cubingError, errorToast]);

  // Handle setting the selected bin and compartment
  useEffect(() => {
    if (hasUserChangedBin) {
      return;
    }
    if (!changedQuantity || !variantId || !nextEmptyBinByPort || !cubingData) {
      dispatch(clearOptimalBin());
      dispatch(setCurrentEmptyBin(null));
      return;
    }

    const optimalBin = findOptimalBin({
      cubingData,
      portStateByPort,
      changedQuantity,
      nextEmptyBinByPort,
      variantId
    });

    if (!optimalBin) {
      dispatch(setOptimalBin(null));
      dispatch(setCurrentEmptyBin(null));
      return;
    }

    dispatch(setOptimalBin(optimalBin));
    dispatch(setCurrentEmptyBin(nextEmptyBinByPort[optimalBin.portId]));
    // Ignoring `portStateByPort` in this array because if we include it the
    // selected bin can potentially change after one is already selected.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    nextEmptyBinByPort,
    variantId,
    cubingData,
    changedQuantity,
    hasUserChangedBin,
    dispatch
  ]);

  // After we pick a bin tell the backend so it can tell the Pointer Light
  useEffect(() => {
    void (async () => {
      if (
        selectedCompartment === undefined ||
        !currentEmptyBin?.autostoreBinConfiguration ||
        !workstationId ||
        !siteWorkstation ||
        !changedQuantity ||
        !selectedRow?.quantity.units
      ) {
        return;
      }
      try {
        await debouncedSelectBinCompartment({
          binConfigurationType:
            currentEmptyBin.autostoreBinConfiguration.configurationType,
          binId: currentEmptyBin.openBinResponse.binId,
          gridId: siteWorkstation.autostoreGridId,
          portId: currentEmptyBin.openBinResponse.portId,
          quantity: {
            units: selectedRow.quantity.units,
            value: changedQuantity
          },
          compartment: selectedCompartment + 1,
          workstationId,
          isOverride: hasUserChangedBin
        });
      } catch (err) {
        errorToast(getMessageFromRtkError(err));
      }
    })();
  }, [
    currentEmptyBin,
    hasUserChangedBin,
    changedQuantity,
    selectedCompartment,
    selectedRow,
    workstationId,
    siteWorkstation,
    debouncedSelectBinCompartment,
    errorToast
  ]);

  // If user has changed bin, check if we need to show the max quantity warning banner
  useEffect(() => {
    if (
      hasUserChangedBin &&
      changedQuantity &&
      selectedBinCubingData?.maxFillQuantity
    ) {
      if (changedQuantity > selectedBinCubingData.maxFillQuantity) {
        dispatch(setShouldShowMaxQtyWarningBanner(true));
      } else {
        dispatch(setShouldShowMaxQtyWarningBanner(false));
      }
    }
  }, [hasUserChangedBin, changedQuantity, selectedBinCubingData, dispatch]);

  return { cubingData, selectedBinCubingData };
};
