import { useAppSelector } from "~/app/store";

import {
  selectUsersClientId,
  selectUserIsAdmin
} from "~/redux/selectors/authSelectors";

import { useClientConfig } from "./useClientConfig";

/**
 * useIsDeleteWorkstationShown returns a boolean indicating whether
 * the delete workstation button should be shown.
 */
export function useIsDeleteWorkstationShown(): boolean {
  const clientId = useAppSelector(selectUsersClientId);
  const isAdmin = useAppSelector(selectUserIsAdmin);
  const clientConfig = useClientConfig();
  // If the client is a particular one,
  // only allow deletion if the user is an admin.  Otherwise, allow deletion.
  const clientCondition =
    clientId === "9e951c04-edad-42a7-af77-accaf41701a1" ? !!isAdmin : true;

  return Boolean(clientConfig.ws_deleteWorkstationEnabled) && clientCondition;
}
