import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import { cubingApi } from "~/redux/warehouse/cubing";
import { GetSuggestedCompartmentAndMaxFillResponse } from "~/types/api";

import { SearchData } from "./useGetPutawayTasks";

type AutostorePutawayState = {
  selectedRowId: Guid | null;
  selectedCompartment: number | undefined;
  newSelectedCompartment: number | null;
  changedQuantity: number | undefined;
  inventoryDate: Date | string | null;
  lotNumber: string;
  isLotNumberFieldFocused: boolean;
  searchData: SearchData;
  isPutawayTaskTableRefreshed: boolean;
  portPollingActive: boolean;
  closePortBtnDisabled: boolean;
  onLeaveInputTextShown: boolean;
  isConfirmInductionButtonDisabled: boolean;
  shouldShowGetNewBinButton: boolean;
  shouldShowMaxQtyWarningBanner: boolean;
  hasUserChangedBin: boolean;
};

const initialState: AutostorePutawayState = {
  selectedRowId: null,
  selectedCompartment: undefined,
  newSelectedCompartment: null,
  changedQuantity: undefined,
  inventoryDate: moment().utc().add(1, "year").toDate(),
  lotNumber: "",
  isLotNumberFieldFocused: false,
  searchData: {
    scannedBarcodes: [],
    offset: 1
  },
  isPutawayTaskTableRefreshed: false,
  portPollingActive: false,
  closePortBtnDisabled: true,
  onLeaveInputTextShown: false,
  isConfirmInductionButtonDisabled: false,
  shouldShowGetNewBinButton: false,
  shouldShowMaxQtyWarningBanner: false,
  hasUserChangedBin: false
};

export const autostorePutawaySlice = createSlice({
  name: "autostorePutaway",
  initialState,
  reducers: {
    selectRow(state, { payload }: PayloadAction<Guid | null>) {
      state.selectedRowId = payload;
      state.hasUserChangedBin = false;
    },

    // Compartment number 0-3
    selectCompartment(state, { payload }: PayloadAction<number | undefined>) {
      state.selectedCompartment = payload;
    },

    // Compartment number used for selecting a new compartment
    selectNewCompartment(state, { payload }: PayloadAction<number | null>) {
      state.newSelectedCompartment = payload;
    },

    setChangedQuantity(state, { payload }: PayloadAction<number | undefined>) {
      state.changedQuantity = payload;
    },

    setInventoryDate(state, { payload }: PayloadAction<Date | string | null>) {
      state.inventoryDate = payload;
    },

    setLotNumber(state, { payload }: PayloadAction<string>) {
      state.lotNumber = payload;
    },

    setIsLotNumberFieldFocused(state, { payload }: PayloadAction<boolean>) {
      state.isLotNumberFieldFocused = payload;
    },

    setSearchData(state, { payload }: PayloadAction<SearchData>) {
      state.searchData = payload;
    },

    setIsPutawayTaskTableRefreshed(state, { payload }: PayloadAction<boolean>) {
      state.isPutawayTaskTableRefreshed = payload;
    },

    setPortPollingActive(state, { payload }: PayloadAction<boolean>) {
      state.portPollingActive = payload;
    },

    setClosePortBtnDisabled(state, { payload }: PayloadAction<boolean>) {
      state.closePortBtnDisabled = payload;
    },

    setOnLeaveInputTextShown(state, { payload }: PayloadAction<boolean>) {
      state.onLeaveInputTextShown = payload;
    },

    setIsConfirmInductionButtonDisabled(
      state,
      { payload }: PayloadAction<boolean>
    ) {
      state.isConfirmInductionButtonDisabled = payload;
    },

    setShouldShowGetNewBinButton(state, { payload }: PayloadAction<boolean>) {
      state.shouldShowGetNewBinButton = payload;
    },

    setShouldShowMaxQtyWarningBanner(
      state,
      { payload }: PayloadAction<boolean>
    ) {
      state.shouldShowMaxQtyWarningBanner = payload;
    },

    setHasUserChangedBin(state, { payload }: PayloadAction<boolean>) {
      state.hasUserChangedBin = payload;
    },

    setOptimalBin(
      state,
      {
        payload
      }: PayloadAction<GetSuggestedCompartmentAndMaxFillResponse | null>
    ) {
      if (!payload) {
        state.shouldShowGetNewBinButton = true;
        state.shouldShowMaxQtyWarningBanner = false;
        state.selectedCompartment = undefined;
        return;
      }

      if (
        !state.hasUserChangedBin &&
        state.changedQuantity &&
        payload?.maxFillQuantity &&
        state.changedQuantity > payload.maxFillQuantity
      ) {
        state.shouldShowMaxQtyWarningBanner = true;
      } else {
        state.shouldShowMaxQtyWarningBanner = false;
      }

      state.selectedCompartment = payload.suggestedCompartment - 1;
      state.shouldShowGetNewBinButton = false;
    },

    clearOptimalBin(state) {
      state.shouldShowGetNewBinButton = false;
      state.shouldShowMaxQtyWarningBanner = false;
      state.selectedCompartment = undefined;
    },

    reset(state) {
      state.selectedRowId = null;
      state.selectedCompartment = undefined;
      state.newSelectedCompartment = null;
      state.inventoryDate = new Date(Date.now() + 3600 * 1000 * 24 * 365); // +1 year
    }
  },
  selectors: {
    selectSelectedCompartment: (state) => state.selectedCompartment,
    selectChangedQuantity: (state) => state.changedQuantity,
    selectLotNumber: (state) => state.lotNumber,
    selectIsLotNumberFieldFocused: (state) => state.isLotNumberFieldFocused,
    selectIsConfirmInductionButtonDisabled: (state) =>
      state.isConfirmInductionButtonDisabled,
    selectShouldShowGetNewBinButton: (state) =>
      state.shouldShowGetNewBinButton && !state.hasUserChangedBin,
    selectShouldShowMaxQtyWarningBanner: (state) =>
      state.shouldShowMaxQtyWarningBanner,
    selectHasUserChangedBin: (state) => state.hasUserChangedBin
  },
  extraReducers: (builder) => {
    const { getSuggestedCompartmentsAndMaxFillCounts } = cubingApi.endpoints;
    builder.addMatcher(
      getSuggestedCompartmentsAndMaxFillCounts.matchRejected,
      (state) => {
        state.isConfirmInductionButtonDisabled = true;
      }
    );
    builder.addMatcher(
      getSuggestedCompartmentsAndMaxFillCounts.matchPending,
      (state) => {
        state.isConfirmInductionButtonDisabled = true;
      }
    );
    builder.addMatcher(
      getSuggestedCompartmentsAndMaxFillCounts.matchFulfilled,
      (state) => {
        state.isConfirmInductionButtonDisabled = false;
      }
    );
  }
});

export const {
  selectRow,
  selectCompartment,
  setChangedQuantity,
  setInventoryDate,
  setLotNumber,
  setIsLotNumberFieldFocused,
  setSearchData,
  setIsPutawayTaskTableRefreshed,
  setPortPollingActive,
  setClosePortBtnDisabled,
  setOnLeaveInputTextShown,
  setIsConfirmInductionButtonDisabled,
  selectNewCompartment,
  setShouldShowGetNewBinButton,
  setShouldShowMaxQtyWarningBanner,
  setHasUserChangedBin,
  setOptimalBin,
  clearOptimalBin,
  reset
} = autostorePutawaySlice.actions;

export const {
  selectSelectedCompartment,
  selectChangedQuantity,
  selectLotNumber,
  selectIsLotNumberFieldFocused,
  selectIsConfirmInductionButtonDisabled,
  selectShouldShowGetNewBinButton,
  selectShouldShowMaxQtyWarningBanner,
  selectHasUserChangedBin
} = autostorePutawaySlice.selectors;
