import { StoreState } from "~/redux/reducers";

export const selectClientIdReady = (state: StoreState) =>
  state.app.ready.clientId;
export const selectFlagsReadyClientId = (state: StoreState) =>
  state.app.ready.flags.clientId;
export const selectFlagsReadyFcId = (state: StoreState) =>
  state.app.ready.flags.fcId;
export const selectInterceptorReady = (state: StoreState) =>
  state.app.ready.authHeader;
export const selectClientConfigReady = (state: StoreState) =>
  state.app.ready.clientConfig;
export const selectServerConfig = (state: StoreState) => state.app.clientConfig;
