import {
  Box,
  Button,
  Card,
  Typography,
  Stack,
  TextField,
  Alert,
  InputLabel,
  Switch
} from "@mui/material";
import { useToast } from "@qubit/autoparts";
import { useState } from "react";
import { useFormContext, useForm, FormProvider } from "react-hook-form";
import ReactJson from "react-json-view";

import { useAppSelector } from "~/app/store";
import { useClientConfig } from "~/hooks/useClientConfig";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { useCreatePepsiInductionOrderMutation } from "~/redux/pepsi/inductionOrder.hooks";
import { usePostInventoryReceiveMutation } from "~/redux/public/inventory.openApi";
import { selectUsersClientId } from "~/redux/selectors/authSelectors";
import { selectOrderCreationSelectedAutostoreGridId } from "~/redux/selectors/orderCreationSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import {
  GeneratePutAwayTasks,
  usePostToolsPutawayGenerateMutation
} from "~/redux/warehouse/tools.openApi";

import { translateToInductionOrder } from "./translateToPepsiApi";

const Range = ({
  propertyName,
  normalizedName
}: {
  propertyName: keyof Pick<GeneratePutAwayTasks, "countPerTask">;
  normalizedName: string;
}) => {
  const generatePutAwayTasksContext = useFormContext<GeneratePutAwayTasks>();
  const minErrorMessage =
    generatePutAwayTasksContext.formState.errors[propertyName]?.min?.message;
  const maxErrorMessage =
    generatePutAwayTasksContext.formState.errors[propertyName]?.max?.message;
  return (
    <Box sx={{ flexBasis: "200px" }}>
      <Card sx={{ p: 1 }}>
        <Typography variant="body1">{normalizedName}</Typography>
        <Stack direction="row" flexWrap="wrap" gap={2}>
          <TextField
            {...generatePutAwayTasksContext.register(`${propertyName}.min`, {
              required: { value: true, message: "Min is required" },
              min: { value: 1, message: "Min must be greater than zero" }
            })}
            sx={{ flexBasis: "75px" }}
            variant="standard"
            label="Min"
            type="number"
            error={!!minErrorMessage}
          />
          <TextField
            {...generatePutAwayTasksContext.register(`${propertyName}.max`, {
              required: { value: true, message: "Max is required" },
              validate: (value, formValues) => {
                if (value < formValues[propertyName].min)
                  return "Max must be greater than Min";
                return true;
              }
            })}
            sx={{ flexBasis: "75px" }}
            variant="standard"
            label="Max"
            type="number"
            error={!!maxErrorMessage}
          />
        </Stack>
      </Card>
      {(!!minErrorMessage || !!maxErrorMessage) && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {!!minErrorMessage && <Typography>{minErrorMessage}</Typography>}
          {!!maxErrorMessage && <Typography>{maxErrorMessage}</Typography>}
        </Alert>
      )}
    </Box>
  );
};

export const GeneratePutAwayTasksPage = () => {
  const { errorToast, successToast } = useToast();
  const autostoreGridId = useAppSelector(
    selectOrderCreationSelectedAutostoreGridId
  );
  const clientId = useAppSelector(selectUsersClientId);
  const fulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const [generatePutAwayTasks, { data: putAwayTasksData, isLoading }] =
    usePostToolsPutawayGenerateMutation();

  const { dev_useV2OrderCreation } = useClientConfig();
  const [createInductionOrder, { isLoading: createInductionInprogress }] =
    useCreatePepsiInductionOrderMutation();
  const [createPutaway, { isLoading: createPutawayInProgress }] =
    usePostInventoryReceiveMutation();
  const [showPutAwayTaskData, setShowPutAwayTaskData] = useState(false);
  const [dryRun, setDryRun] = useState(false);

  const generatePutAwayTasksContext = useForm<GeneratePutAwayTasks>({
    defaultValues: {
      numberOfPutAwayTasks: 10,
      countPerTask: { min: 100, max: 200 }
    }
  });

  const handleGeneratePutAwayTasks = async (
    generateConfig: GeneratePutAwayTasks
  ) => {
    if (!clientId || !fulfillmentCenter || !autostoreGridId || !generateConfig)
      return;

    try {
      const data = await generatePutAwayTasks({
        generatePutAwayTasks: {
          ...generateConfig,
          gridIds: [autostoreGridId]
        }
      }).unwrap();

      if (dryRun) return;
      if (dev_useV2OrderCreation) {
        await Promise.all(
          data.map((pat) =>
            createInductionOrder({
              clientId,
              fulfillmentCenterId: fulfillmentCenter.fulfillmentCenterId,
              ...translateToInductionOrder(
                { receivings: [pat], failAllIfAnyFail: true },
                fulfillmentCenter
              )
            })
          )
        );
      } else {
        await Promise.all(
          data.map((pat) =>
            createPutaway({
              receivedInventory: { receivings: [pat], failAllIfAnyFail: true }
            })
          )
        );
      }
      successToast(`Created ${data.length} PutAway Task(s)`);
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  const numberOfPutAwayTasksErrorMessage =
    generatePutAwayTasksContext.formState.errors.numberOfPutAwayTasks?.message;

  return (
    <>
      <FormProvider {...generatePutAwayTasksContext}>
        <form
          onSubmit={generatePutAwayTasksContext.handleSubmit(
            handleGeneratePutAwayTasks
          )}
        >
          <Stack gap={2}>
            <Typography variant="h5">Generate PutAway Tasks</Typography>
            <TextField
              {...generatePutAwayTasksContext.register(`numberOfPutAwayTasks`, {
                required: {
                  value: true,
                  message: "Number of PutAway Tasks is required"
                },
                min: {
                  value: 1,
                  message: "Number of PutAway Tasks must be greater than zero"
                }
              })}
              sx={{ width: "150px" }}
              variant="standard"
              label="Number of PutAway Tasks"
              type="number"
              error={!!numberOfPutAwayTasksErrorMessage}
            />
            {!!numberOfPutAwayTasksErrorMessage && (
              <Alert severity="error">
                <Typography>{numberOfPutAwayTasksErrorMessage}</Typography>
              </Alert>
            )}
            <Stack gap={2} flexDirection="row" flexWrap="wrap">
              <Range
                propertyName="countPerTask"
                normalizedName="Count per Task"
              />
            </Stack>
            <Stack gap={2} flexDirection="row" alignItems="center">
              <Box>
                <InputLabel htmlFor="dry-run">Dry Run</InputLabel>
                <Switch
                  checked={dryRun}
                  onChange={(e) => setDryRun(e.target.checked || false)}
                />
              </Box>
              <Button
                variant="contained"
                type="submit"
                disabled={
                  isLoading ||
                  createPutawayInProgress ||
                  createInductionInprogress ||
                  !autostoreGridId
                }
              >
                <Typography>Generate</Typography>
              </Button>
              {!autostoreGridId && (
                <Alert severity="warning">
                  <Typography>No Grid Selected</Typography>
                </Alert>
              )}
            </Stack>
            {putAwayTasksData && (
              <Box>
                <InputLabel htmlFor="show-data">Show OrderData</InputLabel>
                <Switch
                  checked={showPutAwayTaskData}
                  onChange={(e) =>
                    setShowPutAwayTaskData(e.target.checked || false)
                  }
                />
              </Box>
            )}
            {showPutAwayTaskData && putAwayTasksData && (
              <ReactJson src={putAwayTasksData} />
            )}
          </Stack>
        </form>
      </FormProvider>
    </>
  );
};
