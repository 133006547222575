import { Container } from "@mui/material";
import { ScanningIndicator, useScanIndicator } from "@qubit/autoparts";
import { isGuid } from "is-guid";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";
import { buildBatchStatusFilter, buildBatchTypeFilter } from "~/lib/helpers";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";

import { PickBatchTable } from "./PickBatchTable";
import { PickBatchesSearch } from "./PickBatchesSearch";
import { PickBatchesToolbar } from "./PickBatchesToolbar";
import {
  resetSelectedBatch,
  setAvailableFilters,
  setPage,
  setToteId
} from "./pickBatches.slice";

type Props = { viewTitle?: ViewNameTranslation };

export function PickBatches({ viewTitle }: Props) {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { setToolbar } = useNavbar({
    centerComponent: useMemo(() => <PickBatchesSearch />, []),
    viewTitle
  });

  const fulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const page = useAppSelector((state) => state.pickBatches.page);
  const selectedBatch = useAppSelector(
    (state) => state.pickBatches.selectedBatch
  );

  useEffect(() => {
    const search: string = location.search.substring(1);
    if (!search) {
      dispatch(setPage(1));
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsedSearch: Record<string, unknown> = JSON.parse(
      `{"${search.replace(/&/g, '","').replace(/=/g, '":"')}"}`,
      (key: string, value: string) =>
        key === "" ? value : decodeURIComponent(value)
    );

    if (parsedSearch.page) {
      dispatch(setPage(parseInt(parsedSearch.page as string, 10)));
    }
  }, [dispatch, location]);

  useEffect(() => {
    dispatch(
      setAvailableFilters({
        batchStatuses: buildBatchStatusFilter(fulfillmentCenter),
        batchTypes: buildBatchTypeFilter(fulfillmentCenter)
      })
    );
  }, [dispatch, fulfillmentCenter]);

  const handleBatchesSearchByToteId = (toteId: Guid) => {
    dispatch(setToteId(toteId));
  };

  useEffect(() => {
    if (window.scrollTo) {
      window.scrollTo(0, 0);
    }
  }, [page]);

  useEffect(() => {
    return () => {
      dispatch(resetSelectedBatch());
    };
  }, [dispatch]);

  const [scanState, setScanState] = useScanIndicator();
  const [scannedBarcode, setScannedBarcode] = useState<string | null>(null);

  useKeyDownHandler();
  useBarcodeScanner({
    findScanMatch: (toteId: string) => {
      setScannedBarcode(toteId);
      setScanState("success");
      if (isGuid(toteId)) {
        handleBatchesSearchByToteId(toteId);
        return true;
      }
      return false;
    }
  });

  useEffect(() => {
    if (selectedBatch) {
      setToolbar(<PickBatchesToolbar />);
    } else {
      setToolbar(null);
    }
  }, [selectedBatch, setToolbar]);

  return (
    <Container>
      <PickBatchTable />

      <ScanningIndicator
        scanState={scanState}
        scannedBarcode={scannedBarcode}
        placeholderText="Scan Tote"
      />
    </Container>
  );
}
