import {
  SuggestBinConfigurationResponse,
  SuggestBinConfigurationRequest,
  GetSuggestedCompartmentAndMaxFillResponse,
  SelectCompartmentRequest
} from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const cubingApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    suggestBinConfiguration: build.query<
      SuggestBinConfigurationResponse,
      SuggestBinConfigurationRequest
    >({
      query: (body) => {
        return {
          url: "/cubing/induction/suggest-bin-configuration",
          method: "POST",
          body
        };
      },
      providesTags: ["bin cubing suggestion"]
    }),
    getSuggestedCompartmentsAndMaxFillCounts: build.query<
      GetSuggestedCompartmentAndMaxFillResponse[],
      { variantId: Guid; workstationId: Guid }
    >({
      query: ({ variantId, workstationId }) => {
        return {
          url: "/cubing/suggested-compartments-and-max-fill-counts",
          method: "GET",
          params: { variantId, workstationId }
        };
      },
      providesTags: ["cubing data"]
    }),
    selectBinCompartment: build.mutation<void, SelectCompartmentRequest>({
      query: (body) => {
        return {
          url: "/cubing/select-bin-compartment",
          method: "POST",
          body
        };
      }
    })
  })
});
