import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

import { ClickAwayListener, Tooltip, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "~/app/store";
import { openAddToteModal } from "~/features/autostorePicking/addToteModal.slice";
import usePickingFunctionalities from "~/features/autostorePicking/hooks/usePickingFunctionalities";
import { setTotePickingStarted } from "~/redux/actions";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { warehouseApi } from "~/redux/warehouse/warehouseApi";
import { PickingStateV2Tote } from "~/types/api";

import ToteOptionsMenu from "./ToteOptionsMenu";

export function AutostoreToteHeader({
  tote,
  isActiveTote,
  toteId,
  isLastStackedTote,
  pickReady,
  position,
  confirmDisabled
}: {
  tote?: PickingStateV2Tote;
  isActiveTote: boolean;
  toteId: Guid;
  isLastStackedTote?: boolean;
  pickReady: boolean;
  position: number;
  confirmDisabled: boolean;
}) {
  const clientConfig = useAppSelector(selectClientConfig);
  const {
    ap_addToteFunctionalityEnabled,
    ap_repickToteFunctionalityEnabled,
    ap_applyingLabelProcessEnabled
  } = clientConfig;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const externalOrderId = tote?.externalOrderId;
  const [openOrderIdTooltip, setOpenOrderIdTooltip] = useState(false);
  const { handleSlamProcess } = usePickingFunctionalities();

  const addToteOptionEnabled = ap_addToteFunctionalityEnabled;
  const repickToteOptionEnabled = ap_repickToteFunctionalityEnabled;
  const slamProcessEnabled = ap_applyingLabelProcessEnabled;

  const addToteCallback = () => {
    if (!addToteOptionEnabled) return;
    dispatch(openAddToteModal());
  };

  const addToteOptionClickCallback = () => {
    dispatch(setTotePickingStarted(toteId));
    // this is needed so we refresh the cache when the addTote modal is opened,
    // until we can invalidate these naturally from the natural workflow.
    dispatch(warehouseApi.util.invalidateTags(["focused pick"]));
    addToteCallback();
  };

  const getShowRepickProductsOption = () => {
    if (!tote) return false;
    if (tote.canceledTimestamp || tote.pickedTimestamp) return false;
    const allPicksForCurrentTote = tote.picks;
    if (allPicksForCurrentTote) {
      const anyConfirmedPicks = allPicksForCurrentTote.filter(
        (pick) => !!pick.completedTimestamp
      );
      if (anyConfirmedPicks.length) return true;
    }
    return false;
  };

  const reprintLabelOptionClickCallback = async () => {
    if (tote) {
      await handleSlamProcess(toteId, tote.orderId, null, true);
    }
  };

  const showRepickProductsOption = getShowRepickProductsOption();
  const showAddToteOption = addToteOptionEnabled && isActiveTote;
  const showRepickOption = repickToteOptionEnabled && showRepickProductsOption;
  const showReprintLabelOption = !!slamProcessEnabled;

  const showThreeDotsMenu =
    showAddToteOption || showRepickOption || showReprintLabelOption;
  return (
    <Box>
      {(externalOrderId || showThreeDotsMenu) && (
        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction="row"
        >
          {!openOrderIdTooltip && isActiveTote ? (
            <ExpandCircleDownIcon
              fontSize="small"
              sx={{
                "@keyframes externalOrderIdText": {
                  from: {
                    transform: "scale(0.8, 0.8)",
                    opacity: "0.2"
                  },
                  to: {
                    transform: "scale(1, 1)",
                    opacity: "1"
                  }
                },
                ...(!openOrderIdTooltip && {
                  animation: "externalOrderIdText 2s infinite ease"
                }),
                width: "16px"
              }}
            />
          ) : (
            <Box minWidth="16px" />
          )}
          {externalOrderId &&
            isLastStackedTote &&
            (externalOrderId.length > 25 ? (
              <ClickAwayListener
                onClickAway={() => setOpenOrderIdTooltip(false)}
              >
                <Tooltip
                  slotProps={{
                    popper: {
                      disablePortal: true
                    }
                  }}
                  open={openOrderIdTooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={externalOrderId.length > 25 ? externalOrderId : ""}
                >
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "12px",
                      display: "inline-block"
                    }}
                    onClick={() => {
                      setOpenOrderIdTooltip((previousState) => !previousState);
                    }}
                  >
                    {t("order")}: {externalOrderId.substring(0, 25)}
                  </Typography>
                </Tooltip>
              </ClickAwayListener>
            ) : (
              <Typography
                variant="body1"
                style={{
                  fontSize: "12px",
                  display: "inline-block",
                  marginRight: "1vw"
                }}
              >
                {t("order")}: {` ${externalOrderId}`}
              </Typography>
            ))}
          {showThreeDotsMenu && isLastStackedTote && (
            <ToteOptionsMenu
              addToteDisabled={!pickReady || confirmDisabled}
              addToteCallback={addToteOptionClickCallback}
              showAddToteOption={showAddToteOption}
              showRepickProductsOption={showRepickOption}
              isReprintLabelOptionEnabled={!!tote?.pickedTimestamp}
              reprintLabelCallback={reprintLabelOptionClickCallback}
              toteId={toteId}
              totePosition={position}
              isActiveTote={isActiveTote}
            />
          )}
        </Stack>
      )}
    </Box>
  );
}
